import {
  Avatar,
  Box,
  Button,
  Container,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  Text,
  Textarea,
  VStack,
  Wrap,
  useColorModeValue,
  WrapItem,
  useToast,
  Image,
  Alert,
  AlertIcon,
  AvatarBadge,
  useDisclosure,
  ModalFooter,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
} from "@chakra-ui/react";
import React, { Fragment, useEffect, useState } from "react";
import {
  MdPhone,
  MdEmail,
  MdLocationOn,
  MdFacebook,
  MdOutlineEmail,
} from "react-icons/md";
import {
  BsGithub,
  BsDiscord,
  BsPerson,
  BsFillCloudArrowUpFill,
  BsImageFill,
} from "react-icons/bs";
import axios from "axios";
import axiosClient from "../../axios-client";
import { useNavigate, useLocation } from "react-router-dom";
import imgAddFormation from "../../assets/img/add_formation.png";
import defaultImage from "../../assets/img/default-image.jpg";
import { useMediaQuery } from "@chakra-ui/react";
import { AiFillCloseCircle, AiOutlineCheck } from "react-icons/ai";
import { useStateContext } from "../../context/ContextProvider";
import { TbCameraRotate } from "react-icons/tb";
import { FaTimes } from "react-icons/fa";

function EditFormation() {
  // current user
  const { user } = useStateContext();

  const toast = useToast();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [isNotSmallerScreen] = useMediaQuery("(min-width: 1196px)");

  const location = useLocation();

  const [listFormation, setListFormation] = useState([
    {
      titre: "",
      image: "",
      description: "",
    },
  ]);

  const date = new Date();
  date.setDate(date.getDate());

  //
  const [titre, setTitre] = useState(location.state.titre || "");
  const [image, setImage] = useState("");
  const [description, setDescription] = useState(
    location.state.description || ""
  );
  const [selectedImage, setSelectedImage] = useState(null);

  // attributs modification

  const [edited_by, setedited_by] = useState(
    `${user.first_name} ${user.last_name}`
  );
  const [edited_at, setedited_at] = useState(date);
  const [titreJSON, setTitreJSON] = useState(null);
  const [imageJSON, setImageJSON] = useState(null);
  const [descriptionJSON, setDescriptionJSON] = useState(null);
  const [selectedImageJSON, setSelectedImageJSON] = useState(null);

  const [modification, setmodification] = useState([]);

  useEffect(() => {
    if (selectedImage) {
      setImage(URL.createObjectURL(selectedImage));
    }
  }, [selectedImage]);

  useEffect(() => {
    axiosClient.get(`/formation/${location.state.idFormation}/`).then((res) => {
      setListFormation(res.data);
      setmodification(res.data.modification);
    });
  }, []);

  const [message, setMessage] = useState("");
  const [etatMessage, setEtatMessage] = useState(false);

  const navigate = useNavigate();

  const EditFormation = (title, desc) => {
    if ((!titre || !description) || (titre.trim() === "" || description.trim() === "")) {
      setMessage("Veuillez remplir les champs");
      setEtatMessage(true);
      return;
    }

    //modification log
    const mod = modification;

    mod.push({
      edited_by: edited_by,
      edited_at: edited_at,
      field: {
        etat: null,
        titre: titre !== title ? titre : null,
        description: description !== desc ? description : null,
        image: selectedImage ? true : false,
      },
    });

    const formData = new FormData();
    formData.append("titre", titre);
    formData.append("description", description);
    if (selectedImage) formData.append("image", selectedImage);
    if (titre !== title || description !== desc || selectedImage) {
      formData.append("modification", JSON.stringify(mod));
    }

    axiosClient
      .patch(`/formation/${location.state.idFormation}/`, formData)
      .then(() => {
        if (titre !== title || description !== desc || selectedImage) {
          toast({
            description: `la formation ${title} est modifiée avec succès`,
            status: "success",
            duration: 2000,
            isClosable: true,
            position: "bottom-right",
          });
        }
        navigate("/formations");
      })
      .catch(err=>{
        console.error(err.response.data);
        setMessage('Cette formation existe déjà !')
      })
  };

  return (
    <Fragment>
      <Box mb={5} w="90%">
        <Heading
          bgGradient="linear(to-l,  #ffd140, #089bd7)"
          bgClip="text"
          fontSize={{ base: "2xl", sm: "3xl" }}
        >
          Modifier Formation
        </Heading>
      </Box>
      <Flex alignItems="center" justifyContent="space-between">
        <Box
          w={isNotSmallerScreen ? "50%" : "100%"}
          bg={useColorModeValue("white", "gray.700")}
          borderRadius="lg"
          m={{ sm: 4, md: 4, lg: 4 }}
          p={{ sm: 4, md: 4, lg: 4 }}
        >
          <Box p={4} rounded="xl" align="center" justify="center">
            <VStack spacing={5} w="90%">
              {message && (
                <Alert status="error" rounded="md">
                  <AlertIcon />
                  {message}
                </Alert>
              )}
              <Avatar
                size="xl"
                src={!selectedImage ? listFormation.image : image}
                cursor="pointer"
                position="relative"
              >
                <AvatarBadge
                  borderColor="papayawhip"
                  bg="tomato"
                  boxSize="1.25em"
                >
                  <TbCameraRotate size={20} color="black" />
                </AvatarBadge>
                <InputGroup
                  borderColor="#E0E1E7"
                  position="absolute"
                  top="0"
                  left={0}
                >
                  <Input
                    opacity="0"
                    cursor="pointer"
                    type="file"
                    h={105}
                    name="file"
                    accept="image/*"
                    onChange={(e) => setSelectedImage(e.target.files[0])}
                    placeholder="Titre de Formation"
                  />
                </InputGroup>
              </Avatar>

              <FormControl id="name">
                <FormLabel>Titre Formation</FormLabel>
                <InputGroup borderColor="#E0E1E7">
                  <Input
                    maxLength={50}
                    type="text"
                    size="lg"
                    name="titre"
                    defaultValue={listFormation.titre}
                    onChange={(e) => {
                      setTitre(e.target.value);
                      setTitreJSON(e.target.value);
                    }}
                    placeholder="Titre de Formation"
                  />
                </InputGroup>
                <Flex justify="end">
                  {titre.length > 40 ? (
                    <Text fontSize="xs" color="red">
                      {titre.length}/50 caractères !
                    </Text>
                  ) : (
                    <Text fontSize="xs">
                      {titre.length}/50{" "}
                      {titre.length > 1 ? "caractères" : "caractère"}
                    </Text>
                  )}
                </Flex>
              </FormControl>
              <FormControl id="name">
                <FormLabel>Description</FormLabel>
                <Textarea
                  maxLength={250}
                  borderColor="gray.300"
                  name="description"
                  defaultValue={listFormation.description}
                  onChange={(e) => {
                    setDescription(e.target.value);
                    setDescriptionJSON(e.target.value);
                  }}
                  _hover={{
                    borderRadius: "gray.300",
                  }}
                  placeholder="Description"
                />
                <Flex justify="end">
                  {description.length > 240 ? (
                    <Text fontSize="xs" color="red">
                      {description.length}/250 caractères !
                    </Text>
                  ) : (
                    <Text fontSize="xs">
                      {description.length}/250{" "}
                      {description.length > 1 ? "caractères" : "caractère"}
                    </Text>
                  )}
                </Flex>
              </FormControl>
              <FormControl
                display="flex"
                justifyContent="end"
                id="name"
                float="right"
              >
                 <Button
                    variant="solid"
                    colorScheme="red"
                    title="Veuiller remplir tout les champs"
                    mr={2}
                    onClick={()=>{
                      navigate(-1)
                    }}
                  >
                   Annuler
                  </Button>
                {!titre || !description ? (
                  <Button
                    variant="solid"
                    bg="#ffd140"
                    color="black"
                    _hover="none"
                    title="Veuiller remplir tout les champs"
                    isDisabled
                  >
                    Modifier la Formation
                  </Button>
                ) : (
                  <Button
                    variant="solid"
                    bg="#ffd140"
                    color="black"
                    _hover="none"
                    onClick={() =>
                      EditFormation(
                        listFormation.titre,
                        listFormation.description
                      )
                    }
                  >
                    Modifier la Formation
                  </Button>
                )}
               
              </FormControl>
              
            </VStack>
          </Box>
        </Box>
        <Box boxSize="lg" display={isNotSmallerScreen ? "block" : "none"} p={5}>
          <Image src={imgAddFormation} alt="Dan Abramov" />
        </Box>
      </Flex>

      <Modal closeOnOverlayClick={true} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Modification de l'image</ModalHeader>
          <ModalBody pb={6}>
            <FormControl
              id="image"
              isRequired
              display="flex"
              justifyContent="center"
            >
              <Flex
                alignItems="center"
                justifyContent="center"
                direction="column"
                height="200px"
                w="80%"
                border={!selectedImage ? "1px dashed" : null}
                position="relative"
                borderColor="#2b6cb0"
                cursor="pointer"
              >
                {!selectedImage && (
                  <>
                    <BsImageFill size="70px" color="#2b6cb0" />
                    <Text
                      fontSize={{ base: "md", md: "lg", lg: "lg" }}
                      fontWeight="semibold"
                      color="#2b6cb0"
                    >
                      Parcourir l'image à télécharger
                    </Text>
                  </>
                )}
                {image && selectedImage && (
                  <Box textAlign="center">
                    <Image
                      src={image}
                      w="full"
                      h="auto"
                      maxH="200px"
                      objectFit="contain"
                      alt="Dan Abramov"
                      rounded="lg"
                    />
                  </Box>
                )}
              </Flex>
              <InputGroup
                borderColor="#E0E1E7"
                position="absolute"
                top="0"
                left={10}
              >
                <Input
                  opacity="0"
                  cursor="pointer"
                  type="file"
                  height="200px"
                  w="80%"
                  size="lg"
                  name="file"
                  onChange={(e) => setSelectedImage(e.target.files[0])}
                  placeholder="Titre de Formation"
                  accept="image/jpg, image/png, image/jpeg, image/svg, image/tif"
                />
              </InputGroup>
            </FormControl>
          </ModalBody>
          {selectedImage && (
            <ModalFooter>
              <Button
                onClick={() => {
                  setSelectedImage(null)
                  onClose()
                }}
                colorScheme="red"
                mr={3}>
                <FaTimes />
              </Button>
              <Button
                onClick={onClose}
                colorScheme="whatsapp"
                isDisabled={!selectedImage}
              >
                <AiOutlineCheck />
              </Button>
            </ModalFooter>
          )}
        </ModalContent>
      </Modal>
    </Fragment>
  );
}

export default EditFormation;
