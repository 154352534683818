import React, { useState, useEffect, useCallback } from 'react';
import axiosClient from '../../axios-client';
import { useNavigate } from 'react-router-dom';
import { AddIcon, SearchIcon, DeleteIcon, EditIcon } from '@chakra-ui/icons'
import MyPagination from "../../components/MyPagination";
import { useStateContext } from "../../context/ContextProvider";
import { MdHistory } from 'react-icons/md';
import moment from 'moment';

import {
    IconButton,
    Flex,
    Box,
    Heading,
    Button,
    Stack,
    Table,
    Thead,
    InputGroup,
    InputLeftElement,
    Tbody,
    Tr,
    Th,
    Select,
    Avatar,
    Switch,
    Badge,
    Td,
    Input,
    FormControl,
    FormLabel,
    useToast,
    TableContainer,
    useColorModeValue,
    useDisclosure,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Alert,
    AlertIcon,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverHeader,
    PopoverArrow,
    PopoverBody,
    PopoverCloseButton

} from '@chakra-ui/react';

export const GestCompany = () => {
    const toast = useToast()
    const [selectedUser, setSelectedUser] = useState('');
    const { isOpen, onOpen, onClose } = useDisclosure();

    //search variable
    const [searchTerm, setSearchTerm] = useState('');

    const navigate = useNavigate()

    const [isAlertOpen, setIsAlertOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const initialFocusRef = React.useRef()
    //Modal
    const [changeModal, setChangeModal] = useState(true)

    const onOpenAlert = () => {
        setIsAlertOpen(true);
    };

    const onOpenModal = () => {
        setIsModalOpen(true);
    };

    const onCloseAlert = () => {
        setIsAlertOpen(false);
    };

    const onCloseModal = () => {
        setIsModalOpen(false);
    };

    const MAX_WORDS = 2; // Maximum number of words to display

    function truncateAddress(address) {
        const words = address.split(' ');
        if (words.length > MAX_WORDS) {
            return words.slice(0, MAX_WORDS).join(' ') + '...';
        }
        return address;
    }
    ////////////////////////////////the size of the table//////////////////////////////
    const PAGE_SIZE = 10;
    const [pageSize, setPageSize] = useState(PAGE_SIZE);
    const handlePageSizeChange = (event) => {
        const newSize = parseInt(event.target.value, 10);
        setPageSize(newSize);
        setCurrentPage(0);
    };
    //////////////////////////////////////////////////////////////////////////////////

    // current user
    const { user, setUser } = useStateContext();
    const initialRef = React.useRef(null)
    const finalRef = React.useRef(null)

    const [deleteId, setDeleteId] = useState(null);
    const cancelRef = React.useRef()

    //Societe data
    const [data, setData] = useState([]);
    const [name, setName] = useState();
    const [message, setMessage] = useState('')


    // current user data
    useEffect(() => {
        axiosClient.get('auth/user/')
            .then(({ data }) => {
                setUser(data)
            })
    }, [])

    //get the apprenant data
    useEffect(() => {
        axiosClient.get('company/')
            .then((res) => {
                setData(res.data.sort().reverse())
            })
    }, [])

    const AjoutCompany = () => {
        if (!name || name.trim() === "") {
            setMessage("Veuillez remplir le titre")
            return;
        }
        const formData = new FormData()
        formData.append("name", name)
        formData.append("added_by", user.id)
        formData.append("added_by", user.first_name + ' ' + user.last_name)

        if (data.length > 0) {
            const check = data.find(obj => {
                return obj.name.toLowerCase() === name.toLocaleLowerCase().trim();
            })
            if (check) {
                return setMessage('Cette société existe !')
            }
        }
        axiosClient.post('/company/', formData)
            .then((response) => {
                setData([response.data, ...data]);
                setName('')
                setMessage("")
                toast({
                    description: `La société ${name} est ajoutée avec succès`,
                    status: 'success',
                    duration: 2000,
                    isClosable: true,
                })
            })
        onClose()
    }

    const date = new Date();
    date.setDate(date.getDate());

    const [edited_at, setedited_at] = useState(date)

    //update chapitre
    const updateCompany = (id) => {
        if (!name || name.trim() === "") {
            setMessage("Veuillez remplir le nom")
            return;
        }

        const formData = new FormData();
        formData.append("name", name);
        formData.append("edited_by", user.first_name + ' ' + user.last_name)

        const newList = data.filter((e) => e.id !== id)
        if (newList.length > 0) {
            const check = newList.find(obj => {
                return obj.name.toLowerCase() === name.toLocaleLowerCase()
            })
            if (check) {
                return setMessage('Cette société existe !')
            }
        }

        axiosClient.patch(`/company/${id}/`, formData).then(() => {
            setData(rows => rows.map(row => {
                if (row.id === id) {
                    return {
                        ...row,
                        name,
                        edited_by: user.first_name + ' ' + user.last_name,
                        date_modification: edited_at
                    }
                }
                return row
            }
            ))

            toast({
                title: 'Modification',
                description: `Le Chapitre ${name} est modifié avec succès`,
                status: 'success',
                duration: 2000,
                isClosable: true,
            })
        })

        onClose()
    }
    const handleDelete = (id = deleteId) => {
        if (!id) return;
        axiosClient.delete(`/company/${id}/`)
            .then((response) => {
                setData((prevData) => prevData.filter((row) => row.id !== id));
                toast({
                    description: "la société est supprimé avec succès",
                    status: 'warning',
                    duration: 2000,
                    isClosable: true,
                    position: 'bottom-right'
                })
            })
            .catch((error) => {
                console.error(error);
            });
        onCloseAlert()
    }

    //search method
    const filteredData = useCallback(() => {
        return data.filter((row) => {
            return row.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                row.added_by.toLowerCase().includes(searchTerm.toLowerCase())

        });
    }, [data, searchTerm]);


    const [currentPage, setCurrentPage] = useState(0);


    return (
        <Box mt="5px" >
            <Flex>
                <Box w="90%" >
                    <Heading bgGradient='linear(to-l, #ffd140, #089bd7)' bgClip='text' fontSize={{ base: '2xl', sm: '3xl' }}>
                        Gestion des sociétés
                    </Heading>
                </Box>

            </Flex>

            <Box
                justify={'end'}
                align={'end'}
                mt={5}>
                <Button
                    mt="10px"
                    onClick={() => {
                        setChangeModal(true)
                        onOpen()
                    }}
                    colorScheme="blue"
                    leftIcon={<AddIcon />}>
                    Ajouter une société
                </Button>
            </Box>
            <Flex
                align={'center'}
                justify={'center'}
            >
                <Stack
                    w={'full'}
                    maxW='full'
                    bg={useColorModeValue('white', 'gray.700')}
                    rounded={'lg'}
                    p={6}
                    my={5}>
                    {/* Search input */}
                    <Flex
                        justifyContent="end"
                        alignItems="center"
                        direction={{ base: 'column', lg: 'row' }}
                    >

                        <InputGroup w={{ base: '100%', lg: "30%" }}>
                            <InputLeftElement
                                pointerEvents='none'
                                children={<SearchIcon color='gray.300' />}
                            />
                            <Input
                                placeholder="Chercher"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                mb={4}
                                sx={{
                                    marginLeft: 'auto',
                                }}
                            />  </InputGroup>

                    </Flex>
                    <TableContainer>
                        <Table variant='striped' colorScheme="gray">
                            <Thead>
                                <Tr>
                                    <Th>Nom</Th>
                                    <Th>Ajoutée par</Th>
                                    <Th>Date creation</Th>
                                    <Th>Historique</Th>
                                    <Th>Options</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {filteredData().slice(currentPage * pageSize, currentPage * pageSize + pageSize).map((row) => (
                                    <Tr p={0} key={row.id}>
                                        <Td>{row.name}</Td>
                                        <Td>{row.added_by}</Td>
                                        <Td>{new Date(row.created_at).toLocaleDateString('fr-FR')}</Td>

                                        <Td p={4}>
                                            <Popover placement="left" >
                                                <PopoverTrigger>
                                                    <Button
                                                        size="sm"
                                                        mr="5px"
                                                        bg="gray.300"
                                                        color={"black"}
                                                        leftIcon={<MdHistory />}>
                                                        Historique
                                                    </Button>

                                                </PopoverTrigger>
                                                <PopoverContent w={"full"} color="black" bgGradient='linear(to-r, gray.300, blue.200)' >
                                                    <PopoverHeader letterSpacing={1.5} pt={4} fontWeight="bold" border="0">
                                                        Historique
                                                    </PopoverHeader>
                                                    <PopoverArrow bg="blue.200" />
                                                    <PopoverCloseButton />
                                                    <PopoverBody>

                                                        <div style={{ marginBottom: '8px' }}>
                                                            Modifié par : {row.edited_by}
                                                        </div>

                                                        <div>
                                                            Date de dernière modification: {new Date(row.date_modification).toLocaleString('fr-FR')}
                                                        </div>
                                                    </PopoverBody>
                                                    <PopoverHeader pt={4} fontWeight="bold" border="0">
                                                    </PopoverHeader>
                                                </PopoverContent>
                                            </Popover>
                                        </Td>

                                        {/* <Td>{new Date(row.date_modification).toLocaleDateString('fr-FR')}</Td>
                                        <Td>{row.edited_by}</Td> */}

                                        {/* <IconButton
                                                size="sm"
                                                onClick={() => {
                                                    setDeleteId(row.id);
                                                    onOpenAlert();
                                                }}
                                                mr="5px"
                                                colorScheme="red"
                                                icon={<DeleteIcon />}>
                                            </IconButton> */}

                                        <Td>
                                            <Button
                                                size="sm"
                                                onClick={() => {
                                                    setChangeModal(false)
                                                    setName(row.name)
                                                    setDeleteId(row.id);
                                                    onOpen();
                                                }}

                                                colorScheme='green'

                                                leftIcon={<EditIcon />}>
                                                Modifier
                                            </Button>
                                            {/* <Popover placement="left" >
                                    <PopoverTrigger>
                                    <Button
                                      size="sm"
                                      mr="5px"
                                      bg="gray.300"
                                      color={"black"}
                                      leftIcon={<MdHistory />}>
                                      Historique
                                    </Button>
                                      
                                    </PopoverTrigger>
                                    <PopoverContent w={"full"} color="black" bgGradient='linear(to-r, gray.300, blue.200)' >
                                      <PopoverHeader letterSpacing={1.5} pt={4} fontWeight="bold" border="0">
                                        Historique
                                      </PopoverHeader>
                                      <PopoverArrow bg="blue.200" />
                                      <PopoverCloseButton />
                                      <PopoverBody>
                                      
                                        <div style={{ marginBottom: '8px' }}>
                                          Modifié par : {row.edited_by}
                                        </div>

                                        <div>
                                        Date de dernière modification: {new Date(row.date_modification).toLocaleString('fr-FR')}
                                        </div>
                                      </PopoverBody>
                                      <PopoverHeader pt={4} fontWeight="bold" border="0">
                                      </PopoverHeader>
                                    </PopoverContent>
                                  </Popover> */}
                                            {(user.role === "SUPERADMIN" || user.role === "MASTER") && <Button
                                                ml={5}
                                                size="sm"
                                                onClick={() => {
                                                    setDeleteId(row.id);
                                                    onOpenAlert();
                                                }}
                                                colorScheme='red'
                                                leftIcon={<DeleteIcon />}>
                                                Supprimer
                                            </Button>
                                            }

                                        </Td>
                                    </Tr>
                                ))}
                                {filteredData().length === 0 && (
                                    <Tr>
                                        <Td colSpan={5}>Aucune ligne correspondante n'a été trouvée.</Td>
                                    </Tr>
                                )}
                            </Tbody>
                        </Table>

                    </TableContainer>
                    {/* Pagination */}
                    <Flex justify="space-between" align="center" w="100%">
                        <Box flex="1">
                            <MyPagination
                                data={filteredData()}
                                searchInput={searchTerm}
                                PAGE_SIZE={pageSize}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                            />
                        </Box>

                        <Select w="70px" value={pageSize} onChange={handlePageSizeChange}>
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="30">30</option>
                            <option value="40">40</option>
                            {/* Add more options as needed */}
                        </Select>
                    </Flex>
                </Stack>
            </Flex>
            <AlertDialog
                isOpen={isAlertOpen}
                leastDestructiveRef={cancelRef}
                onClose={onCloseAlert}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            Supprimer société
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            êtes-vous sûr ? Vous ne pourrez pas annuler cette action ultérieurement.
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onCloseAlert}>
                                Annuler
                            </Button>
                            <Button onClick={() => handleDelete(deleteId)} colorScheme='red' ml={3}>
                                Supprimer
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
            {changeModal ?
                <Modal isOpen={isOpen} onClose={() => {
                    onClose()
                    setMessage('')
                }}>
                    <ModalOverlay />

                    <ModalContent>

                        <ModalHeader>Ajout d'une société</ModalHeader>

                        <ModalCloseButton />
                        <ModalBody pb={6}>
                            {message &&
                                <Alert status='error' rounded="md">
                                    <AlertIcon />
                                    {message}
                                </Alert>
                            }
                            <FormControl isRequired>
                                <FormLabel>Nom de société</FormLabel>
                                <Input maxLength={50} name="name" onChange={e => setName(e.target.value)} placeholder='Nom de société...' />
                            </FormControl>
                            {/* <Flex justify="end">
                  {name.length > 40
                    ? <Text fontSize='xs' color="red">{name.length}/50 caractères !</Text>
                    : <Text fontSize='xs'>{name.length}/50 {name.length > 1 ? "caractères" : "caractère"}</Text>
                  }
                </Flex> */}
                        </ModalBody>

                        <ModalFooter>
                            {!name
                                ?
                                <Button colorScheme='blue' title="Entrer le nom de société" isDisabled
                                >
                                    Ajouter
                                </Button>
                                :
                                <Button colorScheme='blue'
                                    onClick={AjoutCompany}
                                >
                                    Ajouter
                                </Button>
                            }
                        </ModalFooter>
                    </ModalContent>
                </Modal>
                :
                //Modal de modifaction
                <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={() => {
                    onClose()
                    setMessage('')
                }}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Modification de société</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody pb={6}>
                            {message &&
                                <Alert status='error' rounded="md">
                                    <AlertIcon />
                                    {message}
                                </Alert>
                            }
                            <FormControl isRequired>
                                <FormLabel>Nom de société</FormLabel>
                                <Input maxLength={50} name="name" defaultValue={name} onChange={e => setName(e.target.value)} placeholder='Nom de société...' />
                            </FormControl>

                        </ModalBody>

                        <ModalFooter>
                            {!name
                                ?
                                <Button colorScheme='blue' title="Entrer le nom de la société" isDisabled
                                >
                                    Modifier
                                </Button>
                                :
                                <Button colorScheme='blue' onClick={() => {
                                    updateCompany(deleteId)
                                }}>
                                    Modifier
                                </Button>
                            }
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            }

        </Box>

    )
}

export default GestCompany