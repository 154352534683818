import { Navigate, createBrowserRouter } from "react-router-dom";
import DefaultLayout from "./components/DefaultLayout";
import GuestLayout from "./components/GuestLayout";

import Dashboard from "./views/dashboard/Dashboard";

import { Plainte } from "./components/profile/Plainte";
import { Profile } from "./components/profile/Profile";

import Login from "./views/auth/Login";

import TableFormation from "./views/tables/TableFormation";
import TableAllModule from "./views/tables/TableAllModule";
import AddFormation from "./views/gestionFormation/AddFormation";
import EditFormation from './views/gestionFormation/EditFormation';
import TableModule from "./views/tables/TableModule";
import MesModule from "./views/tables/MesModule";
import AddModule from "./views/gestionFormation/AddModule";
import EditModule from './views/gestionFormation/EditModule';
import TableMedia from "./views/Tables_Media/TableMedia";
import GestionDemandeAcces from "./views/GestionDemandes/GestionDemandeAcces";

import ListeCertificatAdmin from "./views/certificat_resultat/ListeCertificatAdmin";
import Resultat from "./views/certificat_resultat/Resultat";

import NotFound from "./views/NotFound";
import RequireAuth from "./components/hooks/RequireAuth";
import About from "./views/About";


import GestApprenant from "./components/gestion_apprenants/GestApprenant";
import ApprenantDetail from "./components/gestion_apprenants/ApprenantDetail";
import AddApprenant from "./components/gestion_apprenants/AddApprenant";
import AddResponsable from "./views/gestion_responsable/AddResponsable";
import GestResponsable from "./views/gestion_responsable/GestResponsable";
import GestCompany from "./views/gestion_company/GestCompany";

import ActivationModuleApprenant from "./views/GestionDemandes/ActivationModuleApprenant";
import Unauthorized from "./views/Unauthorized";
import EditApprenant from "./components/gestion_apprenants/EditApprenant";
import EditResponsable from "./views/gestion_responsable/EditResponsable";
import Tesadmine from "./views/test/test"
import TableChapitre from "./views/gestionChapitre/TableChapitre";
import ListFeedBack from "./views/gestion_feedback/ListFeedBack";
import GestionAccesModule from "./views/GestionDemandes/GestionAccesModule";
import Tutorial from "./views/Tutorial";
import { Ticket } from "./components/profile/Ticket";
import Suivi_Plainte from "./components/profile/Suivi_Plainte";
import GestionContact from "./views/settings/GestionContact";
import ShowMedia from "./views/Tables_Media/ShowMedia";
import HistoriqueFormation from "./views/tables/HistoriqueFormation";
import HistoriqueModule from "./views/tables/HistoriqueModule";
import HistoriqueChapitre from "./views/gestionChapitre/HistoriqueChapitre";
import HistoriqueMedia from "./views/Tables_Media/HistoriqueMedia";
import ResetPassword from "./views/auth/ResetPassword";
import ResetPwdPage from "./views/auth/ResetPwdPage";



const router = createBrowserRouter([
  {
    path: '/',
    element: <DefaultLayout />,
    children: [
      // {

      //   path: '/',
      //   element: <RequireAuth  element={<Dashboard />} />
      // },

      {
        path: '/',
        element: (
          <RequireAuth
            element={

              localStorage.getItem("role") === 'RESPO' ? <TableFormation /> : <Dashboard />
            }
            allowedRoles={['SUPERADMIN', 'ADMIN', 'MASTER', 'RESPO']}
          />
        )
      },

      {
        path: '/formations',
        element: <RequireAuth element={<TableFormation />} />
      },
      //Pages Historiques
      {
        path: '/historique_formation',
        element: <RequireAuth element={<HistoriqueFormation />} />
      },
      {
        path: '/historique_module',
        element: <RequireAuth element={<HistoriqueModule />} />
      },
      {
        path: '/historique_chapitre',
        element: <RequireAuth element={<HistoriqueChapitre />} />
      },
      {
        path: '/historique_media',
        element: <RequireAuth element={<HistoriqueMedia />} />
      },
      {
        path: '/test',
        element: (
          <Tesadmine />
        ),
      },

      {
        path: '/nouvelle_formation',
        element: <RequireAuth element={<AddFormation />} />
      },

      {
        path: '/modifier_formation',
        element: <RequireAuth element={<EditFormation />} allowedRoles={['SUPERADMIN', 'ADMIN', 'MASTER']} />
      },

      {
        path: '/list_allModules',
        element: <RequireAuth element={<TableAllModule />} />
      },

      {
        path: '/list_modules',
        element: <RequireAuth element={<TableModule />} allowedRoles={['RESPO']} />
      },

      {
        path: '/mes_modules',
        element: <RequireAuth element={<MesModule />} allowedRoles={['RESPO']} />
      },

      {
        path: '/ajout_module',
        element: <RequireAuth element={<AddModule />} allowedRoles={['SUPERADMIN', 'ADMIN', 'MASTER', 'RESPO']} />

      },
      {
        path: '/modifier_module',
        element: <RequireAuth element={<EditModule />} allowedRoles={['SUPERADMIN', 'RESPO', 'MASTER', 'ADMIN']} />
      },
      {
        path: '/list_chapiter',
        element: <RequireAuth element={<TableChapitre />} allowedRoles={['SUPERADMIN', 'RESPO', 'MASTER', 'ADMIN']} />
      },

      {
        path: '/gestionAcces',
        element: <RequireAuth element={<GestionAccesModule />} allowedRoles={['SUPERADMIN', 'RESPO', 'MASTER', 'ADMIN']} />
      },

      {
        path: '/list_activation',
        element: <RequireAuth element={<ActivationModuleApprenant />} allowedRoles={['SUPERADMIN', 'RESPO', 'MASTER', 'ADMIN']} />
      },

      {
        path: '/list_feedback',
        element: <RequireAuth element={<ListFeedBack />} allowedRoles={['SUPERADMIN', 'RESPO', 'MASTER', 'ADMIN']} />
      },

      {
        path: '/list_medias',
        element: <RequireAuth element={<TableMedia />} allowedRoles={['SUPERADMIN', 'RESPO', 'MASTER', 'ADMIN']} />
      },

      {
        path: '/detail_media',
        element: <RequireAuth element={<ShowMedia />} allowedRoles={['SUPERADMIN', 'RESPO', 'MASTER', 'ADMIN']} />
      },
      {
        path: '/resultats',
        element: <RequireAuth element={<Resultat />} />
      },
      {
        path: '/certificats',
        element: <RequireAuth element={<ListeCertificatAdmin />} />
      },

      {
        path: '/demandes',
        element: <RequireAuth element={<GestionDemandeAcces />} />

      },
      {
        path: '/tickets',
        element: <RequireAuth element={<Plainte />} allowedRoles={['SUPERADMIN', 'ADMIN', 'MASTER']} />
      },
      {

        path: '/tutoriel',
        element: <RequireAuth element={<Tutorial />} />
      },
      {
        path: '/profile',
        element: <RequireAuth element={<Profile />} />
      },
      {
        path: '/societe',
        element: <RequireAuth element={<GestCompany />} />
      },
      {
        path: '/apprenants',
        element: <RequireAuth element={<GestApprenant />} />
      },
      {
        path: '/apprenant/',
        element: <RequireAuth element={<ApprenantDetail />} />
      },
      {
        path: '/editApprenant/',
        element: <RequireAuth element={<EditApprenant />} allowedRoles={['SUPERADMIN', 'MASTER', 'ADMIN', 'RESPO']} />
      },
      {
        path: '/editResponsable/',
        element: <RequireAuth element={<EditResponsable />} allowedRoles={['SUPERADMIN', 'MASTER', 'ADMIN']} />
      },
      {
        path: '/addApprenant',
        element: <RequireAuth element={<AddApprenant />} allowedRoles={['SUPERADMIN', 'MASTER', 'ADMIN']} />
      },
      {
        path: '/responsables',
        element: <RequireAuth element={<GestResponsable />} allowedRoles={['SUPERADMIN', 'ADMIN', 'MASTER']} />
      },
      {
        path: '/addResponsable',
        element: <RequireAuth element={<AddResponsable />} allowedRoles={['SUPERADMIN', 'MASTER', 'ADMIN']} />
      },
      {
        path: '/aPropos',
        element: <RequireAuth element={<About />} />
      },
      {
        path: '/suggestion',
        element: <RequireAuth element={<Ticket />} allowedRoles={['RESPO', 'ADMIN', 'MASTER']} />
      },
      {
        path: '/mesTickets',
        element: <RequireAuth element={<Suivi_Plainte />} allowedRoles={['RESPO', 'ADMIN', 'MASTER']} />
      },
      {
        path: '/parametres',
        element: <RequireAuth element={< GestionContact />} />
      },
    ]
  },
  {
    path: '/',
    element: <GuestLayout />,
    children: [
      {
        path: '/login',
        element: <Login />
      },
    ]
  },
  {
    path: "/unauthorized",
    element: <Unauthorized />
  },
  {
    path: '/reset_password',
    element: <ResetPassword />
  },
  {
    path: '/reinitialisation_de_motdepasse/:userId/:token/:expires',
    element: <ResetPwdPage />
  },




  {
    path: "*",
    element: <NotFound />
  }
])

export default router;
