import logo from "../assets/img/logo.png";
import { useNavigate, Outlet, useLocation } from "react-router-dom";
import { useStateContext } from "../context/ContextProvider";
import { useEffect, useState } from "react";
import axiosClient from "../axios-client";
import { FaChevronCircleLeft, FaUserGraduate, FaUserTie } from "react-icons/fa";
import { TbCertificate } from "react-icons/tb";
import { MdPlayLesson } from "react-icons/md";
import { FaUserEdit } from "react-icons/fa";
import { BiLogOut } from "react-icons/bi";
import { MdEmail } from "react-icons/md";

import {
  IconButton,
  Avatar,
  Box,
  CloseButton,
  Flex,
  Image,
  HStack,
  Center,
  VStack,
  Icon,
  useColorModeValue,
  Drawer,
  DrawerContent,
  Text,
  useDisclosure,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  useColorMode,
  Badge,
} from "@chakra-ui/react";
import {
  FiHome,
  FiStar,
  FiMenu,
  FiBell,
  FiMoon,
  FiSun,
  FiChevronDown,
  FiGitPullRequest,
} from "react-icons/fi";
import { MdVideoLibrary } from "react-icons/md";
import { BsFillInfoCircleFill } from "react-icons/bs";
import { BiSupport, BiMessageSquareDetail } from "react-icons/bi";
import { SettingsIcon } from "@chakra-ui/icons";
import { GoFileSubmodule } from "react-icons/go";

// add links to sidebar here
// use icons from 'react-icons/fi'

export default function DefaultLayout() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isSiderBar, setIsSideBar] = useState("block");
  const [changedWidth, setChangedWidth] = useState("60");

  const handleCloseSideBar = () => {
    if (isSiderBar === "block") {
      setIsSideBar("none");
      setChangedWidth("full");
    } else {
      setIsSideBar("block");
      setChangedWidth("60");
    }
  };
  return (
    <Box minH="100vh" bg={useColorModeValue("gray.100", "gray.900")}>
      <SidebarContent
        onClose={() => onClose}
        display={{ base: "none", md: isSiderBar }}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav
        onOpen={onOpen}
        handleCloseSideBar={handleCloseSideBar}
        isSiderBar={isSiderBar}
      />
      <Box ml={{ base: 0, md: changedWidth }} p="4">
        <Outlet />
      </Box>
    </Box>
  );
}

const SidebarContent = ({ onClose, ...rest }) => {
  const { user } = useStateContext();
  const userRole = user.role;

  const LinkItems = {
    SUPERADMIN: [
      { name: "Tableau de bord", icon: FiHome, path: "/" },
      { name: "Administrateurs", icon: FaUserTie, path: "/responsables" },
      { name: "Apprenants", icon: FaUserGraduate, path: "/apprenants" },
      { name: "Formations", icon: MdPlayLesson, path: "/formations" },
      { name: "Résultats", icon: FiStar, path: "/resultats" },
      { name: "Diplômes", icon: TbCertificate, path: "/certificats" },
      { name: "Demandes", icon: FiGitPullRequest, path: "/demandes" },
      { name: "Tickets", icon: BiMessageSquareDetail, path: "/tickets" },
      { name: "Guide d'utilisation", icon: MdVideoLibrary, path: "/tutoriel" },
      { name: "A propos", icon: BsFillInfoCircleFill, path: "/aPropos" },
      { name: "Paramètres", icon: SettingsIcon, path: "/parametres" },
    ],
    MASTER: [
      { name: "Tableau de bord", icon: FiHome, path: "/" },
      { name: "Administrateurs", icon: FaUserTie, path: "/responsables" },
      { name: "Apprenants", icon: FaUserGraduate, path: "/apprenants" },
      { name: "Formations", icon: MdPlayLesson, path: "/formations" },
      { name: "Résultats", icon: FiStar, path: "/resultats" },
      { name: "Diplômes", icon: TbCertificate, path: "/certificats" },
      { name: "Demandes", icon: FiGitPullRequest, path: "/demandes" },
      { name: "Tickets", icon: BiMessageSquareDetail, path: "/tickets" },
      { name: "Support", icon: BiSupport, path: "/mesTickets" },
      { name: "Guide d'utilisation", icon: MdVideoLibrary, path: "/tutoriel" },
      { name: "A propos", icon: BsFillInfoCircleFill, path: "/aPropos" },
      { name: "Paramètres", icon: SettingsIcon, path: "/parametres" },
    ],
    ADMIN: [
      { name: "Tableau de bord", icon: FiHome, path: "/" },
      { name: "Administrateurs", icon: FaUserTie, path: "/responsables" },
      { name: "Apprenants", icon: FaUserGraduate, path: "/apprenants" },
      { name: "Formations", icon: MdPlayLesson, path: "/formations" },
      { name: "Résultats", icon: FiStar, path: "/resultats" },
      { name: "Diplômes", icon: TbCertificate, path: "/certificats" },
      { name: "Demandes", icon: FiGitPullRequest, path: "/demandes" },
      { name: "Tickets", icon: BiMessageSquareDetail, path: "/tickets" },
      { name: "Support", icon: BiSupport, path: "/mesTickets" },
      { name: "Guide d'utilisation", icon: MdVideoLibrary, path: "/tutoriel" },
      { name: "A propos", icon: BsFillInfoCircleFill, path: "/aPropos" },
      { name: "Paramètres", icon: SettingsIcon, path: "/parametres" },
    ],
    RESPO: [

      { name: "Apprenants", icon: FaUserGraduate, path: "/apprenants" },
      { name: "Formations", icon: MdPlayLesson, path: "/formations" },
      { name: "Mes Module", icon: GoFileSubmodule, path: "/mes_modules" },
      { name: "Demandes", icon: FiGitPullRequest, path: "/demandes" },
      // { name: 'Tickets', icon: BiMessageSquareDetail, path: '/tickets' },
      { name: "Support", icon: BiSupport, path: "/mesTickets" },
      { name: "Guide d'utilisation", icon: MdVideoLibrary, path: "/tutoriel" },
      { name: "A propos", icon: BsFillInfoCircleFill, path: "/aPropos" },
    ],
  };
  const items = LinkItems[userRole] || [];

  return (
    <Box
      transition="3s ease"
      color="white"
      bg={useColorModeValue("#089bd7", "gray.900")}
      w={{ base: "full", md: 60 }}
      pos="fixed"
      h="full"
      overflowY={"scroll"}
      css={{
        /* Hide scrollbar */
        "&::-webkit-scrollbar": {
          display: "none",
        },
        /* Optional: Firefox scrollbar */
        scrollbarWidth: "none",
        msOverflowStyle: "none",
      }}
      {...rest}
    >
      <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
        <Image src={logo} width="250px" mt="10px"></Image>
        <CloseButton display={{ base: "flex", md: "none" }} onClick={onClose} />
      </Flex>
      {items.map((link) => (
        <NavItem
          items={items}
          key={link.name}
          icon={link.icon}
          onClose={onClose}
        >
          {link.name}
        </NavItem>
      ))}
    </Box>
  );
};

const NavItem = ({ icon, children, items, onClose, ...rest }) => {
  const navigate = useNavigate();
  const { user, setUser } = useStateContext();

  const location = useLocation();
  const pathLink = items.find((e) => e.name === children);
  // current user data
  useEffect(() => {
    axiosClient.get("auth/user/").then(({ data }) => {
      setUser(data);
    });
  }, []);
  const handleClick = () => {
    navigate(pathLink.path);
    onClose();
  };
  const [count, setCount] = useState(() => {
    const initialCount = parseInt(localStorage.getItem("count"), 10);
    return isNaN(initialCount) ? 0 : initialCount;
  });
  const [counta, setCounta] = useState();
  const [data, setData] = useState([]);
  const [countr, setCountr] = useState();
  const [countm, setCountm] = useState();
  const [countapprenant, setCountapprenant] = useState();

  useEffect(() => {
    axiosClient
      .get("/plainte/")
      .then((response) => {
        // Update data state variable
        setData(response.data);
        axiosClient
          .get("/responsables/")
          .then((usersResponse) => {
            const adminUsers = usersResponse.data.filter(
              (user) => user.role === "ADMIN"
            );
            const respoUsers = usersResponse.data.filter(
              (user) => user.role === "RESPO"
            );
            const masterUsers = usersResponse.data.filter(
              (user) => user.role === "MASTER"
            );

            const filteredPlaintesadmin = response.data.filter(
              (pl) =>
                !pl.etat && adminUsers.find((admin) => admin.id === pl.user)
            );
            const filteredPlaintesrespo = response.data.filter(
              (pl) =>
                !pl.etat && respoUsers.find((admin) => admin.id === pl.user)
            );
            const filteredPlaintesmaster = response.data.filter(
              (pl) =>
                !pl.etat && masterUsers.find((admin) => admin.id === pl.user)
            );

            setCounta(filteredPlaintesadmin.length);
            setCountm(filteredPlaintesmaster.length);
            setCountr(filteredPlaintesrespo.length);
          })
          .catch((error) => {
            console.error(error);
          });

        axiosClient
          .get("/apprenants/")
          .then((usersResponse) => {
            const Users = usersResponse.data;
            const filteredPlaintes = response.data.filter(
              (pl) =>
                !pl.etat && Users.find((apprenant) => apprenant.id === pl.user)
            );
            setCountapprenant(filteredPlaintes.length);
          })
          .catch((error) => {
            console.error(error);
          });
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    localStorage.setItem('count', count);
  }, [count]);

  useEffect(() => {
    if (user.role === "SUPERADMIN") {
      setCount(counta + countr + countm + countapprenant);
    } else if (user.role === "MASTER") {
      setCount(counta + countr + countapprenant);
    } else if (user.role === "ADMIN") {
      setCount(countr + countapprenant);
    } else if (user.role === "RESPO") {
      setCount(countapprenant);
    }
  }, [counta, countr, countm, countapprenant, user.role]);

  const [listDemandeAccesModule, setListDemandeAccesModule] = useState();
  const [listReacticationAccesModule, setListReacticationAccesModule] =
    useState();
  const [listDemandeDiplome, setlistDemandeDiplome] = useState();
  const [listDemandeInscription, setlistDemandeInscription] = useState();

  useEffect(() => {
    const demandeDiplome = [];
    const demandeInscription = [];


    if (user.role === "RESPO") {
      axiosClient
        .get(`/acces/getDemamdAccesByResponsable/?idResponsable=${user.id}`)
        .then((res) => setListDemandeAccesModule(res.data.length));
      axiosClient
        .get(
          `/acces/getDemamdReactivationByResponsable/?idResponsable=${user.id}`
        )
        .then((res) => setListReacticationAccesModule(res.data.length));
      axiosClient
        .get(
          `/demandeDiplome/getDemamdDiplomeByResponsable/?idResponsable=${user.id}`
        )
        .then((res) => {
          for (let index = 0; index < res.data.length; index++) {
            if (
              res.data[index].accorder === false &&
              res.data[index].rejetter === false
            ) {
              demandeDiplome.push(res.data);
            }
          }
          setlistDemandeDiplome(demandeDiplome.length);
        });
    }

    if (
      user.role === "SUPERADMIN" ||
      user.role === "MASTER" ||
      user.role === "ADMIN"
    ) {
      axiosClient
        .get(`/acces/getAllDemamdAcces/`)
        .then((res) => setListDemandeAccesModule(res.data.length));
      axiosClient
        .get(`/acces/getAllDemamdReactivation/`)
        .then((res) => setListReacticationAccesModule(res.data.length));
      axiosClient.get(`/demandeDiplome/`).then((res) => {
        for (let index = 0; index < res.data.length; index++) {
          if (
            res.data[index].accorder === false &&
            res.data[index].rejetter === false
          ) {
            demandeDiplome.push(res.data);
          }
        }
        setlistDemandeDiplome(demandeDiplome.length);
      });
    }

    axiosClient.get(`/demandes/`).then((res) => {
      for (let index = 0; index < res.data.length; index++) {
        if (res.data[index].etat === true) {
          demandeInscription.push(res.data);
        }
      }
      setlistDemandeInscription(demandeInscription.length);
    });
  }, [user.id]);

  return (
    <Box
      onClick={handleClick}
      style={{ textDecoration: "none" }}
      _focus={{ boxShadow: "none" }}
    >
      <Flex
        align="center"
        m="2"
        p="4"
        mx="4"
        bg={pathLink.path === location.pathname ? "#ffd140" : ""}
        borderRadius="lg"
        role="group"
        cursor="pointer"
        _hover={{
          bg: "#ffd140",
          color: "white",
        }}
        {...rest}
      >
        {icon && (
          <Icon
            mr="4"
            fontSize="18"
            _groupHover={{
              color: "white",
            }}
            as={icon}
          />
        )}

        {children === "Tickets" ? (
          <>
            <Box flex={"1"}>
              <Text fontSize="md">{children}</Text>
            </Box>
            {count > 0 && (
              <Badge
                rounded="full"
                bg="#df2e38"
                style={{ padding: "2px 7px 2px 6px" }}
                color="white"
                ml="2"
              >
                {count}
              </Badge>
            )}
          </>
        ) : children === "Demandes" ? (
          <>
            <Box flex={"1"}>
              <Text fontSize="md">{children}</Text>
            </Box>
            {listDemandeInscription +
              listDemandeAccesModule +
              listReacticationAccesModule +
              listDemandeDiplome >
              0 && (
                <Badge
                  rounded="full"
                  bg="#df2e38"
                  style={{ padding: "2px 7px 2px 6px" }}
                  color="white"
                  ml="2"
                >
                  {listDemandeInscription +
                    listDemandeAccesModule +
                    listReacticationAccesModule +
                    listDemandeDiplome}
                </Badge>
              )}
          </>
        ) : (
          <Text fontSize="md">{children}</Text>
        )}
      </Flex>
    </Box>
  );
};

const MobileNav = ({ onOpen, handleCloseSideBar, isSiderBar, ...rest }) => {
  const { user, setToken, setUser, setRefresh } = useStateContext();
  const { colorMode, toggleColorMode } = useColorMode();
  const [demande, setDemande] = useState([]);
  const [demandeAccess, setDemandeAcces] = useState();
  const [demandeActivation, setDemandeActivation] = useState();

  //user picture
  const [avatarUrl, setAvatarUrl] = useState("");

  // get demande inscription list
  useEffect(() => {
    axiosClient.get("demandes/").then(({ data }) => {
      const filteredDemande = data.filter((d) => d.etat && !d.isApproved);
      setDemande(filteredDemande);
    });
  }, []);

  // get demande access module list
  useEffect(() => {
    axiosClient
      .get("/acces/getDemamdAcces/")
      .then((res) => setDemandeAcces(res.data.length));
  }, []);

  // get demande activation module list
  useEffect(() => {
    axiosClient
      .get("/acces/getDemamdReactivation/")
      .then((res) => setDemandeActivation(res.data.length));
  }, []);

  // Logout Function
  const navigate = useNavigate();

  const onLogout = (ev) => {
    ev.preventDefault();
    setUser({});
    setToken(null);
    setRefresh(null);
    localStorage.removeItem("tokenExpirationTime");
    localStorage.removeItem("mutumAdmin");
    localStorage.removeItem("tsaroAdmin");
    localStorage.removeItem("refreshTimeAdmin");
    localStorage.removeItem("role");

  };
  //stocking the user image
  useEffect(() => {
    if (user.id) {
      axiosClient
        .get(`/responsables/${user.id}/image`)
        .then((response) => {
          setAvatarUrl(response.request.responseURL);
        })
        .catch((error) => {
          console.error(error);
        })
    }
  }, [user.id]);

  const flexContent = (
    <>
      <IconButton
        display={{ base: "flex", md: "none" }}
        onClick={onOpen}
        variant="outline"
        aria-label="open menu"
        icon={<FiMenu />}
      />
      {isSiderBar === "block" ? (
        <Text
          mt={2}
          ml={2}
          fontWeight={"bold"}
          color={"#ffd140"}
          fontSize={{ base: "1xl", sm: "1xl", md: "2xl", lg: "2xl" }}
        >
          Päiperléck E-Learning Administrative
        </Text>
      ) : (
        <Text
          color={"#ffd140"}
          ml={10}
          fontWeight={"bold"}
          fontSize={{ base: "1xl", sm: "1xl", md: "2xl", lg: "2xl" }}
        >
          Päiperléck E-Learning Administrative
        </Text>
      )}
      <HStack spacing={{ base: "0", md: "6" }}>
        
        <IconButton
          onClick={toggleColorMode}
          size="lg"
          variant=""
          _hover={{
            bg: "#ffd140",
            color: "white",
          }}
          icon={colorMode === "light" ? <FiMoon /> : <FiSun />}
        />
        <Flex alignItems={"center"} color="black">
          <Menu>
            <MenuButton
              py={2}
              transition="all 0.3s"
              _focus={{ boxShadow: "none" }}
            >
              <HStack>
                <Avatar size={"sm"} src={avatarUrl} />
                <VStack
                  display={{ base: "none", md: "flex" }}
                  alignItems="flex-start"
                  spacing="1px"
                  ml="2"
                >
                  <Text fontSize="sm" color="white">
                    {user.first_name} {user.last_name}
                  </Text>
                  <Text fontSize="xs" color="#ffd140">
                    {user.role === "ADMIN"
                      ? "administrateur"
                      : user.role === "SUPERADMIN"
                        ? "super-administrateur"
                        : user.role === "RESPO"
                          ? "responsable"
                          : user.role === "MASTER"
                            ? "Super administrateur"
                            : ""}
                  </Text>
                </VStack>
                <Box display={{ base: "none", md: "flex" }} color="white">
                  <FiChevronDown />
                </Box>
              </HStack>
            </MenuButton>
            <MenuList
              zIndex={9999}
              borderColor="white"
              alignItems={"center"}
              bg={useColorModeValue("#089bd7", "gray.900")}
              color="white"
            >
              <br />
              <Center>
                <Avatar size={"2xl"} src={avatarUrl} />
              </Center>
              <br />

              <MenuItem
                icon={<FaUserEdit size={18} />}
                _hover={{ bg: "blue.800", color: "white" }}
                bg={useColorModeValue("#089bd7", "gray.900")}
                onClick={() => navigate("/profile")}
              >
                {" "}
                Profil
              </MenuItem>
              <MenuDivider />
              <MenuItem
                icon={<BiLogOut size={20} />}
                _hover={{ bg: "blue.800", color: "white" }}
                bg={useColorModeValue("#089bd7", "gray.900")}
                onClick={onLogout}
              >
                Logout
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </HStack>
    </>
  );
  const b = useColorModeValue("#089bd7", "gray.900");
  const border = useColorModeValue("gray.200", "gray.700");
  return (
    <Box>
      {isSiderBar === "block" ? (
        <Flex
          ml={{ base: 0, md: "full" }}
          px={{ base: 4, md: 4 }}
          height="20"
          alignItems="center"
          color="white"
          marginLeft={{ base: 0, md: 60, lg: 60 }}
          bg={b}
          borderBottomWidth="1px"
          borderBottomColor={border}
          justifyContent={{ base: "space-between", md: "space-between" }}
          {...rest}
        >
          {flexContent}
        </Flex>
      ) : (
        <Flex
          ml={{ base: 0, md: "full" }}
          px={{ base: 4, md: 4 }}
          height="20"
          alignItems="center"
          color="white"
          marginLeft={0}
          bg={b}
          borderBottomWidth="1px"
          borderBottomColor={border}
          justifyContent={{ base: "space-between", md: "space-between" }}
          {...rest}
        >
          {flexContent}
        </Flex>
      )}
      {isSiderBar === "block" ? (
        <IconButton
          icon={<FaChevronCircleLeft />}
          onClick={handleCloseSideBar}
          display={{ base: "none", md: "block" }}
          variant=""
          style={{
            position: "absolute",
            left: "220px",
            top: "25px",
            color: "#ECC94B",
            fontSize: "20px",
            fontWeight: "bolder",
          }}
        />
      ) : (
        <IconButton
          onClick={handleCloseSideBar}
          icon={<FiMenu />}
          variant="outline"
          aria-label="open menu"
          style={{
            position: "absolute",
            left: "10px",
            top: "20px",
            color: "white",
            fontSize: "20px",
            fontWeight: "bolder",
          }}
        />
      )}
    </Box>
  );
};
