import {
  Badge,
  Box,
  Heading,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useColorModeValue
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import axiosClient from "../../axios-client";
import { useStateContext } from "../../context/ContextProvider";
import DemandeAccesAuxModule from "./DemandeAccesAuxModule";
import DemandeReactivationAuxModule from "./DemandeReactivationAuxModule";
import GestionDemandeDiplome from "./GestionDemandeDiplome";
import RegisterDemande from "./RegisterDemande";

function GestionDemandeAcces() {

  // current user
  const { user } = useStateContext();

  const [listDemandeAccesModule, setListDemandeAccesModule] = useState()
  const [listReacticationAccesModule, setListReacticationAccesModule] = useState()
  const [listDemandeDiplome, setlistDemandeDiplome] = useState()
  const [listDemandeInscription, setlistDemandeInscription] = useState()

  useEffect(() => {

    const demandeDiplome = []
    const demandeInscription = []

    if (user.role === 'RESPO') {
      axiosClient.get(`/acces/getDemamdAccesByResponsable/?idResponsable=${user.id}`).then((res) => setListDemandeAccesModule(res.data.length))
      axiosClient.get(`/acces/getDemamdReactivationByResponsable/?idResponsable=${user.id}`).then((res) => setListReacticationAccesModule(res.data.length))
      axiosClient.get(`/demandeDiplome/getDemamdDiplomeByResponsable/?idResponsable=${user.id}`).then(res => {
        for (let index = 0; index < res.data.length; index++) {
          if (res.data[index].accorder === false && res.data[index].rejetter === false) {
            demandeDiplome.push(res.data)
          }
        }
        setlistDemandeDiplome(demandeDiplome.length)
      })
    }

    if (user.role === 'SUPERADMIN' || user.role === "MASTER" || user.role === 'ADMIN') {
      axiosClient.get(`/acces/getAllDemamdAcces/`).then((res) => setListDemandeAccesModule(res.data.length))
      axiosClient.get(`/acces/getAllDemamdReactivation/`).then((res) => setListReacticationAccesModule(res.data.length))
      axiosClient.get(`/demandeDiplome/`).then(res => {
        for (let index = 0; index < res.data.length; index++) {
          if (res.data[index].accorder === false && res.data[index].rejetter === false) {
            demandeDiplome.push(res.data)
          }
        }
        setlistDemandeDiplome(demandeDiplome.length)
      })
    }

    axiosClient.get(`/demandes/`).then(res => {
      for (let index = 0; index < res.data.length; index++) {
        if (res.data[index].etat === true) {
          demandeInscription.push(res.data)
        }
      }
      setlistDemandeInscription(demandeInscription.length)
    })

  }, [user.id])

  const myBg = useColorModeValue("white", "gray.700")

  return (
    <Box mt='5px'>
      <Box mb={5} w="90%">
        <Heading bgGradient='linear(to-l,  #ffd140, #089bd7)' bgClip='text' fontSize={{ base: "2xl", sm: "3xl" }}>
          Espace demandes
        </Heading>
      </Box>
      <Stack>
        <Tabs variant="enclosed">
          <TabList overflowX={'auto'} overflowY={'hidden'} css={{
            /* Hide scrollbar */
            "&::-webkit-scrollbar": {
              display: "none",
            },
            /* Optional: Firefox scrollbar */
            scrollbarWidth: "none",
            msOverflowStyle: "none",
          }} >
            <Tab _selected={{ color: "white", bg: "#089bd7" }}>
              <Text fontSize={{ base: 12, lg: 15 }}>Inscription</Text>
              {listDemandeInscription > 0 &&
                <Badge rounded="full" bg="#df2e38" style={{ padding: "2px 7px 2px 6px" }} color="white" ml='2'>{listDemandeInscription}
                </Badge>
              }
            </Tab>
            <Tab _selected={{ color: "white", bg: "#089bd7" }}>
              <Text fontSize={{ base: 12, lg: 15 }}>Accès modules</Text>
              {listDemandeAccesModule > 0 &&
                <Badge rounded="full" bg="#df2e38" style={{ padding: "2px 7px 2px 6px" }} color="white" ml='2'>{listDemandeAccesModule}
                </Badge>
              }
            </Tab>
            <Tab _selected={{ color: "white", bg: "#089bd7" }}>
              <Text fontSize={{ base: 12, lg: 15 }}>Réactivation d'accès</Text>
              {listReacticationAccesModule > 0 &&
                <Badge rounded="full" bg="#df2e38" style={{ padding: "2px 7px 2px 6px" }} color="white" ml='2'>{listReacticationAccesModule}</Badge>}
            </Tab>

            <Tab _selected={{ color: "white", bg: "#089bd7" }}>
              <Text fontSize={{ base: 12, lg: 15 }}>Diplômes</Text>
              {listDemandeDiplome > 0 &&
                <Badge rounded="full" bg="#df2e38" style={{ padding: "2px 7px 2px 6px" }} color="white" ml='2'>{listDemandeDiplome}</Badge>
              }
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <RegisterDemande />
            </TabPanel>
            <TabPanel>
              <Box
                mt="5px"
                w={"full"}
                maxW="full"
                bg={useColorModeValue("white", "gray.700")}
                rounded={"lg"}
                p={6}
                my={5}
              >
                <DemandeAccesAuxModule />
              </Box>
            </TabPanel>
            <TabPanel>
              <Box
                mt="5px"
                w={"full"}
                maxW="full"
                bg={useColorModeValue("white", "gray.700")}
                rounded={"lg"}
                p={6}
                my={5}
              >
                <DemandeReactivationAuxModule />
              </Box>
            </TabPanel>

            <TabPanel>
              <Box
                mt="5px"
                w={"full"}
                maxW="full"
                bg={myBg}
                rounded={"lg"}
                p={6}
                my={5}
              >
                <GestionDemandeDiplome />
              </Box>
            </TabPanel>

          </TabPanels>
        </Tabs>
      </Stack>
    </Box>
  );
}

export default GestionDemandeAcces;
