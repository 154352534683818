import { useStateContext } from "../../context/ContextProvider";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Box,
  Text,
  Input,
  FormControl,
  FormLabel,
  Select,
  VStack,
  Button,
  Flex,
  Stack,
  Textarea,
  InputGroup,
  InputRightAddon,
  useColorModeValue,Switch
} from "@chakra-ui/react";
import axiosClient from "../../axios-client";
const Ajouter = ({ niveau }) => {
  const { fetchDataStat, postdata, getModule, user, setUser } =
    useStateContext();
  const [questionsPerChapter, setQuestionsPerChapter] = useState({});
  const [getChapitre, setgetChapitre] = useState([]);
  const [gettest, setgettest] = useState([]);
  const hasChapitre = getChapitre.length > 0;
  useEffect(() => {
    const defaultQuestionsPerChapter = getChapitre.reduce((acc, chapitre) => {
      return { ...acc, [chapitre.id]: 1 };
    }, {});
    setQuestionsPerChapter(defaultQuestionsPerChapter);
  }, [getChapitre]);
  const location = useLocation();
  useEffect(() => {
    axiosClient.get("auth/user/").then(({ data }) => {
      setUser(data);
    });
  }, []);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosClient.get(
          `/chapitre/?search=${location.state.idModule}`
        );
        setgetChapitre(response.data);
      } catch (error) {}
    };
    fetchData();
  }, [location.state.idModule]);
  useEffect(() => {
    fetchDataStat();
    getModule();
    const fetchTest = async () => {
      try {
        const response = await axiosClient.get(
          `/test/?search=${location.state.idModule}`
        );
        setgettest(response.data);
      } catch (error) {}
    };
    fetchTest();
  }, []);
  
  const [hours, setHours] = useState("00");
  const [minutes, setMinutes] = useState("10");
  const [seconds, setSeconds] = useState("00");
  const handleDurationChange = (event) => {
    const duration = event.target.value.trim();
    const [h = 0, m = 0, s = 0] = duration.split(/[^\d]+/).map(Number);
    setHours(h.toString().padStart(2, "0"));
    setMinutes(m.toString().padStart(2, "0"));
    setSeconds(s.toString().padStart(2, "0"));
  };
  const handleQuestionsInputChange = (event, chapterId) => {
    setQuestionsPerChapter({
      ...questionsPerChapter,
      [chapterId]: parseInt(event.target.value),
    });
  };
  const handleAddQuestion = (chapterId) => {
    const numQuestions = parseInt(questionsPerChapter[chapterId]) || 0;
    const updatedQuestions = {
      ...questionsPerChapter,

      [chapterId]: numQuestions + 1,
    };
    setQuestionsPerChapter(updatedQuestions);
    const inputEl = document.getElementById(
      `chapter-${chapterId}-questions-input`
    );
    if (inputEl) {
      inputEl.value = updatedQuestions[chapterId];
    }
  };
  const handleDeleteQuestion = (chapterId, questionIndex) => {
    const numQuestions = parseInt(questionsPerChapter[chapterId]) || 0;
    if (numQuestions > 0) {
      const updatedQuestions = Array.from({ length: numQuestions }, (_, i) =>
        i === questionIndex
          ? null
          : document.getElementById(`${chapterId}-question-${i}`).value
      ).filter((q) => q !== null);
      setQuestionsPerChapter({
        ...questionsPerChapter,
        [chapterId]: numQuestions - 1,
      });
      for (let i = questionIndex; i < numQuestions; i++) {
        document.getElementById(`${chapterId}-question-${i}`).value =
          updatedQuestions[i];
      }
    }
  };
  const [numPropositionsPerQuestion, setNumPropositionsPerQuestion] = useState(
    {}
  );
  const handleAddProposition = (chapterId, questionIndex) => {
    const currentNumPropositions =
      parseInt(
        numPropositionsPerQuestion[`${chapterId}-question-${questionIndex}`]
      ) || 0;
    const updatedNumPropositions = currentNumPropositions + 1;
    setNumPropositionsPerQuestion({
      ...numPropositionsPerQuestion,
      [`${chapterId}-question-${questionIndex}`]: updatedNumPropositions,
    });
  };

  const handleNumPropositionsChange = (questionId, value) => {
    // Update the number of propositions directly in the state
    setNumPropositionsPerQuestion({
      ...numPropositionsPerQuestion,
      [questionId]: parseInt(value),
    });
  };

  const supprimer = (chapterId, questionIndex, propositionIndex) => {
    const key = `${chapterId}-question-${questionIndex}`;
    const currentPropositions = numPropositionsPerQuestion[key];
    if (propositionIndex >= currentPropositions) {
      console.error(
        `Invalid proposition index ${propositionIndex} for question ${key}`
      );
      return;
    }
    const newPropositions = [...Array(currentPropositions - 1)].map(
      (_, index) => {
        const currentIndex = index >= propositionIndex ? index + 1 : index;
        return document.getElementsByName(
          `${key}-proposition-${currentIndex}`
        )[0].value;
      }
    );
    setNumPropositionsPerQuestion({
      ...numPropositionsPerQuestion,
      [key]: currentPropositions - 1,
    });
    document.getElementsByName(`${key}-num-propositions`)[0].value =
      currentPropositions - 1;
    for (let i = propositionIndex; i < currentPropositions - 1; i++) {
      const propositionInput = document.getElementsByName(
        `${key}-proposition-${i}`
      )[0];
      if (propositionInput) {
        propositionInput.value = newPropositions[i];
      }
      const responseSelect = document.getElementsByName(
        `${key}-proposition-${i}-response`
      )[0];
      if (responseSelect) {
        responseSelect.name = `${key}-proposition-${i - 1}-response`;
        responseSelect.selectedIndex = 0;
      }
    }
  };
  const [hasError, setHasError] = useState({});
  const [questionValues, setQuestionValues] = useState({});
  const [explanationHasError, setExplanationHasError] = useState({});
  const [explanationValues, setExplanationValues] = useState({});
  const handleQuestionChange = (e, chapterId, index) => {
    const value = e.target.value;
  
    // Check if the value is empty or contains only whitespace
    if (value.trim() === "") {
      setHasError((prevErrors) => ({
        ...prevErrors,
        [`${chapterId}-question-${index}`]: true,
      }));
    } else {
      // Check if the value starts with a symbol
      if (/^[-+/!@#$%^&*(),.?":{}|<>]/.test(value)) {
        setHasError((prevErrors) => ({
          ...prevErrors,
          [`${chapterId}-question-${index}`]: true,
        }));
      } else {
        // Check if the value contains only numbers
        if (/^\d+$/.test(value)) {
          setHasError((prevErrors) => ({
            ...prevErrors,
            [`${chapterId}-question-${index}`]: true,
          }));
        } else {
          setHasError((prevErrors) => ({
            ...prevErrors,
            [`${chapterId}-question-${index}`]: false,
          }));
        }
      }
    }
  
    setQuestionValues((prevValues) => ({
      ...prevValues,
      [`${chapterId}-question-${index}`]: value,
    }));
  };
  
  const handleExplicationChange = (e, chapterId, index) => {
    const value = e.target.value;
  
    // Check if the value is empty or contains only whitespace
    if (value.trim() === "") {
      setExplanationHasError((prevErrors) => ({
        ...prevErrors,
        [`${chapterId}-question-${index}`]: true,
      }));
    } else {
      // Check if the value starts with a symbol
      if (/^[-+/!@#$%^&*(),.?":{}|<>]/.test(value)) {
        setExplanationHasError((prevErrors) => ({
          ...prevErrors,
          [`${chapterId}-question-${index}`]: true,
        }));
      } else {
        // Check if the value contains only numbers
        if (/^\d+$/.test(value)) {
          setExplanationHasError((prevErrors) => ({
            ...prevErrors,
            [`${chapterId}-question-${index}`]: true,
          }));
        } else {
          setExplanationHasError((prevErrors) => ({
            ...prevErrors,
            [`${chapterId}-question-${index}`]: false,
          }));
        }
      }
    }
  
    setExplanationValues((prevValues) => ({
      ...prevValues,
      [`${chapterId}-question-${index}`]: value,
    }));
  };

  const [propositionValues, setPropositionValues] = useState({});
  const [propositionHasError, setPropositionHasError] = useState({});
  const [isPropositionValid, setIsPropositionValid] = useState({});
  const [hasSymbol, setHasSymbol] = useState({});
  const [isEmpty, setIsEmpty] = useState({});
  


  const renderQuestions = (chapterId, chapterName) => {
    let numQuestions = parseInt(questionsPerChapter[chapterId]) || 0;
    const questions = [];
    for (let i = 0; i < numQuestions; i++) {
      const propositionsPerQuestion =
        numPropositionsPerQuestion[`${chapterId}-question-${i}`];
      const renderPropositions = () => {
        const propositions = [];
        for (let j = 0; j < propositionsPerQuestion; j++) {
          propositions.push(
            <Box
              key={`${chapterId}-question-${i}-proposition-${j}`}
              rounded={"lg"}
              w={"full"}
              bg={propbg}
              p={5}
              mt={2}
              color={textColor}
              fontWeight={"bold"}
            >
        <FormControl key={j} mt={4}>
          <FormLabel
            htmlFor={`${chapterId}-question-${i}-proposition-input-${j}`}
          >
            Proposition {j + 1}
          </FormLabel>
          <Input
            required
            placeholder="proposition"
            type="text"
            name={`${chapterId}-question-${i}-proposition-${j}`}
            id={`${chapterId}-question-${i}-proposition-input-${j}`}
            onChange={(e) => {
              const value = e.target.value;
              const hasInvalidFormat = /^\s/.test(value); // Check for invalid format (starts with space)
              const hasSymbolInProposition = /[^a-zA-Z0-9\s]/.test(value); // Check for symbols in the proposition
              const isEmptyProposition = value.trim() === ''; // Check if the proposition is empty

              setPropositionValues({
                ...propositionValues,
                [`${chapterId}-question-${i}-proposition-${j}`]: value,
              });

              setPropositionHasError({
                ...propositionHasError,
                [`${chapterId}-question-${i}-proposition-${j}`]: hasInvalidFormat || hasSymbolInProposition || isEmptyProposition, // Update the error for this specific proposition with chapterId
              });

              setIsPropositionValid({
                ...isPropositionValid,
                [`${chapterId}-question-${i}-proposition-${j}`]: !hasInvalidFormat && !hasSymbolInProposition && !isEmptyProposition, // Set to true if the format is valid and doesn't contain symbols or empty
              });

              setHasSymbol({
                ...hasSymbol,
                [`${chapterId}-question-${i}-proposition-${j}`]: hasSymbolInProposition, // Set to true if the proposition contains symbols
              });

              setIsEmpty({
                ...isEmpty,
                [`${chapterId}-question-${i}-proposition-${j}`]: isEmptyProposition, // Set to true if the proposition is empty
              });
            }}
          />
          <Select
            bg={propbg}
            color={textColor}
            mt={2}
            name={`${chapterId}-question-${i}-proposition-${j}-response`}
          >
            <option value="mauvaise">Mauvaise réponse</option>
            <option value="bonne">Bonne réponse</option>
          </Select>
          {propositionHasError[`${chapterId}-question-${i}-proposition-${j}`] && (
            <p style={{ color: 'red' }}>
              Le format de la proposition est incorrecte.
            </p>
          )}
          {hasSymbol[`${chapterId}-question-${i}-proposition-${j}`] && (
            <p style={{ color: 'red' }}>
              La proposition ne peut pas contenir de symbole.
            </p>
          )}
          {isEmpty[`${chapterId}-question-${i}-proposition-${j}`] && (
            <p style={{ color: 'red' }}>
              La proposition ne peut pas être vide.
            </p>
          )}
        </FormControl>
              <Box
                display={"flex"}
                w={"full"}
                gap={{ md: "10%", lg: "68%" }}
                mt={1}
              >
                <Input
                  type="hidden"
                  id={`${chapterId}-question-${i}-num-propositions`}
                  value={propositionsPerQuestion}
                />
                <Button
                  mt={4}
                  colorScheme="red"
                  onClick={() => supprimer(chapterId, i, j)}
                  name={`${chapterId}-question-${i}-proposition-${j}-delete-button`}
                >
                  Supprimer
                </Button>
                {j ===
                numPropositionsPerQuestion[`${chapterId}-question-${i}`] - 1 ? (
                  <Button
                    colorScheme="teal"
                    mt={4}
                    onClick={() => handleAddProposition(chapterId, i)}
                    name={`${chapterId}-question-${i}-add-proposition`}
                  >
                    Ajouter une proposition
                  </Button>
                ) : null}
              </Box>
            </Box>
          );
        }
        return propositions;
      };
      questions.push(
        <Box
          key={`${chapterId}-question-${i}`}
          bg={bgclr}
          p={5}
          rounded={"lg"}
          mt={2}
        >
          <Text fontSize="lg" fontWeight="bold" mb={2} color={textColor}>
            Question {i + 1}
          </Text>
          <VStack spacing={4}>
          <FormControl>
        <FormLabel htmlFor={`${chapterId}-question-${i}`}>
          Question
        </FormLabel>
        <Input
          bg={bgColor}
          type="text"
          placeholder="Veuillez entrer une question"
          name={`${chapterId}-question-${i}`}
          id={`${chapterId}-question-${i}`}
          value={questionValues[`${chapterId}-question-${i}`] || ""}
          onChange={(e) => {
            const value = e.target.value;
            const hasInvalidFormat = /^[^a-zA-Z]/.test(value); // Check for symbols or numbers at the beginning

            setQuestionValues({
              ...questionValues,
              [`${chapterId}-question-${i}`]: value,
            });

            setHasError({
              ...hasError,
              [`${chapterId}-question-${i}`]: hasInvalidFormat, // Set hasError to true if symbols or numbers are at the beginning
            });
          }}
          required
        />
        {hasError[`${chapterId}-question-${i}`] && (
          <p style={{ color: "red" }}>Le format de la question est incorrect.</p>
        )}
      </FormControl>

            <Input
              bg={bgColor}
              type="hidden"
              name={`${chapterId}`}
              value={chapterId}
            />
            <Input
              bg={bgColor}
              type="hidden"
              name={`chapter-${chapterId}-${i}`}
              value={chapterName}
            />

            <FormControl>
              <FormLabel>Explication</FormLabel>
              <Input
                bg={bgColor}
                type="text"
                placeholder="Veuillez entrer l'explanation"
                name={`${chapterId}-question-${i}-explanation`}
                value={explanationValues[`${chapterId}-question-${i}`] || ""}
                onChange={(e) => {
                  const value = e.target.value;
                  const hasSpaceAtStart = /^\s/.test(value); // Check for spaces at the start of the input
                  const hasSpaceAtEnd = /\s$/.test(value); // Check for spaces at the end of the input
                  const hasInvalidCharacters = /[^a-zA-Z0-9\s]/.test(value); // Check for invalid characters (anything other than letters, numbers, and spaces)

                  setExplanationValues({
                    ...explanationValues,
                    [`${chapterId}-question-${i}`]: value,
                  });

                  setExplanationHasError({
                    ...explanationHasError,
                    [`${chapterId}-question-${i}`]: hasSpaceAtStart || hasSpaceAtEnd || hasInvalidCharacters, // Set explanationHasError to true if there are spaces at the start, end, or invalid characters
                  });
                }}
                required
              />
              {explanationHasError[`${chapterId}-question-${i}`] && (
                <p style={{ color: "red" }}>Le format de l'explication est incorrect.</p>
              )}
            </FormControl>

            <FormControl>
              <FormLabel>Type de question</FormLabel>
              <Select
                variant="filled"
                name={`${chapterId}-question-${i}-type`}
                bg={bgColor}
                required
              >
                <option value="unique">Choix unique</option>
                <option value="multiple">Choix multiple</option>
              </Select>
            </FormControl>
            <FormControl>
            <FormLabel>Propositions par question</FormLabel>
            <Input
              required
              placeholder="Entre le nombre de proposition pour cette question"
              bg={bgColor}
              type="number"
              min={2}
              value={
                numPropositionsPerQuestion[`${chapterId}-question-${i}`] || ""
              }
              onChange={(e) =>
                handleNumPropositionsChange(
                  `${chapterId}-question-${i}`,
                  e.target.value
                )
              }
              name={`${chapterId}-question-${i}-num-propositions`}
            />
          </FormControl>
            {renderPropositions()}
            <Box justifyContent={"space-between"} display={"flex"} w={"full"}>
                  {i !== 0 ? (
                    <Button
                      colorScheme="red"
                      onClick={() => handleDeleteQuestion(chapterId, i)}
                      name={`${chapterId}-question-${i}-delete`}
                    >
                      Supprimer cette question
                    </Button>
                  ) : null}
                  {i === numQuestions - 1 ? (
                    <Button
                      colorScheme="teal"
                      onClick={() => handleAddQuestion(chapterId, i)}
                      name={`${chapterId}-question-${i}-add`}
                    >
                      Ajouter une question
                    </Button>
                  ) : null}
            </Box>
          </VStack>
        </Box>
      );
    }
    return questions;
  };
  const getChapterName = (chapterId) => {
    const chapter = getChapitre.find((chap) => chap.id === chapterId);
    return chapter ? chapter.name : 'Unknown Chapter';
  };
  
  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      const formdata = {};
      const questions = [];
      const duration = `${hours}:${minutes}:${seconds}`;
      const chapterIds = Object.keys(questionsPerChapter);
      let hasNoBonneReponse = false;
  
      chapterIds.forEach((chapterId) => {
        const chapterQuestions = [];
        const numQuestions = questionsPerChapter[chapterId];
        const chapterName = getChapterName(parseInt(chapterId));
        const questionSet = new Set(); // Set to store unique questions within the chapter
        const explanationSet = new Set(); // Set to store unique explanations within the chapter
  
        for (let i = 0; i < numQuestions; i++) {
          const question = {
            chapitre: parseInt(chapterId),
            chapterName: chapterName,
            explicationdelaquestion:
              event.target[`${chapterId}-question-${i}-explanation`].value,
            nombredeproposition:
              numPropositionsPerQuestion[`${chapterId}-question-${i}`],
            question: event.target[`${chapterId}-question-${i}`].value,
            typeChoix: event.target[`${chapterId}-question-${i}-type`].value,
            reponses: [],
          };
  
          // Check for duplicate questions within the chapter
          if (questionSet.has(question.question.toLowerCase().trim())) {
            const questionIndex = i + 1;
            alert(
              `Vous avez la même question dans le chapitre ${chapterName}. Veuillez saisir des questions uniques pour ce chapitre.`
            );
            hasNoBonneReponse = true;
          } else {
            questionSet.add(question.question.toLowerCase().trim());
          }
  
          // Check for duplicate explanations within the chapter
          if (explanationSet.has(question.explicationdelaquestion.toLowerCase().trim())) {
            const questionIndex = i + 1;
            alert(
              `Vous avez la même explication pour la question ${questionIndex} du chapitre ${chapterName}. Veuillez saisir des explications uniques pour ce chapitre.`
            );
            hasNoBonneReponse = true;
          } else {
            explanationSet.add(question.explicationdelaquestion.toLowerCase().trim());
          }
  
          const numPropositions = numPropositionsPerQuestion[`${chapterId}-question-${i}`];
          let hasBonneReponse = false;
          let truePropositionCount = 0; // Count of true propositions
          const responseSet = new Set(); // Set to store unique responses
  
          for (let j = 0; j < numPropositions; j++) {
            const isBonneReponse =
              event.target[`${chapterId}-question-${i}-proposition-${j}-response`].value === "bonne";
            const proposition = {
              etat: isBonneReponse,
              reponse: event.target[`${chapterId}-question-${i}-proposition-${j}`].value,
            };
            question.reponses.push(proposition);
  
            if (isBonneReponse) {
              hasBonneReponse = true;
              truePropositionCount++;
            }
  
            if (responseSet.has(proposition.reponse.toLowerCase().trim())) {
              const questionIndex = i + 1;
              alert(
                `Vous avez la même proposition dans la question ${questionIndex} du chapitre ${chapterName}. Veuillez saisir des propositions uniques.`
              );
              hasNoBonneReponse = true;
            } else {
              responseSet.add(proposition.reponse.toLowerCase().trim());
            }
          }
  
          if (!hasBonneReponse) {
            const questionIndex = i + 1;
            alert(
              `La question ${questionIndex} du chapitre ${chapterName} n'a pas de 'bonne réponse'. Veuillez marquer au moins une proposition comme 'bonne réponse'.`
            );
            hasNoBonneReponse = true;
          }
  
          if (question.typeChoix === "unique" && truePropositionCount >= 2) {
            const questionIndex = i + 1;
            alert(
              `Vous avez plus de ${truePropositionCount} propositions vraies dans la question ${questionIndex} du chapitre ${chapterName}. Veuillez n'en sélectionner qu'une.`
            );
            hasNoBonneReponse = true;
          }
  
          if (question.typeChoix === "multiple" && truePropositionCount === numPropositions) {
            const questionIndex = i + 1;
            alert(
              `Toutes les propositions de la question ${questionIndex} du chapitre ${chapterName} sont marquées comme 'bonne réponse'. Veuillez laisser au moins une proposition incorrecte.`
            );
            hasNoBonneReponse = true;
          }
  
          chapterQuestions.push(question);
        }
  
        questions.push(...chapterQuestions);
      });
  
      if (hasNoBonneReponse) {
        return;
      }
  
      formdata.question = questions;
      formdata.idModule = location.state.idModule;
      formdata.nombredequestion = totalQuestions;
      formdata.seuil = event.target.seuil.value;
      formdata.difficulter = niveau;
      formdata.tempdepassage = duration;
      formdata.description = event.target.Description.value;
      formdata.objectif = event.target.Objectife.value;
      formdata.first_name = user.first_name;
      formdata.last_name = user.last_name;
      formdata.isActife = isTestActive
      formdata.date_ajout = new Date().toISOString();
  
      postdata(formdata);
      console.log(formdata)
      window.location.reload();
    } catch (error) {
    }
  };
  
  
  
  const totalQuestions = getChapitre.reduce((total, chapitre) => {
    return total + (questionsPerChapter[chapitre.id] || 0);
  }, 0);
  const bgColor = useColorModeValue("white", "gray.700");
  const bgclr = useColorModeValue("gray.50", "gray.600");
  const propbg = useColorModeValue("gray.300", "gray.700");
  const textColor = useColorModeValue("black", "white");
  const [seuil, setSeuil] = useState("");
  const [erreurseuil, setErreurSeuil] = useState(false);

  const handleSeuilChange = (event) => {
    const value = event.target.value;
    if (value === 0 || (value >= 0 && value <= 100)) {
      setSeuil(value);
      setErreurSeuil(false);
    } else {
      setErreurSeuil(true);
    }
  };
  const [description, setDescription] = useState('');
  const [hasDescriptionError, setHasDescriptionError] = useState(false);

  const handleDescriptionChange = (e) => {
    const value = e.target.value;
    setDescription(value);
  
    // Check if the description starts with a number, symbol, or space
    // Or if the description starts with a space
    if (/^[-+/!@#$%^&*(),.?":{}|<>0-9\s]/.test(value) || value.startsWith(" ")) {
      setHasDescriptionError(true);
    } else {
      setHasDescriptionError(false);
    }
  };
  const [objectif, setObjectif] = useState('');
  const [hasObjectifError, setHasObjectifError] = useState(false);

  const handleObjectifChange = (e) => {
    const value = e.target.value;
    setObjectif(value);
  
    // Check if the objectif starts with a number, symbol, or space
    // Or if the objectif starts with a space
    if (/^[-+/!@#$%^&*(),.?":{}|<>0-9\s]/.test(value) || value.startsWith(" ")) {
      setHasObjectifError(true);
    } else {
      setHasObjectifError(false);
    }
  };

  const isValidEmpty = (hours, minutes, seconds) => {
    const isEmpty =
      (hours === null || hours === "00") &&
      (minutes === null || minutes === "00") &&
      (seconds === null || seconds === "00");
  
    return isEmpty;
  }; 
  const isValidMinDuration = (hours, minutes, seconds) => {
    const totalMinutes = parseInt(hours, 10) * 60 + parseInt(minutes, 10) + parseInt(seconds, 10) / 60;
    return totalMinutes >= 10;
  };
  const isValidTimeFormat = (hours, minutes, seconds) => {
    return hours.length === 2 && minutes.length === 2 && seconds.length === 2;
  };
  
  const isValidTime = (hours, minutes, seconds) => {

  
    // Convert the time components to integers
    const intHours = parseInt(hours, 10);
    const intMinutes = parseInt(minutes, 10);
    const intSeconds = parseInt(seconds, 10);
  
    // Check if each component is within the valid range
    const isValidFormat =
      intHours >= 0 &&
      intHours <= 4 &&
      intMinutes >= 0 &&
      intMinutes <= 59 &&
      intSeconds >= 0 &&
      intSeconds <= 59;
  
    if (!isValidFormat) {
      return false;
    }
  
    // Calculate the total duration in seconds
    const totalDurationInSeconds = intHours * 3600 + intMinutes * 60 + intSeconds;
  
    // Check if the total duration is less than or equal to 4 hours (14400 seconds)
    return totalDurationInSeconds <= 14400;
  };
  const [isTestActive, setIsTestActive] = useState(false);

  const handleToggle = () => {
    setIsTestActive((prevIsTestActive) => !prevIsTestActive);
  };
  
  return (
    <>
      <form onSubmit={handleSubmit}>
        <VStack spacing={8} mt={5}>
          <FormControl
            p={4}
            w={"90%"}
            rounded={"lg"}
            bg={useColorModeValue("white", "gray.700")}
            color={useColorModeValue("black", "white")}
          >
            <Input color={"black"} bg={"gray.50"} type="hidden" name={niveau} />
            <Flex
              justify={"space-between"}
              direction={{ base: "column", md: "row", lg: "row" }}
            >
              <Stack w={{ base: "full", md: "50%", lg: "50%" }}>
                <FormLabel p={2}>Seuil du test</FormLabel>
                <InputGroup borderColor="#E0E1E7">
                  <Input
                    w={{ base: "full", md: "90%", lg: "80%" }}
                    placeholder="Seuil"
                    type="number"
                    name="seuil"
                    value={seuil}
                    onChange={handleSeuilChange}
                    required
                    min={10}
                    max={100}
                  />  
                  <InputRightAddon children="%" />
                </InputGroup>
                {erreurseuil && (
                <p style={{ color: "red" }}>Le seuil du test doit être entre 0 et 100.</p>
              )}
              </Stack>






              <Stack w={{ base: "full", md: "50%", lg: "50%" }}>
              <FormLabel p={2}>Temps de passage</FormLabel>
              <Flex
                borderRadius={5}
                alignItems={"center"}
                justifyItems={"flex-end"}
                gap={{ md: 0, lg: 25 }}
              >
                <InputGroup>
                <Input
            placeholder="00"
            type="number"
            min={0}
            max={4}
            name="hours"
            value={hours}
            onChange={(event) => {
              const value = event.target.value.slice(0, 2); // Limit input to two characters
              setHours(value);
            }}
            style={{ textAlign: "center" }}
          />
                  <InputRightAddon children="HH" />
                </InputGroup>
                <InputGroup>
                <Input
            placeholder="00"
            type="number"
            min={0}
            max={59}
            name="minutes"
            value={minutes}
            onChange={(event) => {
              const value = event.target.value.slice(0, 2); // Limit input to two characters
              setMinutes(value);
            }}
            style={{ textAlign: "center" }}
          />
                  <InputRightAddon children="MM" />
                </InputGroup>
                <InputGroup>
                <Input
            placeholder="00"
            type="number"
            min={0}
            max={59}
            name="seconds"
            value={seconds}
            onChange={(event) => {
              const value = event.target.value.slice(0, 2); // Limit input to two characters
              setSeconds(value);
            }}
            style={{ textAlign: "center" }}
          />
                  <InputRightAddon children="SS" />
                </InputGroup>
              </Flex>
              {(!isValidTime(hours, minutes, seconds) && !isValidEmpty(hours, minutes, seconds)) && (
                <p style={{ color: "red" }}>Le temps de passage ne peut pas dépasser 4 heures.</p>
              )}
              {isValidEmpty(hours, minutes, seconds) && (
                <p style={{ color: "red" }}>La date ne peut pas être null</p>
              )}
              {!isValidTimeFormat(hours, minutes, seconds) && (
                <p style={{ color: "red" }}>Vous devez avoir ce format HH MM SS.</p>
              )}
              {!isValidMinDuration(hours, minutes, seconds) && (
                <p style={{ color: "red" }}>Le temps de passage doit avoir au moins 10 minutes.</p>
              )}
            </Stack>

            </Flex>
            <Stack direction="row" align="center">
            <FormLabel htmlFor="test-toggle" p={2}>
              Activer le test
            </FormLabel>
            <Switch
              id="test-toggle"
              isChecked={isTestActive}
              onChange={handleToggle}
              colorScheme="green"
            />
          </Stack>

            <FormLabel p={2}>Description</FormLabel>
            <Textarea
              type="text"
              placeholder="Entrer la description"
              name={"Description"}
              value={description}
              onChange={handleDescriptionChange}
              required
            />
             {hasDescriptionError && (
              <p style={{ color: "red" }}>Description invalide. Ne doit pas contenir que des chiffres ou commencer par un chiffre , symbole ou un espace.</p>
            )}
            <FormLabel p={2}>Objectif</FormLabel>
            <Input
              type="text"
              placeholder="Entrer l'objectif"
              name={"Objectife"}
              value={objectif}
              onChange={handleObjectifChange}
              required
            />
              {hasObjectifError && (
                <p style={{ color: "red" }}>Objectif invalide. Ne doit pas contenir que des chiffres ou commencer par un chiffre , symbole ou un espace.</p>
              )}
          </FormControl>
          <Flex w={"90%"} rounded={"lg"} justifyContent="center">
            <Text
              fontSize="xl"
              fontWeight="bold"
              name={`${totalQuestions}`}
              textAlign={"center"}
            >
              Nombre total de questions : {`${totalQuestions}`}
            </Text>
          </Flex>
          {getChapitre.map((chapitre) => (
            <Box
              key={chapitre.id}
              p={4}
              w={"90%"}
              rounded={"lg"}
              bg={bgColor}
              color={textColor}
            >
              <Text
                bgGradient="linear(to-l, #ffd140, #2b6cb0)"
                bgClip="text"
                fontSize="2xl"
                fontWeight="bold"
                mb={2}
              >
                Chapitre : {chapitre.name}
              </Text>
              <FormControl display={"flex"} mb={4} alignItems={"center"}>
                <FormLabel
                  htmlFor={`chapter-${chapitre.id}-questions-input`}
                  style={{ display: "none" }}
                >
                  Nombre de questions :
                </FormLabel>
                <Input
                  w={"30%"}
                  rounded={"md"}
                  size={"sm"}
                  placeholder="nombre de questions"
                  type="number"
                  id={`chapter-${chapitre.id}-questions-input`}
                  onChange={(e) => handleQuestionsInputChange(e, chapitre.id)}
                  style={{ display: "none" }}
                />
              </FormControl>
              {renderQuestions(chapitre.id, chapitre.name)}
            </Box>
          ))}
          <Flex justifyItems={"left"} w={"90%"}>
          {hasChapitre && (
            <Button
              type="submit"
              colorScheme="green"
              isDisabled={
                gettest.some((item) => item.difficulter === niveau) ||
                gettest.some((item) => item.difficulter === niveau) ||
                gettest.some((item) => item.difficulter === niveau) ||
                Object.values(hasError).some((error) => error === true) ||
                Object.values(explanationHasError).some((error) => error === true) ||
                hasDescriptionError ||
                hasObjectifError ||
                Object.values(propositionHasError).some((error) => error === true) ||
                setErreurSeuil &&
                !isValidTime(hours, minutes, seconds)||
                isValidEmpty(hours, minutes, seconds) ||
                !isValidTimeFormat(hours, minutes, seconds)||
                !isValidMinDuration(hours, minutes, seconds)

              }
            >
              Ajouter le test
            </Button>
          )}
          </Flex>
        </VStack>
      </form>
    </>
  );
};
export default Ajouter;
