import { SearchIcon } from '@chakra-ui/icons';
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Box,
    Button,
    Flex,
    Icon,
    Input,
    InputGroup,
    InputLeftElement,
    Select,
    Stack,
    Text,
    useDisclosure,
    useToast
} from '@chakra-ui/react';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { BsFillInfoSquareFill } from 'react-icons/bs';
import axiosClient from "../../axios-client";
import MyPagination from '../../components/MyPagination';
import { useStateContext } from "../../context/ContextProvider";

function DemandeAcces({ id_Module }) {

    // current user
    const { user, setUser } = useStateContext();

    // current user data
    useEffect(() => {
        axiosClient.get('auth/user/')
            .then((res) => {
                setUser(res.data)
            })
    }, [])

    //list acces
    const [listAcces, setListAcces] = useState([])

    //list Apprenant
    const [listApprenant, setListApprenant] = useState([])
    //list Module
    const [listModule, setListModule] = useState([])

    useEffect(() => {

        axiosClient.get(`/acces/getDemamdAcces/?idModule=${id_Module}`).then((res) => setListAcces(res.data.sort().reverse()))
        axiosClient.get('/apprenants/').then((res) => setListApprenant(res.data))
        axiosClient.get('/module/').then((res) => setListModule(res.data))

    }, [])



    const { isOpen, onOpen, onClose } = useDisclosure();

    const [deleteId, setDeleteId] = useState(null);
    const cancelRef = React.useRef();


    const toast = useToast();

    //Accepter L'apprenant
    const Accept = (id) => {
        axiosClient.patch(`/acces/${id}/`, {
            etat: true,
            encours: false,
            firstAcces: true
        })
            .then(() => {
                setListAcces((prevData) => prevData.filter((row) => row.id !== id));
                toast({
                    description: `l'apprenant est accepté`,
                    status: "success",
                    duration: 2000,
                    isClosable: true,
                    position: "bottom-right",
                });
            })
    }

    //Refuser l'apprenant
    const refus1 = (id) => {
        axiosClient.delete(`/acces/${id}/`)
            .then(() => {
                setListAcces((prevData) => prevData.filter((row) => row.id !== id));
                toast({
                    description: `l'apprenant est refusé`,
                    status: "error",
                    duration: 2000,
                    isClosable: true,
                    position: "bottom-right",
                });
            })
        onClose()

    }

    const [search, setSearch] = useState('')

    //search method
    const filteredData = useCallback(() => {
        return listAcces.filter((row) => {
            return (
                listApprenant.some((r) => {
                    if (r.id === row.apprenant) {
                        const fullName = `${r.first_name} ${r.last_name}`;
                        return (
                            r.first_name.toLowerCase().includes(search.toLowerCase()) ||
                            r.last_name.toLowerCase().includes(search.toLowerCase()) ||
                            fullName.toLowerCase().includes(search.toLowerCase())
                        );
                    }
                    return false;
                }) ||
                listModule.some((r) => {
                    if (r.id === row.module) {
                        return r.titre.toLowerCase().includes(search.toLowerCase());
                    }
                    return false;
                })
            );
        });
    }, [listAcces, listApprenant, listModule, search]);

    //pagination

    const [currentPage, setCurrentPage] = useState(0);

    // Pagination variables
    ////////////////////////////////the size of the table//////////////////////////////
    const PAGE_SIZE = 10;
    const [pageSize, setPageSize] = useState(PAGE_SIZE);
    const handlePageSizeChange = (event) => {
        const newSize = parseInt(event.target.value, 10);
        setPageSize(newSize);
        setCurrentPage(0);
    };
    //////////////////////////////////////////////////////////////////////////////////




    return (
        <Fragment>
            <Box>
                <Flex justifyContent="end" alignItems="center" mb={5}>
                    <InputGroup w={{ base: '100%', lg: "30%" }}>
                        <InputLeftElement
                            pointerEvents="none"
                            children={<SearchIcon color="gray.300" />}
                        />
                        <Input
                            type="tel"
                            placeholder="Recherche..."
                            onChange={(e) => setSearch(e.target.value)}
                        />
                    </InputGroup>
                </Flex>
                {
                    filteredData()
                        .slice(
                            currentPage * pageSize,
                            currentPage * pageSize + pageSize
                        )
                        .map((val, key) => {
                            return (
                                <Stack
                                    key={key}
                                    h="auto"

                                    borderRadius="lg"
                                    boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
                                    border="1px solid rgba(0, 0, 0, 0.25)"
                                    p={4}
                                    mb={3}
                                >

                                    <Flex align={'center'} direction={{ base: 'column', lg: 'row' }} justifyContent={'space-between'}>

                                        <Box flexGrow={1} display="flex" >

                                            <Icon as={BsFillInfoSquareFill} h="32px" w="32px" mr="10px" />
                                            <Box>
                                                L'apprenant
                                                {listApprenant.map((v, k) => {
                                                    if (v.id === val.apprenant) {

                                                        return <b>{` ${v.first_name}  ${v.last_name} `}</b>

                                                    }
                                                })}
                                                demande l'accès au module
                                                {listModule.map((v, k) => {
                                                    if (v.id === val.module) {
                                                        return <b>{` ${v.titre} `}</b>
                                                    }
                                                })}
                                            </Box>
                                        </Box>
                                        <Flex mt={{ base: 2, lg: 0 }} align={'center'} justify={'center'}>

                                            <Button mr={2} colorScheme='teal' onClick={() => Accept(val.id, val.module, val.apprenant)}>Accepter</Button>
                                            <Button colorScheme='red' onClick={() => {
                                                setDeleteId(val.id);
                                                onOpen();
                                            }}>Refuser</Button>
                                        </Flex>
                                    </Flex>
                                </Stack>
                            )
                        })
                }
                {filteredData().length === 0 && (
                    <Text>Aucune ligne correspondante n'a été trouvée.</Text>

                )}
                <Flex justify="space-between" align="center" w="100%">
                    <Box flex="1">
                        <MyPagination
                            data={filteredData()}
                            searchInput={search}
                            PAGE_SIZE={pageSize}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                        />
                    </Box>

                    <Select w="70px" value={pageSize} onChange={handlePageSizeChange}>
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="40">40</option>
                        {/* Add more options as needed */}
                    </Select>
                </Flex>

            </Box>


            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            Refus d'activation
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            êtes-vous sûr ? Vous ne pourrez pas annuler cette action
                            ultérieurement.
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onClose}>
                                Annuler
                            </Button>
                            <Button
                                onClick={() => refus1(deleteId)}
                                colorScheme="red"
                                ml={3}
                            >
                                Supprimer
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>

        </Fragment>
    )
}

export default DemandeAcces
