import { AddIcon, DeleteIcon, EditIcon, SearchIcon } from "@chakra-ui/icons";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Avatar,
  AvatarGroup,
  Badge,
  Box,
  Button,
  Flex,
  Heading,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
  Switch,
  Table,
  TableContainer,
  Tag,
  TagLabel,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  WrapItem,
  useColorModeValue,
  useDisclosure,
  useMediaQuery,
  useToast,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import { BsShieldLockFill } from "react-icons/bs";
import { CiMenuKebab } from "react-icons/ci";
import { FaFolderOpen, FaMedal } from "react-icons/fa";
import { FiTrendingUp } from "react-icons/fi";
import { GoFileSubmodule } from "react-icons/go";
import { GrCertificate } from "react-icons/gr";
import { HiOutlineChat } from "react-icons/hi";
import { useLocation, useNavigate } from "react-router-dom";
import axiosClient from "../../axios-client";
import MyPagination from "../../components/MyPagination";
import { useStateContext } from "../../context/ContextProvider";
import { MdHistory } from "react-icons/md";

function TableModule() {
  // current user
  const { user, setUser } = useStateContext();

  const navigate = useNavigate();

  const location = useLocation();

  const [isNotSmallerScreen] = useMediaQuery("(min-width: 600px)");

  const toast = useToast();

  const [titre, setTitre] = useState("");
  const [etat, setEtat] = useState(false);
  const [responsable, setResponsable] = useState();
  const [listModule, setListModule] = useState([]);
  const [listResponsable, setListResponsable] = useState([]);

  useEffect(() => {
    axiosClient.get("auth/user/").then((res) => {
      setUser(res.data);
    });
    axiosClient
      .get(`/module/?search=${location.state.idFormation}`)
      .then((res) => {
        setListModule(res.data.sort().reverse());
      });
    axiosClient
      .get("/responsables/")
      .then((res) => setListResponsable(res.data));
  }, [user.id]);

  const DeleteModule = (id, title) => {
    axiosClient.delete(`/module/${id}/`).then((response) => {
      setListModule((prevData) => prevData.filter((row) => row.id !== id));
      toast({
        title: "Suppression",
        description: `Le module ${title} est suppprimé avec succès`,
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    });
    onCloseAlert();
  };

  const date = new Date();
  date.setDate(date.getDate());

  const [edited_by, setedited_by] = useState(
    `${user.first_name} ${user.last_name}`
  );
  const [edited_at, setedited_at] = useState(date);

  const editEtat = (id, etat, myModification) => {
    const mod = myModification;
    mod.push({
      edited_by: edited_by,
      edited_at: edited_at,
      field: {
        etat: !etat,
        titre: null,
        description: null,
        image: false,
        diplome: null,
        responsable: [],
        signateur: null,
        postSignateur: null,
        genreSignateur: null,
        type_validite: null,
        periode: null
      },
    });

    const formData = new FormData();
    formData.append("etat", !etat);
    formData.append("modification", JSON.stringify(mod));
    axiosClient.patch(`/module/${id}/`, formData).then((res) => {
      setListModule((rows) =>
        rows.map((row) => {
          if (row.id === id) {
            return {
              ...row,
              etat: !etat,
            };
          }
          return row;
        })
      );
    });
    if (!etat) {
      toast({
        description: `Le module est activé`,
        status: "success",
        duration: 2000,
        isClosable: true,
        position: "bottom-right",
      });
    } else {
      toast({
        description: `Le module est desactivé`,
        status: "warning",
        duration: 2000,
        isClosable: true,
        position: "bottom-right",
      });
    }
  };

  const openMesModules = () => {
    navigate("/list_modules", {
      state: {
        idFormation: location.state.idFormation,
        titreFormation: location.state.titreF,
        idRespo: user.id,
      },
    });
  };

  const openAddModuleWithIdFormation = () => {
    navigate("/ajout_module", {
      state: {
        idFormation: location.state.idFormation,
        titreFormation: location.state.titreF,
      },
    });
  };

  const openEditModuleWithIdModule = (
    idM,
    titre,
    description,
    diplome,
    validite_certificat,
    signateur,
    postSignateur,
    genreSignateur,
    responsable
  ) => {

    const listRespo = []
    for (let index = 0; index < responsable.length; index++) {
      listRespo.push({
        value: responsable[index],
        label: `${listResponsable.filter(row => row.id === responsable[index])[0].last_name} ${listResponsable.filter(row => row.id === responsable[index])[0].first_name}`
      })

    }

    navigate("/modifier_module", {
      state: {
        idModule: idM,
        titre: titre,
        description: description,
        diplome: diplome,
        validite_certificat: validite_certificat,
        idFormation: location.state.idFormation,
        titreFormation: location.state.titreF,
        signateur: signateur,
        postSignateur: postSignateur,
        genreSignateur: genreSignateur,
        responsable: listRespo
      },
    });
  };

  const openListChapitreWidthIdModuleAndIdResponsableFromSuperAdmin = (
    idM,
    titreM,
    userId
  ) => {
    navigate("/list_chapiter", {
      state: {
        idModule: idM,
        titreModule: titreM,
        userId: userId,
      },
    });
  };
  const openListChapitreWidthIdModuleAndIdResponsable = (idM, titreM) => {
    navigate("/list_chapiter", {
      state: {
        idModule: idM,
        titreModule: titreM,
        userId: user.id,
      },
    });
  };

  const openGestionAccesModule = (idM, titre) => {
    navigate("/gestionAcces", {
      state: {
        idModule: idM,
        titre: titre,
      },
    });
  };

  const openAjouterunTest = (idM, titre) => {
    navigate("/test", {
      state: {
        idModule: idM,
        titre: titre,
      },
    });
  };

  const [selectedEtat, setSelectedEtat] = useState(null);
  const [selectedValidite, setSelectedValidite] = useState(null);

  const [search, setSearch] = useState("");

  //search method
  const filteredData = useCallback(() => {
    return listModule.filter((row) => {
      return (
        row.titre.toLowerCase().includes(search.toLowerCase()) ||
        row.description.toLowerCase().includes(search.toLowerCase()) ||
        row.diplome.toLowerCase().includes(search.toLowerCase()) ||
        row.periode.toString().toLowerCase().includes(search.toLowerCase()) ||
        row.signateur.toString().toLowerCase().includes(search.toLowerCase()) ||
        row.postSignateur
          .toString()
          .toLowerCase()
          .includes(search.toLowerCase()) ||
        row.genreSignateur
          .toString()
          .toLowerCase()
          .includes(search.toLowerCase())
      );
    });
  }, [listModule, search]);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [deleteId, setDeleteId] = useState(null);
  const cancelRef = React.useRef();
  const [isAlertOpen, setIsAlertOpen] = useState(false);

  const onOpenAlert = () => {
    setIsAlertOpen(true);
  };

  const onCloseAlert = () => {
    setIsAlertOpen(false);
  };

  const [changeModal, setChangeModal] = useState(true);

  const [currentPage, setCurrentPage] = useState(0);
  ////////////////////////////////the size of the table//////////////////////////////
  const PAGE_SIZE = 10;
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const handlePageSizeChange = (event) => {
    const newSize = parseInt(event.target.value, 10);
    setPageSize(newSize);
    setCurrentPage(0);
  };
  //////////////////////////////////////////////////////////////////////////////////

  //Statistics
  const [rating1, setRating1] = useState();
  const [rating2, setRating2] = useState();
  const [rating3, setRating3] = useState();
  const [rating4, setRating4] = useState();
  const [rating5, setRating5] = useState();

  const getRatingModule = (id) => {
    axiosClient
      .get(`/rating/getRatingsByModule/?idModule=${id}`)
      .then((res) => setRating1(res.data.filter((e) => e.rating === 1).length));
    axiosClient
      .get(`/rating/getRatingsByModule/?idModule=${id}`)
      .then((res) => setRating2(res.data.filter((e) => e.rating === 2).length));
    axiosClient
      .get(`/rating/getRatingsByModule/?idModule=${id}`)
      .then((res) => setRating3(res.data.filter((e) => e.rating === 3).length));
    axiosClient
      .get(`/rating/getRatingsByModule/?idModule=${id}`)
      .then((res) => setRating4(res.data.filter((e) => e.rating === 4).length));
    axiosClient
      .get(`/rating/getRatingsByModule/?idModule=${id}`)
      .then((res) => setRating5(res.data.filter((e) => e.rating === 5).length));
  };

  return (
    <Box mt="5px">
      <Box mb={5} w="90%">
        <Heading
          bgGradient="linear(to-l,  #ffd140, #089bd7)"
          bgClip="text"
          fontSize={{ base: "2xl", sm: "3xl" }}
        >
          Liste des Modules : {location.state.titreF}
        </Heading>
      </Box>
      {user.role === "RESPO" ? (
        <Flex
          justifyContent={{ base: "space-between", sm: "end" }}
          alignItems="center"
        >
          <Button
            display={{ base: "block", sm: "none" }}
            colorScheme="blue"
            mr={2}
            size="md"
            onClick={openMesModules}
            leftIcon={<GoFileSubmodule />}
          >
            Mes modules
          </Button>

          <Button
            colorScheme="blue"
            size="md"
            onClick={() => openAddModuleWithIdFormation()}
            leftIcon={<AddIcon />}
          >
            Ajouter un module
          </Button>
        </Flex>
      ) : (
        <Flex justifyContent="end" alignItems="center">
          <Button
            colorScheme="blue"
            size="md"
            onClick={() => openAddModuleWithIdFormation()}
            leftIcon={<AddIcon />}
          >
            Ajouter un module
          </Button>
        </Flex>
      )}
      <Stack
        w={"full"}
        maxW="full"
        bg={useColorModeValue("white", "gray.700")}
        rounded={"lg"}
        p={6}
        my={5}
      >
        {user.role === "RESPO" ? (
          <Flex
            mb={4}
            w={"100%"}
            justifyContent="space-between"
            alignItems="center"
          >
            <InputGroup w="100%">
              <InputLeftElement
                pointerEvents="none"
                children={<SearchIcon color="gray.300" />}
              />
              <Input
                w={{ base: "90%", lg: "40%" }}
                type="tel"
                placeholder="Recherche..."
                onChange={(e) => setSearch(e.target.value)}
              />
              <Select
                fontSize={{ base: 13, lg: 16 }}
                w={{ base: "30%", lg: "10%" }}
                ml={{ base: 2, lg: 5 }}
                value={selectedEtat}
                onChange={(e) =>
                  setSelectedEtat(
                    e.target.value === "" ? null : e.target.value === "true"
                  )
                }
              >
                <option value="">Tout</option>
                <option value="true">Activé</option>
                <option value="false">Désactivé</option>
              </Select>
              <Select
              fontSize={{ base: 13, lg: 16 }}
              w={{ base: "30%", lg: "10%" }}
              ml={5}
              value={selectedValidite}
              onChange={(e) =>
                setSelectedValidite(
                  e.target.value === "" ? null : e.target.value === "true"
                )
              }
            >
              <option value="">Tout</option>
              <option value="true">Par mois</option>
              <option value="false">À vie	</option>
            </Select>
            </InputGroup>
            <Button
              display={{ base: "none", sm: "block" }}
              colorScheme="blue"
              paddingRight={10}
              ml={2}
              size="md"
              onClick={openMesModules}
              leftIcon={<GoFileSubmodule />}
            >
              Mes modules
            </Button>
          </Flex>
        ) : (
          <Flex justifyContent="end" w={"100%"} mb={2} alignItems="center">
            <Select
              fontSize={{ base: 13, lg: 16 }}
              w={{ base: "30%", lg: "10%" }}
              mr={5}
              value={selectedValidite}
              onChange={(e) =>
                setSelectedValidite(
                  e.target.value === "" ? null : e.target.value === "true"
                )
              }
            >
              <option value="">Tout</option>
              <option value="true">Par mois</option>
              <option value="false">À vie	</option>
            </Select>
            <Select
              fontSize={{ base: 13, lg: 16 }}
              w={{ base: "30%", lg: "10%" }}
              mr={5}
              value={selectedEtat}
              onChange={(e) =>
                setSelectedEtat(
                  e.target.value === "" ? null : e.target.value === "true"
                )
              }
            >
              <option value="">Tout</option>
              <option value="true">Activé</option>
              <option value="false">Désactivé</option>
            </Select>
            <InputGroup w={{ base: "70%", lg: "30%" }}>
              <InputLeftElement
                pointerEvents="none"
                children={<SearchIcon color="gray.300" />}
              />
              <Input
                type="tel"
                placeholder="Recherche..."
                onChange={(e) => setSearch(e.target.value)}
              />
            </InputGroup>
          </Flex>
        )}

        <TableContainer>
          <Table
            variant="simple"
            css={{
              "&::-webkit-scrollbar": {
                width: "11px",
                height: "6px",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "#10316B",
                borderRadius: "6px",
              },
              "&::-webkit-scrollbar-track": {
                background: "gray.50",
              },
            }}
          >
            <Thead>
              <Tr>
                <Th>image</Th>
                <Th>titre</Th>
                <Th>Description</Th>
                <Th>type Diplôme</Th>
                <Th>signataire</Th>
                <Th>Post signataire</Th>
                <Th>Genre signataire</Th>
                <Th>date validaté</Th>
                <Th>Etat</Th>
                {(user.role === "SUPERADMIN" ||
                  user.role === "MASTER" ||
                  user.role === "ADMIN") && (
                    <>
                      <Th>Responsable</Th>
                    </>
                  )}
                {(user.role === "SUPERADMIN" ||
                  user.role === "MASTER" ||
                  user.role === "ADMIN") && (
                    <>
                      <Th>Chapitres</Th>
                      <Th>Accès</Th>
                      <Th>Tests</Th>
                      <Th>Commentaire</Th>
                      <Th>Statistiques</Th>
                      <Th>Action</Th>
                    </>
                  )}
              </Tr>
            </Thead>
            <Tbody>
              {filteredData()
                .filter(
                  (row) => (selectedEtat === null || row.etat === selectedEtat) && (selectedValidite === null || row.type_validite ===selectedValidite )
                )
                .slice(
                  currentPage * pageSize,
                  currentPage * pageSize + pageSize
                )
                .map((val, key) => {
                  return (
                    <Tr key={key}>
                      <Td>
                        <WrapItem>
                          <Avatar name="Dan Abrahmov" src={val.image} />
                        </WrapItem>
                      </Td>
                      <Td>{val.titre}</Td>
                      <Td>
                        {val.description?.length > 25
                          ? `${val.description.substring(0, 25)}...`
                          : val.description}
                      </Td>
                      <Td>
                        {val.diplome === "Certificat" ? (
                          <Tag size="lg" colorScheme="teal" borderRadius="full">
                            <FaMedal />
                            <TagLabel ml={2}>{val.diplome}</TagLabel>
                          </Tag>
                        ) : (
                          <Tag
                            size="lg"
                            colorScheme="messenger"
                            borderRadius="full"
                          >
                            <GrCertificate />
                            <TagLabel ml={2}>{val.diplome}</TagLabel>
                          </Tag>
                        )}
                      </Td>
                      <Td>{val.signateur}</Td>
                      <Td>{val.postSignateur}</Td>
                      <Td>{val.genreSignateur}</Td>
                      <Td>
                        {val.type_validite === false
                          ? "À vie"
                          : `${val.periode} Mois`}
                      </Td>
                      <Td>
                        {(user.role === "SUPERADMIN" ||
                          user.role === "MASTER" ||
                          user.role === "ADMIN") && (
                            <Switch
                              size="md"
                              colorScheme="green"
                              isChecked={val.etat}
                              onChange={() => {
                                editEtat(val.id, val.etat, val.modification);
                                setEtat(!val.etat);
                              }}
                            />
                          )}

                        <Badge
                          rounded="lg"
                          colorScheme={val.etat ? "green" : "red"}
                        >
                          {val.etat ? "Activé" : "Desactivé"}
                        </Badge>
                      </Td>
                      {(user.role === "SUPERADMIN" ||
                        user.role === "MASTER" ||
                        user.role === "ADMIN") && (
                          <Td>
                            <AvatarGroup
                              size="md"
                              max={2}
                              cursor="pointer"
                              onClick={() => {
                                setChangeModal(true);
                                setDeleteId(val.id);
                                onOpen();
                              }}
                            >
                              {listResponsable.map((v, k) => {
                                for (
                                  let index = 0;
                                  index < val.responsable.length;
                                  index++
                                ) {
                                  if (v.id === val.responsable[index]) {
                                    return (
                                      <Avatar
                                        name="Ryan Florence"
                                        src={v.image}
                                      />
                                    );
                                  }
                                }
                              })}
                            </AvatarGroup>
                          </Td>
                        )}
                      {(user.role === "SUPERADMIN" ||
                        user.role === "MASTER" ||
                        user.role === "ADMIN") && (
                          <>
                            <Td>
                              <Button
                                size="sm"
                                colorScheme="blue"
                                leftIcon={<FaFolderOpen />}
                                onClick={() => {
                                  if (
                                    user.role === "SUPERADMIN" ||
                                    user.role === "MASTER" ||
                                    user.role === "ADMIN"
                                  ) {
                                    openListChapitreWidthIdModuleAndIdResponsableFromSuperAdmin(
                                      val.id,
                                      val.titre,
                                      val.responsable
                                    );
                                  } else {
                                    openListChapitreWidthIdModuleAndIdResponsable(
                                      val.id,
                                      val.titre
                                    );
                                  }
                                }}
                              >
                                Chapitre
                              </Button>
                            </Td>
                            <Td>
                              <Button
                                size="sm"
                                colorScheme="facebook"
                                leftIcon={<BsShieldLockFill />}
                                onClick={() =>
                                  openGestionAccesModule(val.id, val.titre)
                                }
                              >
                                Accès
                              </Button>
                            </Td>
                            <Td>
                              <Button
                                size="sm"
                                colorScheme="purple"
                                leftIcon={<FiTrendingUp />}
                                //onClick={() => alert(`Hello : id_Responsable=${user.id} & id_Formation=${location.state.idFormation} & id_Module=${val.id}`)}
                                onClick={() =>
                                  openAjouterunTest(val.id, val.titre)
                                }
                              >
                                Test
                              </Button>
                            </Td>
                            <Td>
                              <Button
                                size="sm"
                                colorScheme="teal"
                                leftIcon={<HiOutlineChat fontSize={18} />}
                                onClick={() => {
                                  navigate("/list_feedback", {
                                    state: {
                                      idModule: val.id,
                                      title: val.titre
                                    },
                                  });
                                }}
                              >
                                Commentaire
                              </Button>
                            </Td>
                            <Td>
                              <Button
                                leftIcon={<AiOutlineStar fontSize={18} />}
                                colorScheme="teal"
                                variant="outline"
                                onClick={() => {
                                  setChangeModal(false);
                                  getRatingModule(val.id);
                                  onOpen();
                                }}
                              >
                                Statistiques
                              </Button>
                            </Td>
                            <Td>
                              {user.role !== "ADMIN" ? (
                                <Menu>
                                  <MenuButton
                                    as={IconButton}
                                    aria-label="Options"
                                    icon={<CiMenuKebab />}
                                    variant="outline"
                                    _hover="none"
                                  />
                                  <MenuList>
                                    <MenuItem
                                      icon={<EditIcon fontSize={15} />}
                                      onClick={() =>
                                        openEditModuleWithIdModule(
                                          val.id,
                                          val.titre,
                                          val.description,
                                          val.diplome,
                                          val.validite_certificat,
                                          val.signateur,
                                          val.postSignateur,
                                          val.genreSignateur,
                                          val.responsable
                                        )
                                      }
                                    >
                                      Modifier
                                    </MenuItem>
                                    <MenuItem
                                      icon={<MdHistory fontSize={15} />}
                                      onClick={() =>
                                        navigate("/historique_module", {
                                          state: {
                                            idModule: val.id,
                                          },
                                        })
                                      }
                                    >
                                      Historique
                                    </MenuItem>
                                    <MenuItem
                                      icon={<DeleteIcon fontSize={15} />}
                                      onClick={() => {
                                        setDeleteId(val.id);
                                        setTitre(val.titre);
                                        onOpenAlert();
                                      }}
                                    >
                                      Supprimer
                                    </MenuItem>
                                  </MenuList>
                                </Menu>
                              ) : (
                                <>
                                  <IconButton
                                    size="sm"
                                    colorScheme="green"
                                    mr={2}
                                    icon={<EditIcon />}
                                    onClick={() =>
                                      openEditModuleWithIdModule(
                                        val.id,
                                        val.titre,
                                        val.description,
                                        val.diplome,
                                        val.validite_certificat,
                                        val.signateur,
                                        val.postSignateur,
                                        val.genreSignateur,
                                        val.responsable
                                      )
                                    }
                                  >
                                    Modifier
                                  </IconButton>
                                  <IconButton
                                    size="sm"
                                    colorScheme="gray"
                                    icon={<MdHistory />}
                                    onClick={() =>
                                      navigate("/historique_module", {
                                        state: {
                                          idModule: val.id,
                                        },
                                      })
                                    }
                                  >
                                    Historique
                                  </IconButton>
                                </>
                              )}
                            </Td>
                          </>
                        )}
                    </Tr>
                  );
                })}
              {filteredData().length === 0 && (
                <Tr>
                  <Td colSpan={6}>
                    Aucune ligne correspondante n'a été trouvée.
                  </Td>
                </Tr>
              )}
            </Tbody>
          </Table>
        </TableContainer>

        <Flex justify="space-between" align="center" w="100%">
          <Box flex="1">
            <MyPagination
              data={filteredData()}
              searchInput={search}
              PAGE_SIZE={pageSize}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </Box>

          <Select w="70px" value={pageSize} onChange={handlePageSizeChange}>
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="30">30</option>
            <option value="40">40</option>
            {/* Add more options as needed */}
          </Select>
        </Flex>
      </Stack>

      <AlertDialog
        isOpen={isAlertOpen}
        leastDestructiveRef={cancelRef}
        onClose={onCloseAlert}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Supprimer Module
            </AlertDialogHeader>

            <AlertDialogBody>
              êtes-vous sûr ? Vous ne pourrez pas annuler cette action
              ultérieurement.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onCloseAlert}>
                Annuler
              </Button>
              <Button
                onClick={() => DeleteModule(deleteId, titre)}
                colorScheme="red"
                ml={3}
              >
                Supprimer
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      {changeModal ? (
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Responsables : </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {listModule.map((val, key) => {
                if (val.id === deleteId) {
                  return listResponsable.map((v, k) => {
                    for (
                      let index = 0;
                      index < val.responsable.length;
                      index++
                    ) {
                      if (v.id === val.responsable[index]) {
                        return (
                          <Stack key={key}>
                            <Tag size="lg" my={2} p={2} borderRadius="full">
                              <Avatar src={v.image} size="xs" ml={-1} mr={2} />
                              <TagLabel
                                ml={5}
                              >{`${v.last_name} ${v.first_name}`}</TagLabel>
                            </Tag>
                          </Stack>
                        );
                      }
                    }
                  });
                }
              })}
            </ModalBody>
          </ModalContent>
        </Modal>
      ) : (
        <Modal closeOnOverlayClick={true} isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Statistiques d'évaluation</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              <Flex direction="column" align="center">
                <Flex>
                  <Flex width="100px" mr="20px">
                    <AiFillStar color="#d69e2e" fontSize="20px" />
                    <AiFillStar color="#d69e2e" fontSize="20px" />
                    <AiFillStar color="#d69e2e" fontSize="20px" />
                    <AiFillStar color="#d69e2e" fontSize="20px" />
                    <AiFillStar color="#d69e2e" fontSize="20px" />
                  </Flex>
                  <span>{rating5}</span>
                </Flex>
                <Flex>
                  <Flex width="100px" mr="20px">
                    <AiFillStar color="#d69e2e" fontSize="20px" />
                    <AiFillStar color="#d69e2e" fontSize="20px" />
                    <AiFillStar color="#d69e2e" fontSize="20px" />
                    <AiFillStar color="#d69e2e" fontSize="20px" />
                  </Flex>
                  <span>{rating4}</span>
                </Flex>
                <Flex>
                  <Flex width="100px" mr="20px">
                    <AiFillStar color="#d69e2e" fontSize="20px" />
                    <AiFillStar color="#d69e2e" fontSize="20px" />
                    <AiFillStar color="#d69e2e" fontSize="20px" />
                  </Flex>
                  <span>{rating3}</span>
                </Flex>
                <Flex>
                  <Flex width="100px" mr="20px">
                    <AiFillStar color="#d69e2e" fontSize="20px" />
                    <AiFillStar color="#d69e2e" fontSize="20px" />
                  </Flex>
                  <span>{rating2}</span>
                </Flex>
                <Flex>
                  <Flex width="100px" mr="20px">
                    <AiFillStar color="#d69e2e" fontSize="20px" />
                  </Flex>
                  <span>{rating1}</span>
                </Flex>
              </Flex>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </Box>
  );
}

export default TableModule;
