// Chakra imports
import {
  Avatar,
  Box,
  Flex,
  Icon,
  Image,
  Switch,
  Text,
  useColorMode,
  Heading,
  SimpleGrid,
  Progress,
  useColorModeValue,
  Card,
  CardBody,
  Badge,
  useToast,
  Button,
  Tooltip,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogContent

} from "@chakra-ui/react";
import Progres from './Progres'

import axiosClient from "../../axios-client";
import React, { useState, useEffect } from 'react';

import { FaCube } from "react-icons/fa";
import { useLocation, useParams } from 'react-router-dom';
import { IoDocumentsSharp } from "react-icons/io5";
import { progress } from "framer-motion";
import { BsTrash } from "react-icons/bs";

function ApprenantDetail() {

  const [listModule, setListModule] = useState([])
  const [listFormation, setListFormation] = useState([])

  const [listProgres, setListProgres] = useState([])
  const [listAcces, setListAcces] = useState([])
  const [listCertificats, setListCertificats] = useState([])
  const [listResultat, setListResultat] = useState([])
  const [listProgresCMD, setListProgretCMD] = useState([])
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const cancelRef = React.useRef()

  const [accesId, setAccesId] = useState(null);
  const [mdId, setMdId] = useState(null);

  const onOpenAlert = () => {
    setIsAlertOpen(true);
};


const onCloseAlert = () => {
    setIsAlertOpen(false);
};
  const getAccesbyApprenant = (idA, idM) => {
    let a = "error"
    listAcces.map((val, key) => {
      if (val.apprenant === idA && val.module === idM) {
        if ((val.etat === false && val.encours === true && val.refus === false)) {
          a = "en cours"
        }
        if (val.etat === false && val.encours === false && val.refus === true) {
          a = "reactiver"
        }
        if (val.etat === true && val.encours === false && val.refus === false) {
          a = "commencer"
        }
        if ((val.etat === false && val.encours === true && val.refus === true)) {
          a = "en cours d'activation"
        }
      }
    })
    return a
  }
  const getProgresbyApprenant = (idA, idM) => {
    let e = "error"
    let a
    listProgres.map((val, key) => {
      if (val.apprenant === idA && val.module === idM) {
        e = val.progres
        a = e.toFixed(0)
      }
    })

    return a
  }

  useEffect(() => {
    axiosClient.get('module/')
      .then((res) => {
        setListModule(res.data)
      })
  }, [])
  useEffect(() => {
    axiosClient.get('formation/')
      .then((res) => {
        setListFormation(res.data)
      })
  }, [])

  useEffect(() => {
    axiosClient.get('acces/')
      .then((res) => setListAcces(res.data))
  }, [])

  useEffect(() => {
    axiosClient.get('certificat/')
      .then((res) => setListCertificats(res.data))
  }, [])
  useEffect(() => {
    axiosClient.get('resultat/')
      .then((res) => setListResultat(res.data))

    axiosClient.get(`/progressmodcontent/`)
      .then((res) => setListProgretCMD(res.data.filter(row => row.apprenant === location.state.idApprenant)))
  }, [])



  useEffect(() => {
    axiosClient.get('progres/')
      .then((res) => setListProgres(res.data))
  }, [])

  const location = useLocation()
  const { colorMode } = useColorMode();

  const [avatarUrl, setAvatarUrl] = useState('');

  const { id } = useParams();
  const [apprenant, setApprenant] = useState('');

  useEffect(() => {
    axiosClient.get(`/apprenants/${location.state.idApprenant}/image`)
      .then(response => {
        setAvatarUrl(response.request.responseURL);
      })
      .catch(error => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    axiosClient.get(`/apprenants/${location.state.idApprenant}/`)
      .then(({ data }) => {
        setApprenant(data)
      })
  }, [])

  function truncateDescription(description) {
    const words = description.split(' ');
    const truncatedWords = words.slice(0, 15);
    return truncatedWords.join(' ') + (words.length > 15 ? '...' : '');
  }
  const [cpm, setCpm] = useState(0)

  function countModule() {
    let cmp = 0
    listAcces.map((val, key) => {
      if (val.apprenant === location.state.idApprenant) {
        if (!(val.etat === false && val.encours === true && val.refus === false)) {
          cmp++
        }
      }
    })
    return cmp
  }

  function certificatList(idR) {
    let b = "false"
    listCertificats.map((v, k) => {
      if (v.idResultat === idR) {
        b = "true"
      }

    })
    return b
  }

  function countCertificats() {
    let cmp = 0
    let id
    listResultat.map((val, key) => {
      if (val.idUser === location.state.idApprenant) {
        id = val.idResult
        if (certificatList(id) === "true") {
          cmp++
        }
      }
    })
    return cmp
  }
  const toast = useToast()
  const [etat, setEtat] = useState();

  const [listChapitre, setListChapitre] = useState([]);
  const [listMedia, setListMedia] = useState([]);
  useEffect(() => {
    axiosClient.get(`/chapitre/`).then((res) => setListChapitre(res.data))
    axiosClient.get(`/media/`).then((res) => setListMedia(res.data))
  }, [])


  function update() {

    axiosClient.get('acces/')
      .then((res) => setListAcces(res.data))

    axiosClient.get('certificat/')
      .then((res) => setListCertificats(res.data))
      
  }
  
  const handleDelete = async (id, idModule) => {
    try {
      let myMedia = []
      const chapitre = listChapitre.filter(row => row.module === idModule)
      for (let index = 0; index < chapitre.length; index++) {
        const medIds = listMedia
          .filter(row => row.chapitre === chapitre[index].id)
          .map(filteredMed => filteredMed.id);

        myMedia.push(...medIds); // Spread the IDs into the myMedia array
      }
      const progresCMD = listProgresCMD.filter(row => myMedia.includes(row.media))
      for (let index = 0; index < progresCMD.length; index++) {
        axiosClient.delete(`/progressmodcontent/${progresCMD[index].id}/`)
      }
      const progres = listProgres.filter(row => (row.apprenant === location.state.idApprenant && row.module === idModule));
      // console.log('progresCMD ', listProgresCMD.filter(row => myMedia.includes(row.media)));

      axiosClient.delete(`/acces/${id}/`);
      if (progres.length > 0) {
        axiosClient.delete(`/progres/${progres[0].id}/`)
      }

      // console.log('chap : ', chapitre);
      // console.log('media : ', myMedia);

      // console.log('Access, progress, and progress content modules deleted.', progres[0].id);

      // console.log(progres);
      toast({
        description: "L'accès est suprimé avec succès",
        status: 'success',
        duration: 2000,
        isClosable: true,
        position: 'bottom-right'
      })
      update()
      setListModule(rows => rows.filter(row => row.id !== idModule))
    } catch (error) {
      console.error('Error deleting access:', error);
    }
    onCloseAlert()

  };
  const EditActivation = (id, et, re) => {
    axiosClient.patch(`/acces/${id}/`, {
      etat: !et,
      encours: false,
      refus: !re,
    })
      .then((res) => {
        setListAcces(rows => rows.map(row => {
          if (row.id === id) {
            return {
              ...row,
              etat: !et,
              encours: false,
              refus: !re
            }
          }
          return row
        }))
        setEtat(!etat)
      })
    if (!et) {
      toast({
        description: `Le module est activé`,
        status: "success",
        duration: 2000,
        isClosable: true,
        position: "bottom-right",
      });
    }
    else {
      toast({
        description: `Le module est desactivé`,
        status: "warning",
        duration: 2000,
        isClosable: true,
        position: "bottom-right",
      });
    }

  };

  // Chakra color mode
  const textColor = useColorModeValue("gray.700", "white");
  const iconColor = useColorModeValue("blue.500", "white");
  const bgProfile = useColorModeValue("white", "gray.700");
  const borderProfileColor = useColorModeValue("white", "transparent");
  const emailColor = useColorModeValue("gray.400", "gray.300");

  return (
    <Box>
      <Flex shadow={'xl'} direction='column' pt={{ base: "30px", md: "30px", lg: "30px" }}>
        <Flex
          direction={{ sm: "column", md: "row" }}
          maxH='330px'
          justifyContent={{ sm: "center", md: "space-between" }}
          align='center'
          backdropFilter='blur(21px)'
          boxShadow='0px 2px 5.5px rgba(0, 0, 0, 0.02)'
          border='1.5px solid'
          borderColor={borderProfileColor}
          bg={bgProfile}
          p='24px'
          borderRadius='20px'>
          <Flex
            align='center'
            mb={{ sm: "10px", md: "0px" }}
            direction={{ sm: "column", md: "row" }}
            w={{ sm: "100%" }}
            textAlign={{ sm: "center", md: "start" }}>
            <Avatar
              src={avatarUrl}
              me={{ md: "22px" }}
              w='80px'
              h='80px'
              borderRadius='15px'
            />
            <Flex direction='column' maxWidth='100%' my={{ sm: "14px" }}>
              <Text
                fontSize={{ sm: "lg", lg: "xl" }}
                color={textColor}
                fontWeight='bold'
                ms={{ sm: "8px", md: "0px" }}>
                {apprenant.first_name} {apprenant.last_name}
              </Text>
              <Text
                fontSize={{ sm: "sm", md: "md" }}
                color={emailColor}
                fontWeight='semibold'>
                {apprenant.email}

              </Text>
            </Flex>
          </Flex>
          <Flex
            direction={{ sm: "column", lg: "row" }}
            w={{ sm: "100%", md: "50%", lg: "auto" }}>
            <Card p='0px' bg='transparent' variant='no-effects'>
              <Flex
                align='center'
                w={{ sm: "100%", lg: "200px" }}
                borderRadius='8px'
                justifyContent='center'
                py='10px'
                cursor='pointer'>
                <Icon color={'yellow.400'} as={FaCube} me='11px' boxSize='30px' />
                <Box>
                  <Text color={textColor} fontWeight='bold'>
                    Nombre de modules
                  </Text>
                  <Text fontSize={'xl'} color={textColor} fontWeight='bold'>
                    {countModule()}
                  </Text>
                </Box>
              </Flex>

            </Card>
            <Card p='0px' bg='transparent' variant='no-effects'>
              <Flex
                align='center'
                w={{ lg: "250px" }}
                borderRadius='15px'
                justifyContent='center'
                py='10px'
                mx={{ lg: "1rem" }}
                cursor='pointer'>
                <Icon color={'green.400'} as={IoDocumentsSharp} me='11px' boxSize='30px' />
                <Box>
                  <Text color={textColor} fontWeight='bold'>
                    Nombre de certificats
                  </Text>
                  <Text fontSize={'xl'} color={textColor} fontWeight='bold'>
                    {countCertificats()}
                  </Text>
                </Box>
              </Flex>
            </Card>

          </Flex>
        </Flex>
      </Flex >

      {listFormation.map((formation, index) => (
        <div key={index}>
          {listModule.filter(val => val.formation === formation.id && (getAccesbyApprenant(apprenant.id, val.id) === "commencer" || getAccesbyApprenant(apprenant.id, val.id) === "reactiver" || getAccesbyApprenant(apprenant.id, val.id) === "en cours d'activation")).length > 0 &&
            <Heading mt={5} size='md'>Formation {formation.titre}</Heading>
          }
          <SimpleGrid mt={5} spacing={4} columns={{ base: 1, md: 2 }} >

            {listModule.filter(val => val.formation === formation.id && (getAccesbyApprenant(apprenant.id, val.id) === "commencer" || getAccesbyApprenant(apprenant.id, val.id) === "reactiver" || getAccesbyApprenant(apprenant.id, val.id) === "en cours d'activation")).map((val, index) => (

              <Card
                direction={{ base: 'column', sm: 'row' }}
                overflow='hidden'
                variant='outline'

              >
                <Image
                  objectFit='cover'
                  maxW={{ base: '100%', sm: '150px' }}
                  src={val.image}
                  alt='Caffe Latte'
                />
                <CardBody >
                  <Heading size='md'>{val.titre}</Heading>

                  <Text py='2' mb="8px">
                    {truncateDescription(val.description)}
                  </Text>
                  <Box >
                    {
                      getProgresbyApprenant(apprenant.id, val.id) === "error"
                        ? <Progress size='xs' isIndeterminate />
                        : getAccesbyApprenant(apprenant.id, val.id) === "reactiver" || getAccesbyApprenant(apprenant.id, val.id) === "en cours d'activation"
                          ?
                          <Progres color={'red'} value={getProgresbyApprenant(apprenant.id, val.id) > 100 ? 100 : getProgresbyApprenant(apprenant.id, val.id)} a={getAccesbyApprenant(apprenant.id, val.id)} />
                          : getAccesbyApprenant(apprenant.id, val.id) === "commencer"
                            ?
                            <Progres color={'green'} value={getProgresbyApprenant(apprenant.id, val.id) > 100 ? 100 : getProgresbyApprenant(apprenant.id, val.id)} />
                            : null
                    }
                    {listAcces

                      .map((v, key) => {
                        if (v.module === val.id && v.apprenant === apprenant.id) {
                          return (
                            <>
                              <Flex align="center" justify="space-between" mt={2}>
                                <Flex>
                                  <Switch
                                    size="md"
                                    colorScheme="green"
                                    isChecked={v.etat}
                                    onChange={() => {
                                      EditActivation(
                                        v.id,
                                        v.etat,
                                        v.refus
                                      )
                                    }
                                    }

                                  />
                                  <Badge
                                    rounded="lg"
                                    colorScheme={v.etat ? "green" : "red"}
                                  >
                                    {v.etat ? "Activé" : "Desactivé"}
                                  </Badge>
                                </Flex>

                                <BsTrash cursor='pointer' color="red" title="supprimer l'accès" fontSize="20" onClick={() => {
                                  onOpenAlert();
                                  
                                  setMdId(val.id)
                                  setAccesId(v.id)

                                  // onOpen();
                                }} />
                              </Flex>

                            </>
                          )
                        }
                      })}

                  </Box>
                </CardBody>

              </Card>
            ))}
          </SimpleGrid>
        </div>
      ))}
      <AlertDialog
                isOpen={isAlertOpen}
                leastDestructiveRef={cancelRef}
                onClose={onCloseAlert}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>

                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            Supprimer l'accès
                        </AlertDialogHeader>

                        <AlertDialogBody>
                        Attention ! Cette suppression est irréversible. Êtes-vous sûr de vouloir continuer ? Toutes les données de l'apprenant seront perdues définitivement.</AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onCloseAlert}>
                                Annuler
                            </Button>
                            <Button onClick={() => handleDelete(accesId,mdId)} colorScheme='red' ml={3}>
                                Supprimer
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
    </Box>
  );
}

export default ApprenantDetail;