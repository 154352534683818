import logo from '../../assets/img/logo.png';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import { createRef, useState, useEffect } from 'react';
import { useStateContext } from '../../context/ContextProvider';
import { GrAdd } from 'react-icons/gr';
import responsable_img from '../../assets/img/responsableimg.png'
import countryData from '../../assets/dataJson/countries.json';


import {
    Box,
    Select,
    FormControl,
    FormLabel,
    Input,
    Flex,
    InputGroup,
    HStack,
    InputRightElement,
    useColorModeValue,
    Stack,
    useToast,
    Button,
    Heading,
    Text,
    Image,
    Alert,
    AlertIcon,
    Spinner,
    InputLeftElement,

} from '@chakra-ui/react';
import axiosClient from '../../axios-client';
import { TfiReload } from 'react-icons/tfi';
import MySelect from "react-select";

const linkStyle = {
    color: '#3C8DBC',
    textDecoration: "underline"
}


export default function AddResponsable() {
    //toast variable
    const toast = useToast()
    const navigate = useNavigate()

    const emailRegex = /^[a-zA-Z][a-zA-Z0-9._-]*@[a-zA-Z]+(?:-[a-zA-Z]+)?\.[a-zA-Z]{2,}$/;
    const codepRegex = /^(\d{3})$|(\d{6})$|([A-Z]\d{4}[A-Z]{3})$|(\d{4})$|(\d{4})$|(?:FI)*(\d{5})$|(?:AZ)*(\d{4})$|(\d{5})$|(?:BB)*(\d{5})$|(\d{4})$|(\d{4})$|(\d{4})$|(\d{3}\d?)$|([A-Z]{2}\d{2})$|([A-Z]{2}\d{4})$|(\d{8})$|(\d{6})$|([ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVWXYZ]) ?(\d[ABCEGHJKLMNPRSTVWXYZ]\d)$|(\d{4})$|(\d{7})$|(\d{6})$|(\d{4})$|(?:CP)*(\d{5})$|(\d{4})$|(\d{4})$|(\d{4})$|(\d{5})$|(\d{5})$|(?:FI)*(\d{5})$|(\d{5})$|(\d{4})$|(\d{6})$|(?:SEOUL)*(\d{6})$|(\d{5})$|(\d{6})$|(\d{5})$|(\d{4})$|(\d{5})$|(\d{5})$|(\d{10})$|(\d{3})$|(\d{5})$|(\d{5})$|([A-Z]\d{2}[A-Z]{2})|([A-Z]\d{3}[A-Z]{2})|([A-Z]{2}\d{2}[A-Z]{2})|([A-Z]{2}\d{3}[A-Z]{2})|([A-Z]\d[A-Z]\d[A-Z]{2})|([A-Z]{2}\d[A-Z]\d[A-Z]{2})|(GIR0AA)$|(\d{5})$|(\d{7})$|([A-Z]\d{2}[A-Z]{2})|([A-Z]\d{3}[A-Z]{2})|([A-Z]{2}\d{2}[A-Z]{2})|([A-Z]{2}\d{3}[A-Z]{2})|([A-Z]\d[A-Z]\d[A-Z]{2})|([A-Z]{2}\d[A-Z]\d[A-Z]{2})|(GIR0AA)$|(\d{5})$|(\d{4}(\d{4})?)$|(\d{4})$|(\d{5})$|(\d{6})$|(\d{5})$|(\d{6})$|(?:SEOUL)*(\d{6})$|(\d{5})$|(\d{5})$|(\d{5})$|(\d{6})$|(\d{4})$|(\d{7})$|(97500)$|(\d{9})$|(\d{7})$|(96940)$|(\d{4})$|((97|98)(4|7|8)\d{2})$|(\d{6})$|(\d{6})$|(\d{6})$|(\d{5})$|(\d{5})$|(?:SE)*(\d{5})$|(\d{6})$|(STHL1ZZ)$|(?:SI)*(\d{4})$|(\d{5})$|4789\d$|(\d{5})$|(?:CP)*(\d{4})$|([A-Z]\d{3})$|(TKCA 1ZZ)$|(\d{5})$|(\d{6})$|(\d{6})$|(\d{4})$|(\d{5})$|(\d{5})$|(986\d{2})$|(\d{5})$|(\d{4})$|(\d{5})$|(\d{5})$|([A-Z]{1,2}\d[A-Z\d]? \d[A-Z]{2})$/i;


    const [first_name, setFirstName] = useState("")
    const [last_name, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [rue, setRue] = useState("")
    const [ville, setVille] = useState("")
    const password = createRef()
    const [codePostal, setCodePostal] = useState("")
    const confirmPassword = createRef()

    const [pwd, setPwd] = useState('');

    const [message, setMessage] = useState('')
    const [showPassword, setShowPassword] = useState(false);
    const [selectedRole, setSelectedRole] = useState('')

    const { user, isLoading, setIsLoading } = useStateContext()

    const handleRoleChange = (ev) => {
        setSelectedRole(ev.target.value) // update selected role when option is selected
    }



    ////////////////////////// VALIDATION DE MOT DE PASSE /////////////////////////////////////////
    const [passwordStrength, setPasswordStrength] = useState('');
    const [passwordColor, setPasswordColor] = useState('');

    const checkPasswordStrength = () => {
        const specialchar = /[@#$%^&+=!*_|èàç()/."';:,?ù]/;
        const minLengthRegex = /^.{8,}$/;
        const startLength = /^.{2,}$/;
        const digitRegex = /\d/;
        const lowercaseRegex = /[a-z]/;
        const uppercaseRegex = /[A-Z]/;

        let missingRequirements = [];

        if (!specialchar.test(pwd)) {
            missingRequirements.push("caractère spécial");
        } else if (!lowercaseRegex.test(pwd)) {
            missingRequirements.push("minuscule");
        } else if (!uppercaseRegex.test(pwd)) {
            missingRequirements.push("majuscule");
        } else if (!digitRegex.test(pwd)) {
            missingRequirements.push("chiffres");
        } else if (!minLengthRegex.test(pwd)) {
            missingRequirements.push("longueur minimale de 8 caractères");
        }

        if (missingRequirements.length > 0) {
            const missingText = `Vous avez besoin de ${missingRequirements.join(", ")} dans votre mot de passe.`;
            setPasswordStrength(missingText);
            setPasswordColor('red.500');
        } else {
            setPasswordStrength('Le mot de passe est correct!');
            setPasswordColor('green');
        }
    }

    useEffect(() => {
        checkPasswordStrength();
    }, [pwd]);
    // check the password complexity
    const isPasswordValid = (password) => {
        const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=!*_|èàç()/."';:,?ù])[0-9a-zA-Z@#$%^&+=!*_|èàç()/."';:,?ù]{8,}$/;
        const specialchar = /[@#$%^&+=!*_|èàç()/."';:,?ù]/;
        const minLengthRegex = /^.{8,}$/;
        const digitRegex = /\d/;
        const lowercaseRegex = /[a-z]/;
        const uppercaseRegex = /[A-Z]/;

        let errors = [];

        if (!minLengthRegex.test(password)) {
            errors.push('Le mot de passe doit comporter au moins 8 caractères.');
        }

        if (!digitRegex.test(password)) {
            errors.push('Le mot de passe doit contenir au moins un chiffre.');
        }

        if (!lowercaseRegex.test(password)) {
            errors.push('Le mot de passe doit contenir au moins une lettre minuscule.');
        }

        if (!uppercaseRegex.test(password)) {
            errors.push('Le mot de passe doit contenir au moins une lettre majuscule.');
        }
        if (!specialchar.test(password)) {
            errors.push('Le mot de passe doit contenir au moins un caractère spécial (@#$%^&+=).');
        }
        if (password.length > 20) {
            errors.push('Le mot de passe ne doit pas dépasser 20 caractères.');
        }

        if (errors.length > 0) {
            setMessage(errors[0]);
            return false;
        }

        return passwordRegex.test(password);
    };

    const isEmpty = () => {
        return !last_name || !first_name || !email || !pwd || !selectedRole || !selectedCountry.label;
    };

    const onSubmit = ev => {
        ev.preventDefault()

        const payload = {
            first_name: first_name,
            last_name: last_name,
            email: email,
            pays: selectedCountry.label,
            ville: ville,
            code_postal: codePostal,
            rue: rue,
            added_by: user.first_name + ' ' + user.last_name,
            password: password.current.value,
            role: user.role === "ADMIN" ? "RESPO" : selectedRole
        }

        if (first_name.trim() === "" || last_name.trim() === "" || (ville && ville.trim() === "") || (codePostal && codePostal.trim() === "") || (rue && rue.trim() === "")) {
            window.scrollTo(0,0)
            setMessage('Veuillez remplir les champs correctement')
            return
        }
        setMessage("")

        if (password.current.value !== confirmPassword.current.value) {
            setMessage("Veuillez confirmer votre mot de passe");
        } else if (passwordStrength !== 'Fort' && password.current.value.length > 0 && !isPasswordValid(password.current.value)) {
            return;
        }
        else if (!emailRegex.test(email)) {
            setMessage("Veuillez entrer un email valide.");
            setIsLoading(false)
        }
        else if (codePostal && !codepRegex.test(codePostal)) {
            setMessage("Veuillez entrer un code postal valide.");
            return;
        }

        else {
            axiosClient.post('/responsables/', payload)
                .then((response) => {
                    toast({
                        description: "le responsable est ajouté avec succès",
                        status: 'success',
                        duration: 2000,
                        isClosable: true,
                        position: 'bottom-right'
                    })
                    navigate('/responsables')

                })
                .catch((err) => {
                    const response = err.response;
                    setMessage(response.data)
                })
        }
    }

    const [disableInputConfirm, setDisableInputConfirm] = useState(false)
    const [generetedPwd, setGeneretedPwd] = useState()
    const generatePassword = () => {
        const length = 10; // Length of the generated password
        const uppercaseLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        const lowercaseLetters = 'abcdefghijklmnopqrstuvwxyz';
        const numbers = '0123456789';
        const specialCharacters = '[@#$%^&+=!*_|èàç()/.";:,?ù]';

        let newPassword = '';
        let characterSet = '';

        // Include at least one character from each set
        newPassword += uppercaseLetters[Math.floor(Math.random() * uppercaseLetters.length)];
        newPassword += lowercaseLetters[Math.floor(Math.random() * lowercaseLetters.length)];
        newPassword += numbers[Math.floor(Math.random() * numbers.length)];
        newPassword += specialCharacters[Math.floor(Math.random() * specialCharacters.length)];

        characterSet = uppercaseLetters + lowercaseLetters + numbers + specialCharacters;

        // Generate remaining characters randomly
        for (let i = newPassword.length; i < length; i++) {
            newPassword += characterSet[Math.floor(Math.random() * characterSet.length)];
        }

        setPwd(newPassword);
        confirmPassword.current.value = newPassword
        setGeneretedPwd(newPassword)
        setDisableInputConfirm(true)

    };


    const [countries, setCountries] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState({});

    useEffect(() => {
        const Mycountries = [];
        const data = countryData;
        for (let index = 0; index < data.countries.length; index++) {
            Mycountries.push({
                value: data.countries[index].value,
                label: data.countries[index].label.substring(5, 50)
            });
        }
        setCountries(Mycountries);
    }, []);


    const colorbg = useColorModeValue('white', '#2d3748')
    const txtcolor = useColorModeValue('black', 'white')
    const colorbghover = useColorModeValue('#e2e8f0', '#171923')

    return (
        <Box>
            <Heading bgGradient='linear(to-l,  #ffd140, #089bd7)' bgClip='text' ml={5} mt={5} fontSize={{ base: '2xl', sm: '3xl' }}>
                Ajouter un responsable
            </Heading>
            <Flex
                direction={{ base: 'column', md: 'row' }}
                align={{ base: 'stretch', md: 'center' }}
                justify={{ base: 'flex-start', md: 'space-between' }}
                p={5}
            >

                <Stack
                    bg={useColorModeValue('white', 'gray.700')}
                    rounded={'lg'}
                    p={6}
                    my={5}
                    w={{ base: '100%', md: '100%', lg: '80%', sm: '100%' }}
                >
                    {message &&
                        <Alert status='error' rounded="md">
                            <AlertIcon />
                            {message}
                        </Alert>
                    }
                    <Box p={5} rounded={'lg'} w="100%" maxW="full"
                        align={'center'}
                        justify={'center'}
                    >
                        <Stack spacing={2} w={{ base: '100%', md: '100%', lg: '100%', sm: '100%' }}
                        >
                            <HStack >
                                <Box w="50%">
                                    <FormControl id="firstName" isRequired >
                                        <FormLabel ml={0.5}>Nom </FormLabel>
                                        <Input value={last_name} onChange={(e) => setLastName(e.target.value)} name="last_name" type="text" placeholder='Tapez le prénom' maxLength={25} />
                                    </FormControl>
                                </Box>
                                <Box w="50%">
                                    <FormControl id="lastName" isRequired >
                                        <FormLabel ml={0.5}>Prénom</FormLabel>
                                        <Input value={first_name} onChange={(e) => setFirstName(e.target.value)} type="text" placeholder='Tapez le nom' maxLength={25} />

                                    </FormControl>
                                </Box>
                            </HStack>
                            <HStack spacing={2}>
                                <Box w="100%">
                                    <FormControl id="email" isRequired >
                                        <FormLabel ml={0.5}>Adresse email</FormLabel>
                                        <Input value={email} onChange={(e) => setEmail(e.target.value)} name="email" type="email" placeholder="Tapez l'adresse email" maxLength={50} />
                                    </FormControl>
                                </Box>

                            </HStack>
                            <HStack spacing={2}>
                                <Box w="50%">
                                    <FormControl id="rue">
                                        <FormLabel ml={0.5}>Rue</FormLabel>
                                        <Input value={rue} onChange={(e) => setRue(e.target.value)} name="rue" type="text" placeholder='Tapez la rue' maxLength={50} />
                                    </FormControl>

                                </Box>
                                <Box w="50%">
                                    <FormControl id="ville">
                                        <FormLabel ml={0.5}>Ville</FormLabel>
                                        <Input value={ville} onChange={(e) => setVille(e.target.value)} name="ville" type="text" placeholder='Tapez la ville' maxLength={30} />
                                    </FormControl>
                                </Box>

                            </HStack>
                            <HStack spacing={2}>
                                <Box w="50%">
                                    <FormControl id="codePostal" >
                                        <FormLabel ml={0.5}>Code postal</FormLabel>
                                        <Input value={codePostal} onChange={(e) => setCodePostal(e.target.value)} name="codePostal" type="text" placeholder='Tapez le code postal' maxLength={15} />
                                    </FormControl>
                                </Box>
                                <Box w="50%">
                                    <FormControl id="pays" isRequired>
                                        <FormLabel ml={0.5}>Pays</FormLabel>
                                        {/* <Input ref={pays} name="pays" type="text" placeholder='Tapez le pays' maxLength={30} /> */}
                                        <MySelect

                                            name="pays"
                                            options={countries}
                                            value={selectedCountry}
                                            onChange={(selectedOption) => setSelectedCountry(selectedOption)}
                                            isSearchable
                                            styles={{
                                                control: (provided) => ({
                                                    ...provided,
                                                    textAlign: 'left',
                                                    backgroundColor: colorbg,
                                                    color: txtcolor
                                                }),
                                                menu: (provided) => ({
                                                    ...provided,
                                                    textAlign: 'left',
                                                    backgroundColor: colorbg,
                                                    cursor: "pointer"
                                                }),
                                                option: (provided, state) => ({
                                                    ...provided,
                                                    color: txtcolor,
                                                    backgroundColor: state.isSelected ? colorbg : 'inherit',
                                                    '&:hover': {
                                                        backgroundColor: colorbghover,
                                                    },
                                                    cursor: "pointer"
                                                }),
                                                singleValue: (provided) => ({
                                                    ...provided,
                                                    color: txtcolor
                                                }),
                                            }}
                                        />
                                    </FormControl>
                                </Box>
                            </HStack>
                            <Text align={'left'} fontSize="sm" color='yellow.600'>Le mot de passe doit comporter au moins 8 caractères et contenir au moins un chiffre, une lettre minuscule , une lettre majuscule et un chiffre special.</Text>

                            <HStack>
                                <Box w="50%">
                                    <FormControl id="password" isRequired>
                                        <FormLabel ml={0.5}>Mot de passe</FormLabel>
                                        <InputGroup>
                                            <InputLeftElement onClick={generatePassword} as={'Button'} >
                                                <TfiReload color='gray.300' />
                                            </InputLeftElement>
                                            <Input value={pwd} ref={password} name="password" type={showPassword ? 'text' : 'password'} onChange={(e) => setPwd(e.target.value)} onCopy={(e) => e.preventDefault()} placeholder='Tapez votre mot de passe' />

                                            <InputRightElement h={'full'}>

                                                <Button
                                                    variant={'ghost'}
                                                    onClick={() =>
                                                        setShowPassword((showPassword) => !showPassword)
                                                    }>
                                                    {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                                                </Button>
                                            </InputRightElement>
                                        </InputGroup>


                                    </FormControl>

                                </Box>

                                <Box w="50%">
                                    <FormControl id="password_confirmation" isRequired>
                                        <FormLabel ml={0.5}>Confirmer le mot de passe</FormLabel>
                                        <InputGroup>
                                            {(disableInputConfirm && pwd === generetedPwd) ?
                                                <Input ref={confirmPassword} name="confirmPassword" type="password" placeholder='Confirmez votre mot de passe' disabled />
                                                :
                                                <Input ref={confirmPassword} name="confirmPassword" type="password" placeholder='Confirmez votre mot de passe' />
                                            }
                                        </InputGroup>
                                    </FormControl>
                                </Box>
                            </HStack>
                            {pwd.length > 0 ?
                                <Text align="left" justify="left" fontSize="sm" mt={2} color={passwordColor}>{`${passwordStrength}`}</Text>

                                : ""}
                            {(user.role === "SUPERADMIN" || user.role === "MASTER") &&
                                <HStack spacing={2}>
                                    <Box w={{ base: '100%', md: '50%' }}>
                                        <FormControl id="role" isRequired >
                                            <FormLabel ml={0.5}>Role</FormLabel>
                                            <Select placeholder="Sélectionnez" onChange={handleRoleChange}>

                                                <option value="MASTER">Super administrateur</option>

                                                <option value="ADMIN">Administrateur</option>
                                                <option value="RESPO">Responsable</option>
                                            </Select>
                                        </FormControl>

                                    </Box>

                                </HStack>
                            }
                            <Stack direction={['column', 'row']} spacing={10} pt={2} justifyContent="end">
                                {
                                    isEmpty() ? (
                                        <Button
                                            type="submit"
                                            onClick={onSubmit}
                                            isLoading={isLoading}
                                            colorScheme="yellow"
                                            isDisabled
                                            leftIcon={<GrAdd />}
                                        >
                                            {isLoading ? <Spinner size="sm" /> : "Ajouter"}
                                        </Button>
                                    ) : (
                                        <Button
                                            type="submit"
                                            onClick={onSubmit}
                                            isLoading={isLoading}
                                            colorScheme="yellow"
                                            leftIcon={<GrAdd />}
                                        >
                                            {isLoading ? <Spinner size="sm" /> : "Ajouter"}
                                        </Button>
                                    )
                                }
                            </Stack>
                        </Stack>
                    </Box>
                </Stack>
                <Stack w="50%"
                    justify="center"
                    alignItems="center"
                    p={5}
                >
                    <Image display={{ base: 'none', md: 'flex' }} src={responsable_img} />
                </Stack>
            </Flex>
        </Box>

    )
}