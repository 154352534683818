import { Text, useBreakpointValue, useMediaQuery } from '@chakra-ui/react';
import Chart from 'react-apexcharts'

const DonutChart = ({ title, data }) => {
  const width = useBreakpointValue({ base: "300px", md: "380px" });
  return (
    <div>
      <Text fontSize={20}>{title}</Text>
      <Chart options={data.options} series={data.series} type="donut" width={width}
      />
    </div>

  )
}

export default DonutChart