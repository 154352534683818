import React, { useState, useEffect, useCallback } from 'react';
import axiosClient from '../../axios-client';
import { useNavigate } from 'react-router-dom';
import { AddIcon, SearchIcon, DeleteIcon, EditIcon } from '@chakra-ui/icons'
import MyPagination from "../../components/MyPagination";
import { useStateContext } from "../../context/ContextProvider";
import { MdHistory } from 'react-icons/md';
// import { LiaUserEditSolid } from 'react-icons/lia';
import moment from 'moment';

import {
    IconButton,
    Flex,
    Box,
    Heading,
    Button,
    Stack,
    Table,
    Thead,
    InputGroup,
    InputLeftElement,
    Tbody,
    Tr,
    Th,
    Select,
    Avatar,
    Switch,
    Badge,
    Td,
    Input,
    FormControl,
    FormLabel,
    useToast,
    TableContainer,
    useColorModeValue,
    useDisclosure,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    HStack,
    Tooltip,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverArrow,
    PopoverCloseButton,
    PopoverHeader,
    PopoverBody,
    PopoverFooter,
    Text,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription

} from '@chakra-ui/react';

export const GestApprenant = () => {
    const [fullName, setFullName] = useState()
    const [oldRole, setOldRole] = useState()
    const [newRole, setNewRole] = useState()
    const [selectedRole, setSelectedRole] = useState()
    const toast = useToast()
    const [selectedEtat, setSelectedEtat] = useState(null);
    const [selectedUser, setSelectedUser] = useState('');

    //search variable
    const [searchTerm, setSearchTerm] = useState('');

    const navigate = useNavigate()

    const [isAlertOpen, setIsAlertOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const initialFocusRef = React.useRef()


    const onOpenAlert = () => {
        setIsAlertOpen(true);
    };

    const onOpenModal = () => {
        setIsModalOpen(true);
    };

    const onCloseAlert = () => {
        setIsAlertOpen(false);
    };

    const onCloseModal = () => {
        setIsModalOpen(false);
    };

    const MAX_WORDS = 2; // Maximum number of words to display

    function truncateAddress(address) {
        const words = address.split(' ');
        if (words.length > MAX_WORDS) {
            return words.slice(0, MAX_WORDS).join(' ') + '...';
        }
        return address;
    }
    ////////////////////////////////the size of the table//////////////////////////////
    const PAGE_SIZE = 10;
    const [pageSize, setPageSize] = useState(PAGE_SIZE);
    const handlePageSizeChange = (event) => {
        const newSize = parseInt(event.target.value, 10);
        setPageSize(newSize);
        setCurrentPage(0);
    };
    //////////////////////////////////////////////////////////////////////////////////

    // current user
    const { user, setUser } = useStateContext();
    const initialRef = React.useRef(null)
    const finalRef = React.useRef(null)

    const [deleteId, setDeleteId] = useState(null);
    const cancelRef = React.useRef()

    //Apprenant data
    const [data, setData] = useState([]);
    const [listModule, setListModule] = useState([])
    const [listFormation, setListFormation] = useState([])

    // current user data
    useEffect(() => {
        axiosClient.get('auth/user/')
            .then(({ data }) => {
                setUser(data)
            })
    }, [])

    //get the apprenant data
    useEffect(() => {
        axiosClient.get('/formation/').then((res) => {
            setListFormation(res.data)
        })
        axiosClient.get('/module/').then((res) => {
            setListModule(res.data)
        })
        axiosClient.get('/responsables/')
            .then((response) => {
                let filteredData = response.data;
                if (selectedEtat !== null) {
                    filteredData = filteredData.filter((item) => item.etat === selectedEtat);
                }
                if (selectedUser !== '') {
                    filteredData = filteredData.filter((item) => item.role === selectedUser);
                }
                setData(filteredData.sort().reverse());
            })
            .catch((error) => {
                console.error(error);
            });
    }, [user.role, selectedUser]);


    const handleRoleSubmit = () => {
        changeRole(deleteId, selectedRole);
        getModulebyRespo(deleteId)
        setIsModalOpen(false);
    };

    const [message, setMessage] = useState(false)

    const getModulebyRespo = (id) => {

        const moduleRespo = (listModule.filter(row => row.responsable.find(obj => obj === id)))

        const listModuleManyRespo = moduleRespo.filter(row => row.responsable.length > 1)
        const listModuleOneRespo = moduleRespo.filter(row => row.responsable.length < 2)

        let manyRespo = []
        let oneRespo = []

        if (listModuleManyRespo.length > 0) {
            listModuleManyRespo.map((val) => {
                manyRespo.push({
                    formation: listFormation.filter(row => row.id === val.formation)[0].titre,
                    titreModule: val.titre
                })
            })
        }

        if (listModuleOneRespo.length > 0) {
            listModuleOneRespo.map((val) => {
                oneRespo.push({
                    formation: listFormation.filter(row => row.id === val.formation)[0].titre,
                    titreModule: val.titre
                })
            })
        }

        //Regrouper les modules par formation
        const groupedByValue = oneRespo.reduce((acc, curr) => {
            const { formation, titreModule } = curr;
            if (!acc[formation]) {
                acc[formation] = [];
            }
            acc[formation].push(titreModule);
            return acc;
        }, {});

        const formattedOutput = Object.entries(groupedByValue)
            .map(([formation, titreModules]) => `${titreModules.length > 1 ? "des modules :" : "du module :"} ${titreModules.join(', ')} de la formation ${formation}`)
            .join(', ');

        return { manyRespo, oneRespo, listModuleManyRespo, listModuleOneRespo, formattedOutput }

    }



    const changeRole = (id) => {
        const updatedData = data.map(row => {
            if (row.id === id) {
                const formData = new FormData();

                formData.append("role", selectedRole);
                formData.append("edited_by", user.first_name + ' ' + user.last_name);


                if (getModulebyRespo(id).listModuleManyRespo.length > 0) {
                    getModulebyRespo(id).listModuleManyRespo.map((val, key) => {
                        const formData = new FormData()
                        formData.append("responsable", val.responsable.filter(element => element !== id))
                        axiosClient
                            .patch(`/module/${val.id}/`, formData)
                    })
                }


                axiosClient.put(`/role_update/${id}/`, formData)
                    .then((response) => {
                        const updatedRow = response.data;
                        // Update the row object with the new etat value
                        setData(rows => rows.map(row => {
                            if (row.id === id) {
                                return {
                                    ...row,
                                    role: selectedRole,
                                    date_modification: moment(updatedRow.date_modification).format('YYYY-MM-DD HH:mm:ss'),
                                    edited_by: updatedRow.edited_by || (user.first_name + ' ' + user.last_name),
                                };
                            }
                            return row;
                        }));
                        toast({
                            description: "le role est changé",
                            status: 'success',
                            duration: 2000,
                            isClosable: true,
                            position: 'bottom-right'
                        })
                    })

                    .catch((error) => console.error(error));

            }
        });
    }

    const handleDelete = (id = deleteId) => {
        if (!id) return;
        axiosClient.delete(`/responsables/${id}/`)
            .then((response) => {
                setData((prevData) => prevData.filter((row) => row.id !== id));
                toast({
                    description: "l'utilisateur est supprimé avec succès",
                    status: 'warning',
                    duration: 2000,
                    isClosable: true,
                    position: 'bottom-right'
                })
            })
            .catch((error) => {
                console.error(error);
            });
        onCloseAlert()
    }

    //search method
    const filteredData = useCallback(() => {
        return data.filter((row) => {
            // Exclude data of current user
            if (row.id === user.id || row.role === "SUPERADMIN") {
                return false;
            }
            if (row.role === "MASTER" && (user.role !== "SUPERADMIN" && user.role !== "MASTER")) {
                return false;
            }
            if (row.role === "ADMIN" && (user.role !== "SUPERADMIN" && user.role !== "MASTER")) {
                return false;
            }

            return (
                row.last_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                row.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                row.code.toLowerCase().includes(searchTerm.toLowerCase()) ||
                row.pays.toLowerCase().includes(searchTerm.toLowerCase()) ||
                row.ville.toLowerCase().includes(searchTerm.toLowerCase()) ||
                row.rue.toLowerCase().includes(searchTerm.toLowerCase()) ||
                row.code_postal.toLowerCase().includes(searchTerm.toLowerCase()) ||
                row.email.toLowerCase().includes(searchTerm.toLowerCase())
            );
        });
    }, [data, searchTerm, user.id]);


    //edit l'etat de responsable ('activé','désactivé')
    const handleEtatClick = (id, etat) => {
        const formData = new FormData();
        formData.append("etat", !etat);
        formData.append("edited_by", user.first_name + ' ' + user.last_name);

        axiosClient.put(`/etat_update/${id}/`, formData)
            .then((response) => {
                const updatedRow = response.data;
                // Update the row object with the new etat value
                setData(rows => rows.map(row => {
                    if (row.id === id) {
                        return {
                            ...row,
                            etat: !etat,
                            date_modification: moment(updatedRow.date_modification).format('YYYY-MM-DD HH:mm:ss'),
                            edited_by: updatedRow.edited_by || (user.first_name + ' ' + user.last_name),
                        };
                    }
                    return row;
                }));
            })
            .catch((error) => console.error(error));
        if (!etat) {
            toast({
                description: "le compte est activé",
                status: 'success',
                duration: 2000,
                isClosable: true,
                position: 'bottom-right'
            })
        }
        else {
            toast({
                description: "le compte est désactivé",
                status: 'warning',
                duration: 2000,
                isClosable: true,
                position: 'bottom-right'
            })
        }
    }
    const [currentPage, setCurrentPage] = useState(0);


    const formatDate = (dateString) => {
        if (dateString) {
            const [year, month, day] = dateString.split('-');
            return `${day}-${month}-${year}`;
        }

    };


    return (
        <Box mt="5px" >
            <Flex>
                <Box w="90%" >
                    <Heading bgGradient='linear(to-l, #ffd140, #089bd7)' bgClip='text' fontSize={{ base: '2xl', sm: '3xl' }}>
                        Administrateurs
                    </Heading>
                </Box>

            </Flex>

            <Box
                justify={'end'}
                align={'end'}
                mt={5}>
                {(user.role === "SUPERADMIN" || user.role === "MASTER" || user.role === "ADMIN") &&
                    <Button
                        mt="10px"
                        onClick={() => navigate('/addResponsable')}
                        colorScheme="blue"
                        leftIcon={<AddIcon />}>
                        Ajouter responsable
                    </Button>}
            </Box>
            <Flex
                align={'center'}
                justify={'center'}
            >

                <Stack

                    w={'full'}
                    maxW='full'
                    bg={useColorModeValue('white', 'gray.700')}
                    rounded={'lg'}
                    p={6}
                    my={5}>
                    {/* Search input */}
                    <Flex
                        justifyContent="space-between"
                        alignItems="center"
                        direction={{ base: 'column', lg: 'row' }}
                    >
                        <Flex w={"100%"}>
                            <Select fontSize={{ base: 13, lg: 16 }} w={{ base: '70%', lg: '50%' }} mr={5} mb={4} value={selectedUser} onChange={(e) => setSelectedUser(e.target.value)}>
                                <option value="">Tout les utilisateurs</option>
                                <option value="MASTER">Super administrateur</option>
                                <option value="ADMIN">Administrateur</option>
                                <option value="RESPO">Responsable</option>
                            </Select>
                            <Select fontSize={{ base: 13, lg: 16 }} w={{ base: '30%', lg: "20%" }} mr={5} mb={4} value={selectedEtat} onChange={(e) => setSelectedEtat(e.target.value === '' ? null : e.target.value === 'true')}>
                                <option value="">Tout</option>
                                <option value="true">Activé</option>
                                <option value="false">Désactivé</option>
                            </Select>
                        </Flex>


                        <InputGroup w={{ base: '70%', lg: "30%" }}>
                            <InputLeftElement
                                pointerEvents='none'
                                children={<SearchIcon color='gray.300' />}
                            />
                            <Input
                                placeholder="Recherche..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                mb={4}
                                sx={{
                                    marginLeft: 'auto',
                                }}
                            />  </InputGroup>

                    </Flex>
                    <TableContainer>
                        <Table variant='striped' colorScheme="gray">
                            <Thead>
                                <Tr>
                                    <Th>Image</Th>
                                    <Th>Code</Th>
                                    <Th>Nom</Th>
                                    <Th>Prénom</Th>
                                    <Th>Email</Th>
                                    <Th >Adresse</Th>
                                    <Th >Pays</Th>
                                    <Th >Etat</Th>
                                    <Th >Role</Th>
                                    <Th>Date Creation</Th>
                                    {(user.role === "SUPERADMIN" || user.role === "MASTER") &&
                                        <Th>Options</Th>
                                    }
                                </Tr>
                            </Thead>
                            <Tbody>
                                {filteredData().filter((row) => selectedEtat === null || row.etat === selectedEtat && (selectedUser === '' || row.role === selectedUser)).slice(currentPage * pageSize, currentPage * pageSize + pageSize).map((row) => (
                                    <Tr p={0} key={row.id}>
                                        <Td > <Avatar src={row.image} /></Td>
                                        <Td>{row.code}</Td>
                                        <Td p={4}>{row.last_name}</Td>
                                        <Td p={4}>{row.first_name}</Td>
                                        <Td p={1}>{row.email}</Td>
                                        <Td>
                                            <Tooltip label={row.rue + " " + row.ville + " " + row.code_postal} closeOnClick={true}>
                                                {truncateAddress(row.rue + " " + row.ville + " " + row.code_postal)}
                                            </Tooltip>
                                        </Td>
                                        <Td>{row.pays}</Td>
                                        <Td p={5}>
                                            <Flex>
                                                <Stack direction='row' mr="3px">
                                                    {(user.role === "SUPERADMIN" || user.role === "MASTER" || user.role === "ADMIN") &&
                                                        <Switch
                                                            colorScheme='green'
                                                            isChecked={row.etat}
                                                            onChange={() => handleEtatClick(row.id, row.etat)}
                                                        />
                                                    }
                                                </Stack>
                                                <Badge
                                                    rounded={'lg'}
                                                    colorScheme={!row.etat ? 'red' : 'green'}
                                                >
                                                    {row.etat ? 'activé' : 'désactivé'}
                                                </Badge>
                                            </Flex>
                                        </Td>
                                        {(user.role === "SUPERADMIN" || user.role === "MASTER" || user.role === "ADMIN") ?
                                            <Td w={"100%"} p={0}>
                                                <Badge
                                                    display="flex"
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    rounded="full"
                                                    w={"fit-content"}
                                                    py={2}
                                                    px={4}
                                                    colorScheme={row.role === "MASTER" ? "telegram" : row.role === "ADMIN" ? "orange" : "purple"}
                                                    cursor={(user.role === "SUPERADMIN" || user.role === "MASTER") ? "pointer" : null}
                                                    title={(user.role === "SUPERADMIN" || user.role === "MASTER") ? "changer le rôle de l'utilisateur" : null}
                                                    onClick={() => {
                                                        if ((user.role === "SUPERADMIN" || user.role === "MASTER")) {
                                                            setFullName(`${row.first_name} ${row.last_name}`)
                                                            setSelectedRole(row.role)
                                                            setDeleteId(row.id);
                                                            setIsModalOpen(true);
                                                            if (getModulebyRespo(row.id).oneRespo.length > 0) {
                                                                setMessage(true)
                                                            }
                                                        }
                                                    }}>
                                                    {/* <LiaUserEditSolid size={20} /> */}
                                                    <Text ml={1}>{row.role === "MASTER" ? "Super-administrateur" : row.role === "ADMIN" ? "Administrateur" : "Responsable"}</Text>
                                                </Badge>
                                            </Td>
                                            : <Td> {row.role.toLowerCase() === 'admin' ? 'Administrateur' : row.role.toLowerCase() === 'respo' ? 'Responsable' : row.role.toLowerCase() === 'master' ? 'Super administrateur' : 'null'} </Td>
                                        }
                                        <Td>{new Date(row.date_creation).toLocaleDateString('fr-FR')}</Td>
                                        <Td>

                                            <IconButton
                                                size="sm"
                                                onClick={() => navigate(`/editResponsable`, {
                                                    state: {
                                                        idResponsable: row.id
                                                    }
                                                })}
                                                mr="5px"
                                                colorScheme="green"
                                                icon={<EditIcon />}>
                                            </IconButton>
                                            <Popover placement="left" >
                                                <PopoverTrigger>
                                                    <IconButton
                                                        size="sm"
                                                        mr="5px"
                                                        bg="gray.300"
                                                        color={"black"}
                                                        icon={<MdHistory />}>
                                                    </IconButton>
                                                </PopoverTrigger>
                                                <PopoverContent w={"full"} color="black" bgGradient='linear(to-r, gray.300, blue.200)' >
                                                    <PopoverHeader letterSpacing={1.5} pt={4} fontWeight="bold" border="0">
                                                        Historique
                                                    </PopoverHeader>
                                                    <PopoverArrow bg="blue.200" />
                                                    <PopoverCloseButton />
                                                    <PopoverBody>
                                                        <div style={{ marginBottom: '8px' }}>
                                                            Ajouté par : {row.added_by}
                                                        </div>
                                                        <div style={{ marginBottom: '8px' }}>
                                                            Modifié par : {row.edited_by}
                                                        </div>

                                                        <div>
                                                            Date de dernière modification: {new Date(row.date_modification).toLocaleString('fr-FR')}
                                                        </div>
                                                    </PopoverBody>
                                                    <PopoverHeader pt={4} fontWeight="bold" border="0">
                                                    </PopoverHeader>
                                                </PopoverContent>
                                            </Popover>
                                            {(user.role === "SUPERADMIN" || user.role === "MASTER") &&

                                                <IconButton
                                                    size="sm"
                                                    onClick={() => {
                                                        setDeleteId(row.id);
                                                        setSelectedRole(row.role === "MASTER" ? "Super-administrateur" : row.role === "ADMIN" ? "Administrateur" : "Responsable")
                                                        onOpenAlert();
                                                        if (getModulebyRespo(row.id).oneRespo.length > 0) {
                                                            setMessage(true)
                                                        }
                                                    }}
                                                    mr="5px"
                                                    colorScheme="red"
                                                    icon={<DeleteIcon />}>
                                                </IconButton>}
                                        </Td>

                                    </Tr>
                                ))}
                                {filteredData().length === 0 && (
                                    <Tr>
                                        <Td colSpan={11}>Aucune ligne correspondante n'a été trouvée.</Td>
                                    </Tr>
                                )}
                            </Tbody>
                        </Table>

                    </TableContainer>
                    {/* Pagination */}
                    <Flex justify="space-between" align="center" w="100%">
                        <Box flex="1">
                            <MyPagination
                                data={filteredData()}
                                searchInput={searchTerm}
                                PAGE_SIZE={pageSize}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                            />
                        </Box>

                        <Select w="70px" value={pageSize} onChange={handlePageSizeChange}>
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="30">30</option>
                            <option value="40">40</option>
                            {/* Add more options as needed */}
                        </Select>
                    </Flex>
                </Stack>
            </Flex>
            <AlertDialog
                closeOnOverlayClick={false}
                isOpen={isAlertOpen}
                leastDestructiveRef={cancelRef}
                onClose={onCloseAlert}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            Supprimer {selectedRole}
                        </AlertDialogHeader>

                        <AlertDialogBody>

                            {getModulebyRespo(deleteId).oneRespo.length > 0 ?
                                <Alert maxH={200} overflow='auto' status='error' alignItems="start">
                                    <AlertIcon />
                                    <AlertDescription><AlertTitle>Erreur !</AlertTitle>La suppression de ce responsable est impossible car il est en charge {getModulebyRespo(deleteId).formattedOutput}.<br /> Pour procéder à cette modification, un autre responsable doit être affecté à ces modules.</AlertDescription>
                                </Alert> :
                                <Text>
                                    êtes-vous sûr ? Vous ne pourrez pas annuler cette action ultérieurement.
                                </Text>
                            }
                        </AlertDialogBody>



                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={() => {
                                setMessage(false)
                                onCloseAlert()
                            }}>
                                Annuler
                            </Button>
                            <Button onClick={() => handleDelete(deleteId)} colorScheme='red' ml={3} isDisabled={message}>
                                Supprimer
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
            <AlertDialog

                size={"lg"}
                closeOnOverlayClick={false}
                isOpen={isModalOpen}
                leastDestructiveRef={cancelRef}
                onClose={onCloseModal}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            Modifier le rôle de {fullName}
                        </AlertDialogHeader>

                        <AlertDialogBody >

                            {getModulebyRespo(deleteId).oneRespo.length > 0 ?
                                <Alert maxH={200} overflow='auto' status='error' alignItems="start" >
                                    <AlertIcon />
                                    <AlertDescription><AlertTitle>Erreur !</AlertTitle>La modification du rôle de ce responsable est impossible car il est en charge {getModulebyRespo(deleteId).formattedOutput}.<br /> Pour procéder à cette modification, un autre responsable doit être affecté à ces modules.</AlertDescription>
                                </Alert>
                                // <Text fontWeight="bold">Impossible de modifier le rôle de ce responsable car il gère {getModulebyRespo(deleteId).formattedOutput} tout seul il faut ajouter un autre responsable à {getModulebyRespo(deleteId).oneRespo.length > 1 ? 'ces modules' : 'ce module'}</Text>
                                :
                                <Stack>
                                    <Text>
                                        Êtes-vous sûr de vouloir changer le rôle de cet utilisateur ? Changer le rôle mettra à jour ses privilèges et peut affecter sa capacité à effectuer certaines actions.
                                    </Text>
                                    <Select
                                        defaultValue={selectedRole} onChange={e => setSelectedRole(e.target.value)}>
                                        {(user.role === "SUPERADMIN" || user.role === "MASTER") &&
                                            <option value="MASTER">Super-administrateur</option>
                                        }
                                        <option value="RESPO">Responsable</option>
                                        <option value="ADMIN">Administrateur</option>
                                    </Select>
                                </Stack>
                            }

                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={() => {
                                setMessage('')
                                setNewRole(oldRole)
                                onCloseModal()
                            }}>
                                Annuler
                            </Button>
                            <Button onClick={handleRoleSubmit} colorScheme='green' ml={3} isDisabled={message}>
                                Modifier
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>

        </Box>

    )
}

export default GestApprenant