import React from 'react'
import { useLocation } from 'react-router'
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";


function ShowMedia() {
    const location = useLocation()
    return (
        <>
            {location.state.fileType === "ppt" &&
                <DocViewer documents={[{
                    uri:location.state.file,
                    fileType:location.state.fileType
                }]} pluginRenderers={DocViewerRenderers} style={{height:1000}}/>
            }

            {location.state.fileType === "pdf" &&
                <iframe src={location.state.file} width="100%" height={1000}></iframe>
            }

            {location.state.fileType === "Video" &&
                <iframe src={location.state.file} width="100%" height={1000}></iframe>
            }
        </>
    )
}

export default ShowMedia