import {
  SearchIcon
} from "@chakra-ui/icons";
import {
  Badge,
  Box,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  Stack,
  Switch,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useToast
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axiosClient from "../../axios-client";
import MyPagination from '../../components/MyPagination';

function ActivationModuleApprenant({ id_Module }) {

  const location = useLocation();


  const [listAcces, setListAcces] = useState([]);
  const [listApprenant, setListApprenant] = useState([]);


  useEffect(() => {

    axiosClient
      .get("/acces/getEtatAccesInModule/")
      .then((res) => {
        setListAcces(res.data.filter(e => e.module === location.state.idModule).sort().reverse());
      })

    axiosClient
      .get("/apprenants/")
      .then((res) => setListApprenant(res.data));

  }, []);


  const toast = useToast()
  const EditActivation = (id, et, re) => {
    axiosClient.patch(`/acces/${id}/`, {
      etat: !et,
      encours: false,
      refus: !re,
      firstAcces: false
    })
      .then(() => {
        setListAcces(rows => rows.map(row => {
          if (row.id === id) {
            return {
              ...row,
              etat: !et,
              encours: false,
              refus: !re
            }
          }
          return row
        }))
      })
    if (!et) {
      toast({
        description: `Le module ${location.state.titre} est activé`,
        status: "success",
        duration: 2000,
        isClosable: true,
        position: "bottom-right",
      });
    }
    else {
      toast({
        description: `Le module ${location.state.titre} est desactivé`,
        status: "warning",
        duration: 2000,
        isClosable: true,
        position: "bottom-right",
      });
    }

  };

  const [value, setValue] = useState("");
  const [search, setSearch] = useState('')

  //search method
  const filteredData = useCallback(() => {
    return listAcces.filter((row) => {
      return (
        listApprenant.some((r) => {
          if (r.id === row.apprenant) {
            const fullName = `${r.first_name} ${r.last_name}`;
            return (
              r.first_name.toLowerCase().includes(search.toLowerCase()) ||
              r.last_name.toLowerCase().includes(search.toLowerCase()) ||
              fullName.toLowerCase().includes(search.toLowerCase())
            );
          }
          return false;
        })
      );
    });
  }, [listAcces, listApprenant, search]);

  //pagination

  const [currentPage, setCurrentPage] = useState(0);

  // Pagination variables
  ////////////////////////////////the size of the table//////////////////////////////
  const PAGE_SIZE = 10;
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const handlePageSizeChange = (event) => {
    const newSize = parseInt(event.target.value, 10);
    setPageSize(newSize);
    setCurrentPage(0);
  };
  //////////////////////////////////////////////////////////////////////////////////

  return (
    <Stack mt="5px">
      <Stack
        w={"full"}
        maxW="full"
        bg={useColorModeValue("white", "gray.700")}
        rounded={"lg"}
        p={6}
        my={5}
      >
        <Flex mb={3} justifyContent="space-between">
          <InputGroup>
            <Select
              w={{ base: '50%', lg: "15%" }}
              name="value"
              value={value}
              size="sm"
              onChange={(e) => { setValue(e.target.value) }}
            >
              <option value="">Tout</option>
              <option value="true">Activé</option>
              <option value="false">Désactivé</option>
            </Select>
          </InputGroup>
          <InputGroup w={{ base: '70%', lg: "30%" }}>
            <InputLeftElement
              pointerEvents="none"
              children={<SearchIcon color="gray.300" />}
            />
            <Input
              type="tel"
              placeholder="Recherche..."
              onChange={(e) => setSearch(e.target.value)}
            />
          </InputGroup>
        </Flex>
        <TableContainer>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Nom</Th>
                <Th>Prénom</Th>
                <Th>Module</Th>
                <Th>Etat</Th>
              </Tr>
            </Thead>
            <Tbody>
              {filteredData()
                .slice(
                  currentPage * pageSize,
                  currentPage * pageSize + pageSize
                )
                .filter((item) => {
                  return value === ""
                    ? item
                    : item.etat.toString().toLowerCase().includes(value);
                })
                .map((val, key) => {
                  return (
                    <Tr key={key}>
                      <Td>
                        {listApprenant.map((v, k) => {
                          if (v.id === val.apprenant) {
                            return v.last_name;
                          }
                        })}
                      </Td>
                      <Td>
                        {listApprenant.map((v, k) => {
                          if (v.id === val.apprenant) {
                            return v.first_name;
                          }
                        })}
                      </Td>
                      <Td>{location.state.titre}</Td>
                      <Td>
                        <Switch
                          size="md"
                          colorScheme="green"
                          isChecked={val.etat}
                          onChange={() =>
                            EditActivation(
                              val.id,
                              val.etat,
                              val.refus
                            )
                          }
                        />
                        <Badge
                          rounded="lg"
                          colorScheme={val.etat ? "green" : "red"}
                        >
                          {val.etat ? "Activé" : "Desactivé"}
                        </Badge>
                      </Td>
                    </Tr>
                  );
                })}
              {filteredData().length === 0 && (
                <Text>Aucune ligne correspondante n'a été trouvée.</Text>

              )}
            </Tbody>
          </Table>
        </TableContainer>

        <Flex justify="space-between" align="center" w="100%">
          <Box flex="1">
            <MyPagination
              data={filteredData()}
              searchInput={search}
              PAGE_SIZE={pageSize}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </Box>

          <Select w="70px" value={pageSize} onChange={handlePageSizeChange}>
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="30">30</option>
            <option value="40">40</option>
            {/* Add more options as needed */}
          </Select>
        </Flex>
      </Stack>
    </Stack>
  );
}

export default ActivationModuleApprenant;
