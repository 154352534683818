import {
  Text,
  Box,
  Thead,
  Table,
  Flex,
  Tbody,
  Spacer,
  Tr,
  Th,
  Td,
  TableContainer,
  Heading,
  HStack,
  Center,
  Button,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Stack,
  useColorModeValue,
} from "@chakra-ui/react";

import { CheckIcon, CloseIcon, Search2Icon } from "@chakra-ui/icons";
import { useEffect, useState } from "react";
import axiosClient from "../../axios-client";
import { format } from "date-fns";
import PieChart from "./chart/StatisqueAdmission";
import Users from "./chart/ReussiteParTentative";
import Moyenne from "./chart/NoteMoyenneParModule";
import MyPagination from "../../components/MyPagination";

function Resultat() {
  const [globalResult, setGlobalResult] = useState([]);
  const [resultats, setResultats] = useState([]);
  const [tentative, setTentative] = useState("tous");
  const [admis, setAdmis] = useState("tous");
  const [date1, setDate1] = useState(
    format(new Date(2000, 11, 27), "yyyy-MM-dd")
  );
  const [date2, setDate2] = useState(format(new Date(), "yyyy-MM-dd"));
  const [searchValue, setSearchValue] = useState("");

  const mode = useColorModeValue("black", "white")
  const statColor = useColorModeValue("white", "Gray")


  //pagination
  const [currentPage, setCurrentPage] = useState(0);
  ////////////////////////////////the size of the table//////////////////////////////
  const PAGE_SIZE = 10;
  const [pageSize, setPageSize] = useState(PAGE_SIZE);

  useEffect(() => {
    axiosClient
      .get("/resultat/")
      .then(({ data }) => {
        data.map((dat, index) => {
          //convertion de date en format "yyyy-mm-dd"
          const date = new Date(dat.date_de_passage);
          date.setDate(date.getDate());
          const formattedDate = date.toISOString().slice(0, 10);
          data[index].date_de_passage = formattedDate;
        });
        const sortedData = data.sort().reverse();
        setGlobalResult(sortedData);
        setResultats(sortedData);
        // console.log("la date daujourdhui", date2);
      })
      .catch((error) => {
        // console.log(error);
      });
  }, []);

  useEffect(() => {
    filtrage_search();
  }, [admis, tentative, date1, date2, searchValue]);

  // convertion du format de date
  function dateFormat(dateString) {
    const dateObj = new Date(dateString);
    const day = dateObj.getDate().toString().padStart(2, "0");
    const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
    const year = dateObj.getFullYear().toString();
    const formattedDate = `${day}/${month}/${year}`;
    // Output: "31/03/2023"
    return formattedDate;
  }

  function dateFront(dateF) {
    const [year, month, day] = dateF.split("-");
    return `${day}/${month}/${year}`;
  }

  //Filtrer les résultats en fonction de la propriété "valider"
  const handleAdmission = (valider) => {
    if (valider === "tous") {
      setAdmis(valider);
    } else {
      const check = valider === "true" ? true : false;
      setAdmis(check);
    }
  };

  //Filtrer les résultats en fonction de la propriété "tentative"
  const handleTentative = (variable) => {
    if (variable === "tous") {
      setTentative(variable);
    } else {
      variable = parseInt(variable);
      setTentative(variable);
    }
  };

  //handle pagination
  const handlePageSizeChange = (event) => {
    const newSize = parseInt(event.target.value, 10);
    setPageSize(newSize);
    setCurrentPage(0);
  };

  //filtre date
  const handleDate1 = (dateString) => {
    setDate1(dateString);
  };

  const handleDate2 = (dateString) => {
    setDate2(dateString);
  };

  //search state
  const handleSearchInputChange = (event) => {
    setSearchValue(event.target.value);
  };

  const filtrage_search = (event) => {
    if (event) event.preventDefault();

    // search
    const lowerCaseSearchString = searchValue.toLowerCase();
    const searchedResult = globalResult.filter(
      (result) =>
        result.formation.toLowerCase().includes(lowerCaseSearchString) ||
        result.module.toLowerCase().includes(lowerCaseSearchString) ||
        result.username.toLowerCase().includes(lowerCaseSearchString)
    );

    // filtre
    const filteredResultats = searchedResult.filter(
      (resultat) =>
        (tentative === "tous" ? true : resultat.tentative === tentative) &&
        (admis === "tous" ? true : resultat.valider === admis) &&
        resultat.date_de_passage >= date1 &&
        resultat.date_de_passage <= date2
    );
    setResultats(filteredResultats);
  };

  return (
    <Box w="100%" p={4} borderRadius="xl" color={mode}>
      <Flex
        bg={statColor}
        rounded={"lg"}
        flexWrap={{ base: "wrap", sm: "wrap", md: "nowrap" }}
        justifyContent={{ base: "flex-start", sm: "flex-start", md: "center" }}
        alignItems={"center"}
        p={{ base: 2, sm: 2, md: 0, lg: 5 }}
        direction={{ base: "column", md: "row", lg: "row" }}
      >

        <Box m={5} w={{ base: "100%", sm: "80%", md: "30%" }} >
          <center>
            <PieChart />
          </center>
        </Box>

        <Box w={{ base: "100%", sm: "80%", md: "30%" }}>
          <center>
            <Users />
          </center>
        </Box>

        <Box w={{ base: "100%", sm: "80%", md: "30%" }}>
          <center>
            <Moyenne />
          </center>
        </Box>
      </Flex>
      <br />
      <br />

      <Heading
        textAlign={"left"}
        fontSize={{ base: "2xl", sm: "3xl" }}
        fontWeight="bold"
        bgGradient="linear(to-l, #ffd140, #089bd7)"
        bgClip="text"
      >
        Liste des résultats
      </Heading>
      <br />
      <Stack
        w={"full"}
        maxW="full"
        bg={useColorModeValue("white", "gray.700")}
        rounded={"lg"}
        p={6}
        my={5}
      >
        <Flex
          justifyContent="space-between"
          alignItems="center"
          direction={{ base: "column", lg: "row" }}
          w={"100%"}
        >
          <form>
            <InputGroup
              w={{ base: "100%", lg: "100%" }}
              mt={{ base: 0, lg: 7 }}
              mb={{ base: 3, lg: 0 }}
            >
              <Input
                w={{ base: "100%", lg: "100%" }}
                type="search"
                color={useColorModeValue("black", "gray.300")}
                value={searchValue}
                onChange={handleSearchInputChange}
                pr="4.5rem"
                placeholder="Recherche"
              />
              <InputRightElement width="4.5rem">
                <Button
                  bgColor={"transparent"}
                  h="1.75rem"
                  size="sm"
                  onClick={filtrage_search}
                >
                  <Search2Icon color={useColorModeValue("black", "gray.300")} />
                </Button>
              </InputRightElement>
            </InputGroup>
          </form>
          <Spacer />

          <Flex
            justifyContent="space-between"
            alignItems="center"
            direction={{ base: "column", lg: "row" }}
            w={{ base: "100%", lg: "55%" }}
            textAlign={"center"}
            fontSize={{ base: 9, md: 13 }}
            flex={25}
          >
            <Spacer />
            <Box w={"100%"} mr={{ base: 0, lg: 3 }}>
              <Text
                fontWeight={"bold"}
                mb={3}
                color={useColorModeValue("black", "gray.300")}
              >
                Admission:
              </Text>
              <Select
                mb={{ base: 3, lg: 0 }}
                color={useColorModeValue("black", "gray.300")}
                onChange={(e) => handleAdmission(e.target.value)}
              >
                <option value="tous">Toutes</option>
                <option value="true">Admis</option>
                <option value="false">Non admis</option>
              </Select>
            </Box>
            <Spacer />
            <Box w={"100%"} mr={{ base: 0, lg: 3 }}>
              <Text
                fontWeight={"bold"}
                mb={3}
                color={useColorModeValue("black", "gray.300")}
              >
                Tentative:
              </Text>
              <Select
                mb={{ base: 3, lg: 0 }}
                color={useColorModeValue("black", "gray.300")}
                onChange={(e) => handleTentative(e.target.value)}
              >
                <option value="tous">Toutes</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
              </Select>
            </Box>
            <Spacer />
            <Box w={"100%"} mr={{ base: 0, lg: 3 }}>
              <Text
                fontWeight={"bold"}
                mb={3}
                color={useColorModeValue("black", "gray.300")}
              >
                Aprés le:
              </Text>
              <form onChange={(e) => handleDate1(e.target.value)}>
                <Input
                  mb={{ base: 3, lg: 0 }}
                  color={useColorModeValue("black", "gray.300")}
                  type="date"
                />
              </form>
            </Box>
            <Spacer />
            <Box w={"100%"} mr={{ base: 0, lg: 3 }}>
              <Text
                fontWeight={"bold"}
                mb={3}
                color={useColorModeValue("black", "gray.300")}
              >
                Avant le:
              </Text>
              <form onChange={(e) => handleDate2(e.target.value)}>
                <Input
                  color={useColorModeValue("black", "gray.300")}
                  type="date"
                />
              </form>
            </Box>
          </Flex>
        </Flex>
        <br />

        <TableContainer mt={15} >
          <Table variant="striped">
            <Thead>
              <Tr>
                <Th >Formation</Th>
                <Th>Module</Th>
                <Th>Email</Th>
                <Th>Niveau du test</Th>
                <Th>Tentative</Th>
                <Th>Score</Th>
                <Th>Date de passage</Th>
                <Th>Admis</Th>
              </Tr>
            </Thead>

            <Tbody>
              {resultats
                .slice(
                  currentPage * pageSize,
                  currentPage * pageSize + pageSize
                )
                .map((result, index) => (
                  <Tr key={index}>
                    <Td fontWeight={"bold"}>{result.formation}</Td>
                    <Td fontWeight={"bold"}>{result.module}</Td>
                    <Td fontWeight={"bold"}>{result.username}</Td>
                    <Td fontWeight={"bold"}>{result.niveau}</Td>
                    <Td fontWeight={"bold"}>{result.tentative}</Td>
                    {result.valider ? (
                      <Td size={13} color={"#1EB14C"} fontWeight="bold">
                        {result.resultat}%
                      </Td>
                    ) : (
                      <Td size={13} color={"red"} fontWeight="bold">
                        {result.resultat}%
                      </Td>
                    )}
                    <Td>{dateFront(result.date_de_passage)}</Td>
                    {result.valider ? (
                      <Td color={"#1EB14C"}>
                        {" "}
                        <button>
                          <CheckIcon />
                        </button>{" "}
                      </Td>
                    ) : (
                      <Td color={"red"}>
                        {" "}
                        <button>
                          <CloseIcon />
                        </button>{" "}
                      </Td>
                    )}
                  </Tr>
                ))}

              {resultats.length === 0 && (
                <Tr>
                  <Td colSpan={7}>
                    Aucune ligne correspondante n'a été trouvée.
                  </Td>
                </Tr>
              )}
            </Tbody>
          </Table>
        </TableContainer>
        <Flex justify="space-between" align="center" w="100%">
          <Box flex="1">
            <MyPagination
              data={resultats}
              // searchInput={search}
              PAGE_SIZE={pageSize}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </Box>
          <Select w="70px" value={pageSize} onChange={handlePageSizeChange}>
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="30">30</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="150">150</option>
            {/* Add more options as needed */}
          </Select>
        </Flex>
      </Stack>
    </Box>
  );
}

export default Resultat;
