import { useState, useEffect, useCallback } from 'react';
import axiosClient from '../../axios-client';
import { useStateContext } from '../../context/ContextProvider';
import MyPagination from "../../components/MyPagination";

import {
    Flex,
    Box,
    Button,
    Stack,
    Input,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    useColorModeValue,
    useToast,
    Spinner,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    InputRightElement,
    Icon,
    InputGroup,
    Text,
    Badge,
    Textarea,
    Select
} from '@chakra-ui/react';
import { FaSearch } from "react-icons/fa";

export default function RegisterDemande() {


    // State variables
    const toast = useToast()
    const [data, setData] = useState([]);
    const { user, isLoading, setIsLoading } = useStateContext()
    const [id, setId] = useState(null);
    const [selectedEtat, setSelectedEtat] = useState("3");

    // Approuver demande Modal Overlay
    const OverlayApprouver = () => (
        <ModalOverlay
            bg='none'
            backdropFilter='auto'
            backdropInvert='80%'
            backdropBlur='2px'
        />
    )

    // Refuser demande Modal Overlay
    const OverlayRefuser = () => (
        <ModalOverlay
            bg='none'
            backdropFilter='auto'
            backdropInvert='80%'
            backdropBlur='2px'
        />
    )

    // Refuser une demande d'inscription
    let [messageRefus, setMessageRefus] = useState('')

    let handleInputChange = (e) => {
        let inputValue = e.target.value
        setMessageRefus(inputValue)
    }

    const handleRefuser = (id) => {
        setIsLoading(true)
        axiosClient.get(`/refuse_demande/${id}/${user.id}`, {
            params: {
                justification: messageRefus
            }
        })
            .then(() => {
                toast({
                    description: "La demande a été refusée avec succés",
                    status: 'warning',
                    duration: 5000,
                    isClosable: true,
                    position: 'bottom-right'
                })
                setIsLoading(false);
                onCloseModalB()
                fetchDemandes();
            })
            .catch((error) => {
                console.log(error);
                setIsLoading(false);
            });

    }

    // Approuver une demande d'inscription
    const handleApprouver = (id) => {
        setIsLoading(true);
        axiosClient.get(`/approve_demande/${id}/${user.id}`)
            .then((response) => {
              
                    toast({
                        description: response.data.message,
                        status: 'success',
                        duration: 5000,
                        isClosable: true,
                        position: 'bottom-right'
                    })
                
                setIsLoading(false);
                onCloseModalA()
                fetchDemandes();
            })
            .catch((error) => {
                console.log(error);
                setIsLoading(false);
            });

    }

    // afficher la liste des demandes
    const fetchDemandes = useCallback(() => {
        setIsLoading(true);
        axiosClient.get('/demandes/')
            .then((response) => {
                setData(response.data);
                setIsLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setIsLoading(false);
            });
    }, [setData]);

    useEffect(() => {
        fetchDemandes();
    }, [fetchDemandes]);


    // Récuperer l'ID de la demande
    const handleModalEtat = async (id) => {
        setId(id);
    };


    // Pagination variables
    const [currentPage, setCurrentPage] = useState(0);

    // Modals variables and methods
    const [isModalAOpen, setIsModalAOpen] = useState(false);
    const [isModalBOpen, setIsModalBOpen] = useState(false);

    ////////////////////////////////the size of the table//////////////////////////////
    const PAGE_SIZE = 10;
    const [pageSize, setPageSize] = useState(PAGE_SIZE);
    const handlePageSizeChange = (event) => {
        const newSize = parseInt(event.target.value, 10);
        setPageSize(newSize);
        setCurrentPage(0);
    };
    //////////////////////////////////////////////////////////////////////////////////

    const onOpenModalA = () => {
        setIsModalAOpen(true);
    };

    const onOpenModalB = () => {
        setIsModalBOpen(true);
    };

    const onCloseModalA = () => {
        setIsModalAOpen(false);
    };

    const onCloseModalB = () => {
        setIsModalBOpen(false);
    };

    const [overlayRefuser, setOverlayRefuser] = useState(<OverlayRefuser />)
    const [overlay, setOverlay] = useState(<OverlayApprouver />)

    //search variable
    const [searchTerm, setSearchTerm] = useState('')
    //search method
    const filteredData = useCallback(() => {
        const parsedSelectedEtat = parseInt(selectedEtat);

        return data.filter(row => {

            if (parsedSelectedEtat === 1) {
                return (row.etat === false && row.isApproved === true && (row.last_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    row.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    row.email.toLowerCase().includes(searchTerm.toLowerCase())));
            }
            if (parsedSelectedEtat === 2) {
                return (row.etat === false && row.isApproved === false && (row.last_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    row.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    row.email.toLowerCase().includes(searchTerm.toLowerCase())));
            }
            if (parsedSelectedEtat === 3) {
                return (row.etat === true && row.isApproved === false && (row.last_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    row.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    row.email.toLowerCase().includes(searchTerm.toLowerCase())));
            }

            return (
                row.last_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                row.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                row.email.toLowerCase().includes(searchTerm.toLowerCase())
            );
        });
    }, [data, searchTerm, selectedEtat]);


    // format date
    function format(d) {
        const formattedDate = new Date(d).toLocaleDateString('en-US', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        });

        return formattedDate
    }

    function myFilter() {
        const number = 0
        if (parseInt(selectedEtat) === 1) {
            const myData = filteredData().filter(row => (row.etat === false && row.isApproved === true))
            return (myData)
        }
        if (parseInt(selectedEtat) === 2) {
            const myData = filteredData().filter(row => (row.etat === false && row.isApproved === false))
            return (myData)
        }
        if (parseInt(selectedEtat) === 3) {
            const myData = filteredData().filter(row => (row.etat === true && row.isApproved === false))
            return (myData)
        }
        return filteredData()
    }

    console.log(myFilter());

    return (
        <Box mt="5px" >
            <Flex
                align={'center'}
                justify={'center'}
            >
                <Stack
                    w={'full'}
                    maxW='full'
                    bg={useColorModeValue('white', 'gray.700')}
                    rounded={'lg'}
                    p={6}
                    my={5}>
                    {/* Start Search input */}
                    <Flex justifyContent="right">
                        <Select fontSize={{ base: 13, lg: 16 }} w={{ base: '30%', lg: "12%" }} mr={5} mb={4} value={selectedEtat} onChange={(e) => setSelectedEtat(e.target.value)}>
                            <option value="0">Tout</option>
                            <option value="1">Approuvé</option>
                            <option value="2">Non Approuvé</option>
                            <option value="3">En cours</option>
                        </Select>
                        <InputGroup w={{ base: '70%', lg: "30%" }} >
                            <InputRightElement
                                pointerEvents="none"
                                children={<Icon as={FaSearch} color="gray.300" />}
                            />
                            <Input
                                placeholder="Recherche..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                mb={4}
                                mr="auto"
                                sx={{
                                    marginRight: "auto",
                                }}
                            />
                        </InputGroup>
                    </Flex>
                    {/* End Search input */}
                    <TableContainer>
                        <Table variant='simple'>
                            <Thead>
                                <Tr>
                                    <Th>Prénom</Th>
                                    <Th>Nom</Th>
                                    <Th>Email</Th>
                                    <Th>Etat</Th>
                                    <Th>Par</Th>
                                    <Th>Dérnière modification</Th>
                                    <Th>Options</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {filteredData()

                                    .slice(currentPage * pageSize, currentPage * pageSize + pageSize).map((demande) => (
                                        <Tr key={demande.id}>
                                            <Td>{demande.first_name}</Td>
                                            <Td>{demande.last_name}</Td>
                                            <Td>{demande.email}</Td>



                                            {(!demande.etat || demande.isApproved) ?
                                                <Td>
                                                    <Badge pr="1.5" pl="1.5" colorScheme={demande.isApproved ? 'green' : 'blue'} rounded={"lg"}>{demande.isApproved ? 'Approuvée' : 'Non approuvée'}</Badge></Td>
                                                : <Td>
                                                    <Badge pr="1.5" pl="1.5" colorScheme={'yellow'} rounded={"lg"}>En cours</Badge>
                                                </Td>
                                            }

                                            <Td>{demande.approved_by || demande.refused_by}</Td>
                                            <Td>{format(demande.date_modification)}</Td>


                                            {(!demande.etat && !demande.isApproved) ? (
                                                <Td>
                                                    <Button

                                                        size="sm"
                                                        colorScheme={'blue'}
                                                        onClick={() => {
                                                            handleModalEtat(demande.id);
                                                            setOverlay(<OverlayApprouver />);
                                                            onOpenModalA();
                                                        }}
                                                    >
                                                        Réapprouver
                                                    </Button>
                                                </Td>
                                            ) : null}



                                            {demande.etat ?
                                                <Td> <Button

                                                    size="sm"
                                                    isDisabled={demande.etat ? false : true}
                                                    colorScheme={demande.etat ? 'yellow' : 'green'}
                                                    onClick={() => {
                                                        handleModalEtat(demande.id)
                                                        setOverlay(<OverlayApprouver />)
                                                        onOpenModalA()
                                                    }}
                                                >
                                                    Approuver
                                                </Button>

                                                    <Button
                                                        ml='4'
                                                        size="sm"
                                                        isDisabled={demande.etat ? false : true}
                                                        colorScheme={demande.etat ? 'red' : 'green'}
                                                        onClick={() => {
                                                            handleModalEtat(demande.id)
                                                            setOverlayRefuser(<OverlayRefuser />)
                                                            onOpenModalB()
                                                        }}
                                                    >
                                                        Refuser
                                                    </Button>

                                                </Td> : ""}

                                        </Tr>
                                    ))}
                                {filteredData().length === 0 && (
                                    <Tr>
                                        <Td colSpan={6}>Aucune ligne correspondante n'a été trouvée.</Td>
                                    </Tr>
                                )}
                            </Tbody>
                        </Table>
                        {/* Start Pagination Component */}


                        <Flex justify="space-between" align="center" w="100%">
                            <Box flex="1">
                                <MyPagination
                                    data={filteredData()}
                                    searchInput={searchTerm}
                                    PAGE_SIZE={pageSize}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                />
                            </Box>

                            <Select w="70px" value={pageSize} onChange={handlePageSizeChange}>
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="30">30</option>
                                <option value="40">40</option>
                                {/* Add more options as needed */}
                            </Select>
                        </Flex>
                        {/* End Pagination Modal Component */}
                    </TableContainer>
                </Stack>
            </Flex>

            {/* Start Approuver Modal Component */}
            <Modal isCentered isOpen={isModalAOpen} onClose={onCloseModalA}>
                {overlay}
                <ModalContent>
                    <ModalHeader>Approuver une demande d'inscription</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Text>Voulez-vous vraiment approuver cette demande ?</Text>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            isLoading={isLoading}
                            size="sm"
                            mr={3}
                            colorScheme='blue'
                            onClick={() => handleApprouver(id)}
                        >
                            {isLoading ? <Spinner size="sm" /> : "Approuver"}
                        </Button>
                        <Button colorScheme='red' size="sm" onClick={onCloseModalA}>Fermer</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            {/* End Approuver Modal Component */}

            {/* Start Refuser Modal Component */}
            <Modal isCentered isOpen={isModalBOpen} onClose={onCloseModalB}>
                {overlayRefuser}
                <ModalContent>
                    <ModalHeader>Refuser une demande d'inscription</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Text>Voulez-vous vraiment refuser cette demande ? </Text>
                        <Textarea value={messageRefus}
                            onChange={handleInputChange}
                            mt="2" placeholder='Laissez un message de justification de refus'>

                        </Textarea>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            isLoading={isLoading}
                            size="sm"
                            mr={3}
                            colorScheme='blue'
                            onClick={() => handleRefuser(id)}
                        >
                            {isLoading ? <Spinner size="sm" /> : "Refuser"}
                        </Button>
                        <Button colorScheme='red' size="sm" onClick={onCloseModalB}>Fermer</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            {/* End Refuser Modal Component */}

        </Box>
    )
}