import {
  AddIcon,
  DeleteIcon,
  EditIcon,
  SearchIcon
} from "@chakra-ui/icons";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Avatar,
  Badge,
  Box,
  Button,
  Flex,
  Heading,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Select,
  Stack,
  Switch,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  WrapItem,
  useColorModeValue,
  useDisclosure,
  useToast
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import { CiMenuKebab } from "react-icons/ci";
import { MdHistory } from "react-icons/md";
import { GoFileSubmodule } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import axiosClient from "../../axios-client";
import MyPagination from "../../components/MyPagination";
import { useStateContext } from "../../context/ContextProvider";

function TableFormation() {
  // current user
  const { user, setUser } = useStateContext();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [deleteId, setDeleteId] = useState(null);
  const cancelRef = React.useRef();


  const toast = useToast();

  const [listFormation, setListFormation] = useState([]);

  const [titre, setTitre] = useState("");
  const [etat, setEtat] = useState(false);

  useEffect(() => {
    axiosClient.get("auth/user/").then((res) => {
      setUser(res.data);
    });

    axiosClient
      .get(`/formation/`)
      .then((res) => setListFormation(res.data.sort().reverse()));
  }, []);


  const DeleteFormation = (id, title) => {
    axiosClient.delete(`/formation/${id}/`).then(() => {
      setListFormation((prevData) => prevData.filter((row) => row.id !== id));
      toast({
        title: "Suppression",
        description: `La formation ${title} est suppprimée avec succès`,
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    });
    onClose();
  };

  const date = new Date();
  date.setDate(date.getDate());

  const [edited_by, setedited_by] = useState(`${user.first_name} ${user.last_name}`)
  const [edited_at, setedited_at] = useState(date)
  const [etatJson, setetatJson] = useState(false)

  const editEtat = (id, etat, myModification) => {


    //modification log
    const mod = myModification

    mod.push({
      "edited_by": edited_by,
      "edited_at": edited_at,
      "field": {
        "etat": !etat,
        "titre": null,
        "description": null,
        "image": false
      }
    })


    const formData = new FormData();
    formData.append("etat", !etat);
    formData.append('modification', JSON.stringify(mod))
    axiosClient.patch(`/formation/${id}/`, formData).then((res) => {
      setListFormation((rows) =>
        rows.map((row) => {
          if (row.id === id) {
            return {
              ...row,
              etat: !etat,
            };
          }
          return row;
        })
      );
    });
    if (!etat) {
      toast({
        description: `La formation est activée`,
        status: "success",
        duration: 2000,
        isClosable: true,
        position: "bottom-right",
      });
    } else {
      toast({
        description: `La formation est desactivée`,
        status: "warning",
        duration: 2000,
        isClosable: true,
        position: "bottom-right",
      });
    }
  };

  const navigate = useNavigate();

  function openModuleWithIdFormation(idF, titre) {
    navigate("/list_allModules", {
      state: {
        idFormation: idF,
        titreF: titre,
      },
    });
  }

  const editFormation = (idF, titre, image, description) => {
    navigate("/modifier_formation", {
      state: {
        idFormation: idF,
        titre: titre,
        image: image,
        description: description,
      },
    });
  };

  const [selectedEtat, setSelectedEtat] = useState(null);

  const [search, setSearch] = useState("");

  //search method
  const filteredData = useCallback(() => {
    return listFormation.filter((row) => {
      return (
        row.titre.toLowerCase().includes(search.toLowerCase()) ||
        row.description.toLowerCase().includes(search.toLowerCase())
      );
    });
  }, [listFormation, search]);

  const [currentPage, setCurrentPage] = useState(0);

  // Pagination variables
  ////////////////////////////////the size of the table//////////////////////////////
  const PAGE_SIZE = 10;
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const handlePageSizeChange = (event) => {
    const newSize = parseInt(event.target.value, 10);
    setPageSize(newSize);
    setCurrentPage(0);
  };
  //////////////////////////////////////////////////////////////////////////////////

  return (
    <Box mt="5px">
      <Box mb={5} w="90%">
        <Heading
          bgGradient='linear(to-l,  #ffd140, #089bd7)'
          bgClip="text"
          fontSize={{ base: "2xl", sm: "3xl" }}
        >
          Liste des formations
        </Heading>
      </Box>
      <Flex justifyContent="end" alignItems="center">
        <Button
          colorScheme="blue"
          size="md"
          onClick={() => navigate("/nouvelle_formation")}
          leftIcon={<AddIcon />}
        >
          Ajouter une formation
        </Button>
      </Flex>
      <Stack
        w={"full"}
        maxW="full"
        bg={useColorModeValue("white", "gray.700")}
        rounded={"lg"}
        p={6}
        my={5}
      >
        <Flex justifyContent="end" alignItems="center">
          <Select fontSize={{ base: 13, lg: 16 }} w={{ base: '30%', lg: "10%" }} mr={5} value={selectedEtat} onChange={(e) => setSelectedEtat(e.target.value === '' ? null : e.target.value === 'true')}>
            <option value="">Tout</option>
            <option value="true">Activé</option>
            <option value="false">Désactivé</option>
          </Select>
          <InputGroup w={{ base: '70%', lg: "30%" }}>
            <InputLeftElement
              pointerEvents="none"
              children={<SearchIcon color="gray.300" />}
            />
            <Input
              type="tel"
              placeholder="Recherche..."
              onChange={(e) => setSearch(e.target.value)}
            />
          </InputGroup>
        </Flex>

        <TableContainer>
          <Table variant="striped">
            <Thead>
              <Tr>
                <Th>image</Th>
                <Th>titre</Th>
                <Th>Description</Th>
                <Th>Etat</Th>
                <Th>Module</Th>
                {(user.role === "SUPERADMIN" ||
                  user.role === "MASTER" ||
                  user.role === "ADMIN") && (
                    <>
                      <Th>Action</Th>
                    </>
                  )}
              </Tr>
            </Thead>
            <Tbody>
              {filteredData()
                .filter((row) => selectedEtat === null || row.etat === selectedEtat)
                .slice(
                  currentPage * pageSize,
                  currentPage * pageSize + pageSize
                )
                .map((val, key) => {
                  return (
                    <Tr key={key}>
                      <Td>
                        <WrapItem>
                          <Avatar name="Dan Abrahmov" src={val.image} />
                        </WrapItem>
                      </Td>
                      <Td>{val.titre}</Td>
                      <Td>
                        {val.description?.length > 20
                          ? `${val.description.substring(0, 40)}...`
                          : val.description}
                      </Td>
                      <Td>
                        {(user.role === "SUPERADMIN" ||
                          user.role === "MASTER" ||
                          user.role === "ADMIN") && (
                            <Switch
                              size="md"
                              colorScheme="green"
                              isChecked={val.etat}
                              onChange={() => {
                                editEtat(val.id, val.etat, val.modification);
                                setEtat(!val.etat);
                                setetatJson(!val.etat)
                              }}
                            />
                          )}

                        <Badge
                          rounded="lg"
                          colorScheme={val.etat ? "green" : "red"}
                        >
                          {val.etat ? "Activé" : "Desactivé"}
                        </Badge>
                      </Td>
                      <Td>
                        {val.etat && (
                          <Button
                            size="sm"
                            colorScheme="blue"
                            mr={10}
                            leftIcon={<GoFileSubmodule />}
                            onClick={() =>
                              openModuleWithIdFormation(val.id, val.titre)
                            }
                          >
                            Module
                          </Button>
                        )}
                        {!val.etat && user.role === "RESPO" && (
                          <Button
                            colorScheme="red"
                            size="sm"
                            mr={10}
                            leftIcon={<GoFileSubmodule />}
                            onClick={() =>
                              openModuleWithIdFormation(val.id, val.titre)
                            }
                            isDisabled
                          >
                            Module
                          </Button>
                        )}
                        {!val.etat &&
                          (user.role === "SUPERADMIN" ||
                            user.role === "MASTER" ||
                            user.role === "ADMIN") && (
                            <Button
                              size="sm"
                              colorScheme="red"
                              mr={10}
                              leftIcon={<GoFileSubmodule />}
                              onClick={() =>
                                openModuleWithIdFormation(val.id, val.titre)
                              }
                            >
                              Module
                            </Button>
                          )}
                      </Td>
                      {(user.role === "SUPERADMIN" ||
                        user.role === "MASTER") && (
                          <>
                            <Td>
                              <Menu>
                                <MenuButton
                                  as={IconButton}
                                  aria-label="Options"
                                  icon={<CiMenuKebab />}
                                  variant="outline"
                                  _hover="none"
                                />
                                <MenuList>
                                  <MenuItem
                                    icon={<EditIcon fontSize={15} />}
                                    onClick={() =>
                                      editFormation(
                                        val.id,
                                        val.titre,
                                        val.image,
                                        val.description
                                      )
                                    }
                                  >
                                    Modifier
                                  </MenuItem>
                                  <MenuItem
                                    icon={<MdHistory fontSize={15} />}
                                    onClick={() =>
                                      navigate('/historique_formation', {
                                        state: {
                                          idFormation: val.id
                                        }
                                      })
                                    }
                                  >
                                    Historique
                                  </MenuItem>
                                  <MenuItem
                                    icon={<DeleteIcon fontSize={15} />}
                                    onClick={() => {
                                      setDeleteId(val.id);
                                      setTitre(val.titre);
                                      onOpen();
                                    }}
                                  >
                                    Supprimer
                                  </MenuItem>

                                </MenuList>
                              </Menu>
                            </Td>
                          </>
                        )}
                      {user.role === "ADMIN" &&
                        <Td>
                          <IconButton
                            size="sm"
                            colorScheme="green"
                            mr={2}
                            icon={<EditIcon />}
                            onClick={() =>
                              editFormation(
                                val.id,
                                val.titre,
                                val.image,
                                val.description
                              )
                            }
                          >
                            Modifier
                          </IconButton>
                          <IconButton
                            size="sm"
                            bg="gray.300"
                            icon={<MdHistory />}
                            onClick={() =>
                              navigate('/historique_formation', {
                                state: {
                                  idFormation: val.id
                                }
                              })
                            }
                          >
                            Historique
                          </IconButton>
                        </Td>
                      }
                    </Tr>
                  );
                })}
              {filteredData().length === 0 && (
                <Tr>
                  <Td colSpan={6}>
                    Aucune ligne correspondante n'a été trouvée.
                  </Td>
                </Tr>
              )}
            </Tbody>
          </Table>
        </TableContainer>
        <Flex justify="space-between" align="center" w="100%">
          <Box flex="1">
            <MyPagination
              data={filteredData()}
              searchInput={search}
              PAGE_SIZE={pageSize}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </Box>

          <Select w="70px" value={pageSize} onChange={handlePageSizeChange}>
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="30">30</option>
            <option value="40">40</option>
            {/* Add more options as needed */}
          </Select>
        </Flex>
      </Stack>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Supprimer formation
            </AlertDialogHeader>

            <AlertDialogBody>
              êtes-vous sûr ? Vous ne pourrez pas annuler cette action
              ultérieurement.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Annuler
              </Button>
              <Button
                onClick={() => DeleteFormation(deleteId, titre)}
                colorScheme="red"
                ml={3}
              >
                Supprimer
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
}

export default TableFormation;
