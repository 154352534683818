import Ajouter from "./Ajouterletext.jsx";
import { useStateContext } from "../../context/ContextProvider";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axiosClient from "../../axios-client.js";
import {
  DeleteIcon,
  EditIcon,
} from "@chakra-ui/icons";
import { CiMenuKebab } from "react-icons/ci";
import {
  Menu,
  IconButton,
  MenuButton,
  MenuList,
  Heading,
  MenuItem,
  Stack,
  Modal,
  useColorModeValue,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalCloseButton,
  Flex,
  ModalBody,
  Box,
  Text,
  Input,
  FormControl,
  FormLabel,
  Select,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Tabs,
  TabList,
  Tab,
  TabPanel,
  TabPanels,
  Switch
} from "@chakra-ui/react";
function findFirstMissingTestDifficulty(tests) {
  const difficulties = ["facile", "moyen", "difficile"];
  for (const difficulty of difficulties) {
    if (!tests.some((test) => test.difficulter === difficulty)) {
      return difficulties.indexOf(difficulty);
    }
  }
  return -1; // If all difficulty levels have tests, return -1 as an indicator.
}
const ChapitreList = () => {
  const { fetchDataStat, getModule,user, setUser } = useStateContext();
  const [gettest, setgettest] = useState([]);
  const location = useLocation();
  const [getChapitre, setgetChapitre] = useState([]);
  const bgColor = useColorModeValue("white", "gray.700");
  const bgclr = useColorModeValue("gray.50", "gray.600");
  const propbg = useColorModeValue("gray.300", "gray.700");
  const textColor = useColorModeValue("black", "white");

  useEffect(() => {
    axiosClient.get("auth/user/").then(({ data }) => {
      setUser(data);
    });
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosClient.get(
          `/chapitre/?search=${location.state.idModule}`
        );
        setgetChapitre(response.data);
      } catch (error) {
      }
    };

    fetchData();
  }, [location.state.idModule]);

  useEffect(() => {
    fetchDataStat();
    getModule();
    const fetchTest = async () => {
      try {
        const response = await axiosClient.get(`/test/?search=${location.state.idModule}`);
        setgettest(response.data);
      } catch (error) {
      }
    };
    fetchTest();
  }, []);
  const [deleteId, setDeleteId] = useState(null);
  const [difficulter, setdifficulter] = useState("");
  const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false);
  const handleDeleteClick = (id, difficulter) => {
    setDeleteId(id);
    setdifficulter(difficulter);
    setIsDeleteAlertOpen(true);
  };
  const handleDeleteConfirm = async (id) => {
    try {
      await axiosClient.delete(`/test/${id}/`);
      window.location.reload()
      setIsDeleteAlertOpen(false);
    } catch (error) {
    }
  };
  const handleDeleteCancel = () => {
    setIsDeleteAlertOpen(false);
  };
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [moduleData, setModuleData] = useState(null);
  const handleEditClick = async (data) => {
    try {
      const response = await axiosClient.get(`/test/${data.id}/`);
      setModuleData(response.data);
    } catch (error) {
    }
    setIsEditOpen(true);
  };
  const handleEditClose = () => {
    setIsEditOpen(false);
    setModuleData(null);
  };

  function getChapterName(chapitreId) {
    // Find the chapter object with the corresponding chapitreId
    const selectedChapter = getChapitre.find((chapitre) => chapitre.id === chapitreId);
  
    // Return the name of the chapter, if found
    return selectedChapter ? selectedChapter.name : "Unknown Chapter";
  }
  const handleEditSave = async (editedData) => {
    try {
      editedData.date_modif = new Date().toISOString();
      editedData.editelastname = user.last_name;
      editedData.editename = user.first_name;
  
      const { question } = editedData;
  
      for (let i = 0; i < question.length; i++) {
        const { typeChoix, reponses, chapitre, question: questionText, explicationdelaquestion } = question[i];
        if (!typeChoix || !reponses || reponses.length === 0 || !chapitre || !questionText || !explicationdelaquestion) {
          alert("Veuillez remplir tous les champs pour la question.");
          return;
        }
  
        // Check for duplicate responses within the same question
        const responseSet = new Set();
        for (const r of reponses) {
          if (responseSet.has(r.reponse.toLowerCase().trim())) {
            const chapterName = getChapterName(chapitre);
            alert(`Vous avez la même proposition dans la question du chapitre ${chapterName}. Veuillez saisir des propositions uniques.`);
            return;
          } else {
            responseSet.add(r.reponse.toLowerCase().trim());
          }
        }
  
        // Check for duplicate questions within the same chapter
        for (let j = 0; j < i; j++) {
          const prevQuestion = question[j];
          if (prevQuestion.chapitre === chapitre && prevQuestion.question.toLowerCase().trim() === questionText.toLowerCase().trim()) {
            const chapterName = getChapterName(chapitre);
            alert(`Vous avez la même question pour le chapitre ${chapterName}. Veuillez saisir des questions uniques pour ce chapitre.`);
            return;
          }
        }
  
        // Check for duplicate explanations within the same chapter
        for (let j = 0; j < i; j++) {
          const prevQuestion = question[j];
          if (prevQuestion.chapitre === chapitre && prevQuestion.explicationdelaquestion.toLowerCase().trim() === explicationdelaquestion.toLowerCase().trim()) {
            const chapterName = getChapterName(chapitre);
            alert(`Vous avez la même explication pour la question du chapitre ${chapterName}. Veuillez saisir des explications uniques pour ce chapitre.`);
            return;
          }
        }
  
        if (typeChoix === "unique") {
          const trueReponses = reponses.filter((r) => r.etat);
          if (trueReponses.length !== 1) {
            const chapterName = getChapterName(chapitre);
            alert(`Pour une question de type unique dans le chapitre ${chapterName}, vous devez avoir exactement 1 réponse correcte.`);
            return;
          }
        }
  
        if (typeChoix === "multiple") {
          const trueReponses = reponses.filter((r) => r.etat);
          if (trueReponses.length === reponses.length) {
            const chapterName = getChapterName(chapitre);
            alert(`Toutes les propositions de la question du chapitre ${chapterName} sont marquées comme 'bonne réponse'. Veuillez laisser au moins une proposition incorrecte.`);
            return;
          }
        }
  
        if (reponses.every((r) => !r.etat)) {
          const chapterName = getChapterName(chapitre);
          alert(`Veuillez sélectionner au moins une réponse correcte pour la question du chapitre ${chapterName}.`);
          return;
        }
  
        if (reponses.length < 2) {
          const chapterName = getChapterName(chapitre);
          alert(`La question du chapitre ${chapterName} doit comporter au moins 2 propositions. Veuillez ajouter plus de propositions.`);
          return;
        }
      }
  
      const response = await axiosClient.put(`/test/${editedData.id}/`, editedData);
      setModuleData(response.data);
      setIsEditOpen(false);
      window.location.reload();
    } catch (error) {
    }
  };
  const handleSelectChange = (questionIndex, answerIndex, value) => {
    const newModuleData = { ...moduleData };
    const question = { ...newModuleData.question[questionIndex] };
    const answer = { ...question.reponses[answerIndex] };
    answer.etat = value === "true";
    question.reponses[answerIndex] = answer;
    newModuleData.question[questionIndex] = question;
    setModuleData(newModuleData);
  };
  const addQuestion = () => {
    setModuleData({
      ...moduleData,
      nombredequestion: moduleData.nombredequestion+1,
      question: [
        ...moduleData.question,
        {

          typeChoix:"unique",
          question: "",
          explicationdelaquestion: "",
          reponses: [{ reponse: "", etat: false }],
        },
      ],
    });
  };

  const deleteQuestion = (index) => {
    setModuleData({
      ...moduleData,
      nombredequestion: moduleData.nombredequestion-1,
      question: moduleData.question.filter((_, i) => i !== index),
    });
  };
  const addAnswer = (questionIndex) => {
    setModuleData({
      ...moduleData,
      question: moduleData.question.map((q, index) => {
        if (index === questionIndex) {
          return {
            ...q,
            reponses: [...q.reponses, { reponse: "", etat: false }],
          };
        }
        return q;
      }),
    });
  };
  const deleteAnswer = (questionIndex, answerIndex) => {
    setModuleData({
      ...moduleData,
      question: moduleData.question.map((q, index) => {
        if (index === questionIndex) {
          return {
            ...q,
            reponses: q.reponses.filter((_, i) => i !== answerIndex),
          };
        }
        return q;
      }),
    });
  };
  const [hasError, setHasError] = useState(false);
  const [hasErrorExplication, setHasErrorExplication] = useState(false);
  const [hasErrorExplicationNumber, setHasErrorExplicationNumber] = useState(false);
  const [hasErrorReponse, setHasErrorReponse] = useState(false);
  const [hasErrorDescreption, setHasErrorDescreption] = useState(false);
  const [hasErrorObjectif, setHasErrorObjectif] = useState(false);
  
  const textcolor = useColorModeValue('black','black')
  const input = useColorModeValue('','gray')

  const [tempassagerror, setTempassagerror] = useState(false);

  const handleChange = (e) => {
    const value = e.target.value;
    setModuleData({
      ...moduleData,
      tempdepassage: value,
    });
    setTempassagerror(value === ''); // Set tempassagerror to true if the input is empty
  };
  const [seuilllerror, setSeuilllerror] = useState(false);

  const handleChangeSeuile = (e) => {
    const value = e.target.value;
    if (value === '' || parseInt(value, 10) === 0 || (parseInt(value, 10) >= 0 && parseInt(value, 10) <= 100)) {
      setModuleData({
        ...moduleData,
        seuil: value === '' ? '' : parseInt(value, 10),
      });
      setSeuilllerror(value === '' || parseInt(value, 10) === 0); // Set the error when input is empty or zero
    } else {
      setSeuilllerror(false); // Clear the error when input is valid
    }
  };

  
  function getRemainingDifficultyLevels(existingLevels) {
    const allLevels = ["facile", "moyen", "difficile"];
    return allLevels.filter((level) => !existingLevels.includes(level));
  }
  const defaultIndex = findFirstMissingTestDifficulty(gettest);
  const [currentTabIndex, setCurrentTabIndex] = useState(defaultIndex);

  useEffect(() => {
    // Set the initial value of currentTabIndex to defaultIndex
    setCurrentTabIndex(defaultIndex);
  }, [defaultIndex]);

  const handleTabChange = (index) => {
    setCurrentTabIndex(index);
  };
  function getDurationInSeconds(timeString) {
    const timeParts = timeString.split(":");
    if (timeParts.length !== 3) {
      return 0; // Invalid format, return 0 seconds
    }
  
    const hours = parseInt(timeParts[0], 10);
    const minutes = parseInt(timeParts[1], 10);
    const seconds = parseInt(timeParts[2], 10);
  
    if (isNaN(hours) || isNaN(minutes) || isNaN(seconds)) {
      return 0; // Invalid numeric values, return 0 seconds
    }
  
    const totalSeconds = hours * 3600 + minutes * 60 + seconds;
    return totalSeconds;
  }
  const timePattern = /^([0-3]?[0-9]|4[0]):[0-5][0-9]:[0-5][0-9]$/;
  
  return (
    <>
      <Flex mt="5px">
        <Box w="90%" mb={5}>
          <Heading
            bgGradient="linear(to-l, #ffd140, #2b6cb0)"
            bgClip="text"
            fontSize={{ base: "2xl", sm: "3xl" }}
          >
            Gestion du test - Module : {location.state.titre}
          </Heading>
        </Box>
      </Flex>


      <Tabs
        size="md"
        variant="enclosed"
        index={currentTabIndex}
        onChange={handleTabChange}
      >
        <TabList>
          <Tab
            _selected={{ color: "white", bg: "blue.500" }}
            isDisabled={gettest.some((item) => item.difficulter === "facile")}
          >
            Ajouter un test Facile
          </Tab>

          <Tab
            _selected={{ color: "white", bg: "blue.500" }}
            isDisabled={gettest.some((item) => item.difficulter === "moyen")}
          >
            Ajouter un test Moyen
          </Tab>
          <Tab
            _selected={{ color: "white", bg: "blue.500" }}
            isDisabled={gettest.some(
              (item) => item.difficulter === "difficile"
            )}
          >
            Ajouter un test Difficile
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <Ajouter niveau={"facile"} />
          </TabPanel>
          <TabPanel>
            <Ajouter niveau={"moyen"} />
          </TabPanel>
          <TabPanel>
            <Ajouter niveau={"difficile"} />
          </TabPanel>
        </TabPanels>
      </Tabs>



      <Text
        textAlign={"center"}
        fontSize="4xl"
        fontWeight="bold"
        bgGradient="linear(to-r, #2b6cb0, #ffd140)"
        bgClip="text"
      >
        Liste des test
      </Text>

      <Stack
        w={"full"}
        maxW="full"
        bg={useColorModeValue("white", "gray.700")}
        rounded={"lg"}
        p={6}
        my={5}
      >
        <TableContainer mt={15}>
          <Table variant="striped">
            <Thead>
              <Tr>
                <Th textAlign={'center'}>nombre de question</Th>
                <Th textAlign={'center'}>seuil</Th>
                <Th textAlign={'center'}>temps de passage</Th>
                <Th textAlign={'center'}>difficulte</Th>
                <Th textAlign={'center'}>Ajouter par </Th>
                <Th textAlign={'center'}>Date d'ajout</Th>
                <Th textAlign={'center'}>Date de modification</Th>
                <Th textAlign={'center'}>Modifier par</Th>
                <Th textAlign={'center'}>Action</Th>
              </Tr>
            </Thead>
            <Tbody>
              {gettest.map((data) => (
                <Tr key={data.id}>
                  <Td fontWeight={"bold"} textAlign={'center'}>{data.nombredequestion}</Td>
                  <Td fontWeight={"bold"} textAlign={'center'}>{data.seuil}</Td>
                  <Td fontWeight={"bold"} textAlign={'center'}>{data.tempdepassage}</Td>
                  <Td fontWeight={"bold"} textAlign={'center'}>{data.difficulter}</Td>
                  <Td fontWeight={"bold"} textAlign={'center'}>{data.first_name} {data.last_name}</Td>
                  <Td fontWeight="bold" textAlign="center">
                    {new Date(data.date_ajout).toLocaleString([], { dateStyle: 'short', timeStyle: 'short', hour12: false })}
                  </Td>

                  <Td fontWeight="bold" textAlign="center">
                    {data.date_modif
                      ? new Date(data.date_modif).toLocaleString([], { dateStyle: 'short', timeStyle: 'short', hour12: false })
                      : 'Pas de modification'}
                  </Td>
                  <Td fontWeight="bold" textAlign="center">
                      {data.editename || data.editelastname ? (
                        <>
                          {data.editename ? data.editename : "-"}
                          {data.editelastname ? data.editelastname : "-"}
                        </>
                      ) : (
                        "-"
                      )}
                    </Td>
                  <Td>
                    <Menu>
                      <MenuButton
                        as={IconButton}
                        aria-label="Options"
                        icon={<CiMenuKebab />}
                        variant="outline"
                        _hover="none"
                      />
                      <MenuList>
                        <MenuItem
                          icon={<EditIcon />}
                          onClick={() => handleEditClick(data)}
                        >
                          Modifier
                        </MenuItem>
                        <MenuItem
                          icon={<DeleteIcon />}
                          onClick={() =>
                            handleDeleteClick(data.id, data.difficulter)
                          }
                        >
                          Supprimer
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
          {moduleData && (
            <Modal isOpen={isEditOpen} onClose={handleEditClose} size={"full"}>
              <ModalOverlay />
              <ModalContent ml={200}>
                <ModalHeader>
                 Editer le test pour le module {moduleData.module}
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <FormLabel>
                    Nombre de question : {moduleData.nombredequestion}
                  </FormLabel>
                  <FormControl>
                  <FormLabel>Seuil</FormLabel>
                  <Input
                        type="number"
                        name="seuil"
                        value={moduleData.seuil}
                        min={0}
                        max={100}
                        onChange={handleChangeSeuile}
                      />
                      {seuilllerror && (
                        <p style={{ color: "red" }}>Le seuil ne peut pas être null ou égal à zéro.</p>
                      )}
                    <FormLabel>Description</FormLabel>
                    <Input
                      type="text"
                      name="description"
                      value={moduleData.description}
                      onChange={(e) => {
                        const value = e.target.value;
                        const trimmedValue = value.trim();
                        const hasInvalidChars = /[-+/*=!@#$%^&*(),.?":{}|<>]/.test(value);
                        const startsWithNumber = /^\d/.test(trimmedValue);
                        const startsWithSpaceAndLetter = /^\s+[A-Za-z]/.test(value);

                        setHasErrorDescreption(
                          trimmedValue === '' || hasInvalidChars || startsWithNumber || startsWithSpaceAndLetter
                        );

                        setModuleData({
                          ...moduleData,
                          description: value,
                        });
                      }}
                    />
                    {hasErrorDescreption && <span style={{ color: 'red' }}>Invalid input</span>}
                  </FormControl>
                  <FormControl>
                <FormLabel>Objectif</FormLabel>
                <Input
                  type="text"
                  name="objectif"
                  value={moduleData.objectif}
                  onChange={(e) => {
                    const value = e.target.value;
                    const trimmedValue = value.trim();
                    const hasInvalidChars = /[-+/*=!@#$%^&*(),.?":{}|<>]/.test(value);
                    const startsWithNumber = /^\d/.test(trimmedValue);
                    const startsWithSpaceAndLetter = /^\s+[A-Za-z]/.test(value);

                    setHasErrorObjectif(
                      trimmedValue === '' || hasInvalidChars || startsWithNumber || startsWithSpaceAndLetter
                    );

                    setModuleData({
                      ...moduleData,
                      objectif: value,
                    });
                  }}
                />
                {hasErrorObjectif && <span style={{ color: 'red' }}>Objectif invalid</span>}
              </FormControl>
                  <FormControl>
                        <FormLabel>Difficulté</FormLabel>
                        <Select
                          name="difficulte"
                          value={moduleData.difficulter}
                          onChange={(e) => {
                            const selectedValue = e.target.value;
                            setModuleData((prevModuleData) => ({
                              ...prevModuleData,
                              difficulter: selectedValue,
                            }));
                          }}
                          required
                        >
                          <option value="">Veuillez entrer un niveau</option>
                          {getRemainingDifficultyLevels(
                            gettest.map((test) => test.difficulter)
                          ).map((level) => (
                            <option key={level} value={level}>
                              Niveau {level}
                            </option>
                          ))}
                        </Select>
                      </FormControl>

                      <FormControl>
                  <FormLabel>Temps de passage</FormLabel>
                  <Input
                    type="text"
                    name="tempsdepassage"
                    value={moduleData.tempdepassage}
                    onChange={(e) => {
                      const value = e.target.value;
                      setModuleData({
                        ...moduleData,
                        tempdepassage: value,
                      });
                      setTempassagerror(!timePattern.test(value) || getDurationInSeconds(value) > 4 * 60 * 60);
                    }}
                  />
                  {tempassagerror && <p style={{ color: "red" }}>Le temps de passage doit être au format HH:MM:SS et ne peut pas dépasser 4 heures.</p>}
                </FormControl>
                <FormControl>
                  <FormLabel>Activer le test</FormLabel>
                  <Stack direction="row" align="center">
                    <Select
                      value={moduleData.isActife}
                      onChange={(e) => {
                        const value = e.target.value;
                        setModuleData({
                          ...moduleData,
                          isActife: value,
                        });
                      }}
                    >
                      {moduleData.isActife === "false" ? (
                        <>
                          <option value="false">Désactiver</option>
                          <option value="true">Activer</option>
                        </>
                      ) : (
                        <>
                          <option value="true">Activer</option>
                          <option value="false">Désactiver</option>
                        </>
                      )}
                    </Select>
                  </Stack>
                </FormControl>
                  {moduleData.question.map((q, questionIndex) => (
                    <div key={questionIndex} >
                      <Box
                        mt={5}
                        bg={'#f7fafc'}
                        p={4} rounded={'lg'}
                      >
                      <FormControl>
                        <FormLabel color={textcolor}>Question {questionIndex + 1}</FormLabel>
                        <Input 
                          style={{ borderColor: input }}
                          color={textcolor}
                          type="text"
                          name="question"
                          value={moduleData.question[questionIndex]?.question || ''}
                          placeholder="Veuillez entrer une question"
                          onChange={(e) => {
                            const value = e.target.value;
                            const trimmedValue = value.trim();
                      
                            // Check if there is a space before the first letter
                            // Check if the value is empty or contains only whitespace
                            // Check if the value starts with a symbol or number
                            const error =
                              value !== trimmedValue || // Check if there is a space before the first letter
                              trimmedValue === '' || // Check if the value is empty or contains only whitespace
                              /^[0-9*/+\-]/.test(trimmedValue[0]); // Check if the value starts with a symbol or number
                      
                            setHasError((prevErrors) => ({
                              ...prevErrors,
                              [questionIndex]: error,
                            }));
                      
                            setModuleData((prevModuleData) => ({
                              ...prevModuleData,
                              question: prevModuleData.question.map((q, index) =>
                                index === questionIndex ? { ...q, question: value } : q
                              ),
                            }));
                          }}
                        />
                        {hasError[questionIndex] && <p style={{ color: "red" }}>Question invalide</p>}
                        {hasError[questionIndex] && moduleData.question[questionIndex]?.question.trim() === "" && (
                          <p style={{ color: "red" }}>Ce champ ne doit pas être vide.</p>
                        )}
                      </FormControl>
                      <FormControl>
                        <FormLabel color={textcolor}>Explication de la question</FormLabel>
                        <Input
                            style={{ borderColor: input }}
                            color={textcolor}
                            type="text"
                            name="explicationdelaquestion"
                            value={q.explicationdelaquestion}
                            placeholder="Veuillez entrer une Explication"
                            onChange={(e) => {
                              const value = e.target.value;
                              const trimmedValue = value.trim();
                        
                              // Check if there is a space before the first letter
                              if (/^\s/.test(value)) {
                                setHasErrorExplication((prevErrors) => ({
                                  ...prevErrors,
                                  [questionIndex]: true,
                                }));
                                setHasErrorExplicationNumber((prevErrors) => ({
                                  ...prevErrors,
                                  [questionIndex]: false,
                                }));
                              } else {
                                setHasErrorExplication((prevErrors) => ({
                                  ...prevErrors,
                                  [questionIndex]: false,
                                }));
                        
                                // Check if the value is empty or contains only whitespace
                                if (trimmedValue === '') {
                                  setHasErrorExplication((prevErrors) => ({
                                    ...prevErrors,
                                    [questionIndex]: true,
                                  }));
                                  setHasErrorExplicationNumber((prevErrors) => ({
                                    ...prevErrors,
                                    [questionIndex]: false,
                                  }));
                                }
                                // Check if the value starts with a symbol or number
                                else if (/^[^a-zA-Z]+$/.test(trimmedValue)) {
                                  setHasErrorExplicationNumber((prevErrors) => ({
                                    ...prevErrors,
                                    [questionIndex]: true,
                                  }));
                                  setHasErrorExplication((prevErrors) => ({
                                    ...prevErrors,
                                    [questionIndex]: false,
                                  }));
                                } else {
                                  setHasErrorExplicationNumber((prevErrors) => ({
                                    ...prevErrors,
                                    [questionIndex]: false,
                                  }));
                                }
                        
                                setModuleData((prevModuleData) => ({
                                  ...prevModuleData,
                                  question: prevModuleData.question.map((q, index) =>
                                    index === questionIndex
                                      ? {
                                          ...q,
                                          explicationdelaquestion: value,
                                        }
                                      : q
                                  ),
                                }));
                              }
                            }}
                          />
                          {hasErrorExplication[questionIndex] && (
                            <p style={{ color: "red" }}>Ce champ ne doit pas commencer par un espace.</p>
                          )}
                          {hasErrorExplicationNumber[questionIndex] && (
                            <p style={{ color: "red" }}>Ce champ ne doit contenir que des nombres ou commencer par un symbole.</p>
                          )}
                        </FormControl>
                        <FormControl>
                          <FormLabel color={textcolor}>Type de la question</FormLabel>
                          <Select color={textcolor}
                          style={{ borderColor: input }}
                            name="typeChoix"
                            value={q.typeChoix}
                            onChange={(e) =>
                              setModuleData({
                                ...moduleData,
                                question: moduleData.question.map((q, index) =>
                                  index === questionIndex
                                    ? {
                                        ...q,
                                        typeChoix: e.target.value,
                                      }
                                    : q
                                ),
                              })
                            }
                          >
                            <option value="unique">
                              Question à choix unique
                            </option>
                            <option value="multiple">
                              Question à choix multiple
                            </option>
                          </Select>
                        </FormControl>
                        <FormControl>
                        <FormLabel color={textcolor}>Chapitre</FormLabel>
                        <Select
                          style={{ borderColor: input }}
                          color={textcolor}
                          value={q.chapitre}
                          onChange={(e) => {
                            const selectedChapterId = parseInt(e.target.value);
                            setModuleData((prevData) => ({
                              ...prevData,
                              question: prevData.question.map((q, index) =>
                                index === questionIndex
                                  ? { ...q, chapitre: selectedChapterId }
                                  : q
                              ),
                            }));
                          }}
                        >
                          <option value={-1}>Veuillez choisir un chapitre</option>
                          {getChapitre.map((chapitre) => (
                            <option key={chapitre.id} value={parseInt(chapitre.id)}>
                              {chapitre.name}
                            </option>
                          ))}
                        </Select>
                      </FormControl>
                        <Box
                          bg={"#cbd5e0"}
                          p={5}
                          mt={5}
                          color={"black"}
                          fontWeight={"bold"}
                          rounded={"lg"}
                        >
                        {q.reponses.map((r, answerIndex) => (
                          <div key={answerIndex}>
                            <FormLabel>Reponse {answerIndex + 1} </FormLabel>
                            <Input
                              style={{ borderColor: input }}
                              type="text"
                              name="explicationdelaquestion"
                              value={r.reponse}
                              placeholder="Veuillez entrer une proposition"
                              onChange={(e) => {
                                const value = e.target.value.trim();

                                // Check if the value is empty or contains only whitespace
                                if (value === "") {
                                  setHasErrorReponse((prevErrors) => ({
                                    ...prevErrors,
                                    [`${questionIndex}-${answerIndex}`]: true,
                                  }));
                                } else if (/^[^a-zA-Z0-9]+$/.test(value)) {
                                  // Check if the value contains only symbols
                                  setHasErrorReponse((prevErrors) => ({
                                    ...prevErrors,
                                    [`${questionIndex}-${answerIndex}`]: true,
                                  }));
                                } else {
                                  setHasErrorReponse((prevErrors) => ({
                                    ...prevErrors,
                                    [`${questionIndex}-${answerIndex}`]: false,
                                  }));
                                }

                                setModuleData((prevModuleData) => ({
                                  ...prevModuleData,
                                  question: prevModuleData.question.map((q, index) =>
                                    index === questionIndex
                                      ? {
                                          ...q,
                                          reponses: q.reponses.map((rep, repIndex) =>
                                            repIndex === answerIndex
                                              ? {
                                                  ...rep,
                                                  reponse: e.target.value,
                                                }
                                              : rep
                                          ),
                                        }
                                      : q
                                  ),
                                }));
                              }}
                            />
                            {hasErrorReponse[`${questionIndex}-${answerIndex}`] && (
                              <p style={{ color: "red" }}>Ce champ ne doit pas être vide ou contenir uniquement des symboles.</p>
                            )}
                            <FormLabel>
                              Etat de la {answerIndex + 1} Proposition
                            </FormLabel>
                            <Select
                              style={{ borderColor: input }}
                              value={r.etat ? "true" : "false"}
                              onChange={(e) =>
                                handleSelectChange(questionIndex, answerIndex, e.target.value)
                              }
                            >
                              <option value="true">Bonne reponse</option>
                              <option value="false">Mauvaise reponse</option>
                            </Select>
                            <Box mt={5} justifyContent={'space-between'} display={'flex'} w={'full'}>
                              <Button
                                colorScheme="red"
                                onClick={() => deleteAnswer(questionIndex, answerIndex)}
                              >
                                Supprimer cette proposition
                              </Button>
                              {answerIndex === q.reponses.length - 1 && (
                                <Button
                                  colorScheme="teal"
                                  onClick={() => addAnswer(questionIndex)}
                                >
                                  Ajouter une proposition
                                </Button>
                              )}
                            </Box>
                          </div>
                        ))}
                        </Box>
                        <Box mt={5} justifyContent={'space-between'} display={'flex'} w={'full'}>
                                {questionIndex > 0 && (
                                  <Button
                                    colorScheme="red"
                                    onClick={() => deleteQuestion(questionIndex)}
                                  >
                                    Supprimer cette question
                                  </Button>
                                )}
                                {questionIndex === moduleData.question.length - 1 && (
                                  <Button
                                    colorScheme="teal"
                                    onClick={() => addQuestion()}
                                  >
                                    Ajouter une question
                                  </Button>
                                )}
                              </Box>
                      </Box>
                    </div>
                  ))}
                </ModalBody>
                <ModalFooter>
                <Button
                    colorScheme="teal"
                    mr={3}
                    onClick={() => {
                      const areAnyQuestionsInvalid = Object.values(hasError).some((error) => error);
                      const areAnyExplicationsInvalid = Object.values(hasErrorExplication).some((error) => error);
                      const areAnyExplicationsNumberInvalid = Object.values(hasErrorExplicationNumber).some((error) => error);
                      const areAnyResponsesInvalid = Object.values(hasErrorReponse).some((error) => error);

                      if (
                        !areAnyQuestionsInvalid &&
                        !areAnyExplicationsInvalid &&
                        !areAnyExplicationsNumberInvalid &&
                        !areAnyResponsesInvalid &&
                        !hasErrorDescreption &&
                        !hasErrorObjectif &&
                        !tempassagerror &&
                        !seuilllerror
                      ) {
                        handleEditSave(moduleData);
                      }
                    }}
                    isDisabled={
                      Object.values(hasError).some((error) => error) ||
                      Object.values(hasErrorExplication).some((error) => error) ||
                      Object.values(hasErrorExplicationNumber).some((error) => error) ||
                      Object.values(hasErrorReponse).some((error) => error) ||
                      hasErrorDescreption ||
                      hasErrorObjectif ||
                      tempassagerror ||
                      seuilllerror
                    }
                  >
                    Enregistrer
                  </Button>

                  <Button variant="ghost" onClick={handleEditClose}>
                    Annuler
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          )}
        </TableContainer>
      </Stack>

      <AlertDialog
        isOpen={isDeleteAlertOpen}
        leastDestructiveRef={undefined}
        onClose={handleDeleteCancel}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Supprimer le test
            </AlertDialogHeader>

            <AlertDialogBody>
              Êtes-vous sûr de vouloir supprimer le test de difficulter{" "}
              {difficulter}{" "}
              <span style={{ visibility: "hidden" }}>id {deleteId} </span> ?
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button onClick={handleDeleteCancel}>Annuler</Button>
              <Button
                colorScheme="red"
                onClick={() => handleDeleteConfirm(deleteId)}
                ml={3}
              >
                Supprimer
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};
export default ChapitreList;
