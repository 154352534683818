import { AddIcon, DeleteIcon, EditIcon, SearchIcon } from "@chakra-ui/icons";
import {
  Alert,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertIcon,
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputLeftAddon,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Table,
  TableContainer,
  Tag,
  TagLabel,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import { BiLink } from "react-icons/bi";
import { useLocation, useNavigate } from "react-router-dom";
import axiosClient from "../../axios-client";
import MyPagination from "../../components/MyPagination";
import { useStateContext } from "../../context/ContextProvider";
import { MdHistory } from "react-icons/md";


function TableLinks() {
  // current user
  const { user, setUser } = useStateContext();

  const location = useLocation();

  const [listLinks, setListLinks] = useState([]);
  const [listLinksEdit, setListLinksEdit] = useState([
    {
      name: "",
      link: "",
    },
  ]);

  const navigate = useNavigate()

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [deletedId, setDeletedId] = useState();

  const cancelRef = React.useRef();
  const [isAlertOpen, setIsAlertOpen] = useState(false);

  const onOpenAlert = () => {
    setIsAlertOpen(true);
  };

  const onCloseAlert = () => {
    setIsAlertOpen(false);
  };

  useEffect(() => {
    axiosClient.get("auth/user/").then((res) => {
      setUser(res.data);
    });
    axiosClient
      .get(`/link/?search=${location.state.idChapitre}`)
      .then((res) => setListLinks(res.data.sort().reverse()));
  }, [user.id]);

  const toast = useToast();
  const deleteLink = (id) => {
    axiosClient.delete(`/link/${id}/`)
      .then((res) => {
        setListLinks((prevData) => prevData.filter((row) => row.id !== id));
        toast({
          title: "Suppression",
          description: `Le Lien est suppprimé avec succès`,
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      })
  };

  const openModalUpdate = (id) => {
    setListLinksEdit([]);
    axiosClient
      .get(`/link/${id}/`)
      .then((res) => setListLinksEdit(res.data));
    onOpen();
  };


  const [changeModal, setChangeModal] = useState(true)

  const [name, setName] = useState('');
  const [link, setLink] = useState();


  const [message, setMessage] = useState('')
  const [etatMessage, setEtatMessage] = useState(false)

  const isFormValid = (name, link) => {

    const linkRegex = /^(https:\/\/)([a-zA-Z0-9]+(\.[a-zA-Z]+)+([\w\-\.,@?^=%&amp;:/~\+#]*[\w\-\@?^=%&amp;/~\+#])?)$/;

    if (!name || !link || (name.trim() === "")  || (link.trim() === "")) {
      setMessage("Veuillez remplir les champs")
      setEtatMessage(true)
      return false;
    }

    if (!linkRegex.test(link)) {
      setMessage(<Text><b fontWeight="bold">Erreur</b> : Veuillez entrer un lien valide sécurisé commençant par : https://www.exemple.aze</Text>);
      setEtatMessage(true)
      return false;
    }


    return true;
  };

  const addLinks = () => {

    if (!isFormValid(name, link)) {
      return
    }

    const formData = new FormData()
    formData.append("name", name)
    formData.append('type', "Link")
    formData.append('link', link)
    formData.append('chapitre', location.state.idChapitre)
    formData.append("added_by", user.id)
    formData.append('modification',
      JSON.stringify([{
        "edited_by": `${user.first_name} ${user.last_name}`,
        "edited_at": edited_at,
        "field": {
          "name": name,
          "link": link
        }
      }])
    )
    if (listLinks.length>0) {
      const check = listLinks.find(obj => {
        return obj.name.toLowerCase() === name.toLocaleLowerCase().trim()
      })
      if (check) {
        setEtatMessage(true)
        return setMessage('Ce lien existe déjà dans ce chapitre !')
      }
    }
    axiosClient.post("/link/", formData)
      .then((response) => {
        setListLinks([response.data, ...listLinks]);
        setName('')
        setLink('')
        setEtatMessage(false)
        toast({
          title: 'Ajout',
          description: `Le Lien ${name} est ajouté avec succès`,
          status: 'success',
          duration: 2000,
          isClosable: true,
        })
      })
    onClose()
  }



  const [modification, setModification] = useState([])

  const date = new Date();
  date.setDate(date.getDate());

  const [edited_by, setedited_by] = useState()
  const [edited_at, setedited_at] = useState(date)

  const [title, setTitle] = useState('')
  const [lien, setLien] = useState('')

  const modalUpdate = (id, myModification, title, lien) => {

    if (!isFormValid(name, link)) {
      return
    }

    const mod = myModification
    mod.push({
      "edited_by": `${user.first_name} ${user.last_name}`,
      "edited_at": edited_at,
      "field": {
        "name": name !== title ? name : null,
        "link": link !== lien ? link : null
      }
    })


    const formData = new FormData()
    formData.append('name', name)
    formData.append('link', link)
    if (name !== title || link !== lien) {
      formData.append('modification', JSON.stringify(mod))
    }
    
    const newList = listLinks.filter((e) => e.id !== id)
    if (newList.length>0) {
      const check = newList.find(obj => {
        return obj.name.toLowerCase() === name.toLocaleLowerCase().trim()
      })
      if (check) {
        setEtatMessage(true)
        return setMessage('Ce lien existe déjà dans ce chapitre !')
      }
    }

    axiosClient.patch(`/link/${id}/`, formData)
      .then(() => {
        setListLinks((rows) =>
          rows.map((row) => {
            if (row.id === id) {
              return {
                ...row,
                name: name,
                link: link,
              };
            }
            return row;
          })
        );
        setName('')
        setLink('')
        setEtatMessage(false)
        if (name !== title || link !== lien) {
          toast({
            description: `le Lien est modifié avec succès`,
            status: "success",
            duration: 2000,
            isClosable: true,
            position: "bottom-right",
          });
        }
      });
    onClose();
  };

  const [search, setSearch] = useState("");


  //search method
  const filteredData = useCallback(() => {
    return listLinks.filter((row) => {
      return row.name.includes(search) ||
        row.link.includes(search)
    });
  }, [listLinks, search]);

  const [currentPage, setCurrentPage] = useState(0);
  ////////////////////////////////the size of the table//////////////////////////////
  const PAGE_SIZE = 10;
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const handlePageSizeChange = (event) => {
    const newSize = parseInt(event.target.value, 10);
    setPageSize(newSize);
    setCurrentPage(0);
  };
  //////////////////////////////////////////////////////////////////////////////////
  return (
    <>
      <Flex justifyContent="space-between" m={0} alignItems="center" direction={{ base: 'column', lg: 'row' }}>
        <InputGroup mt="10px" w={{ base: '100%', lg: "30%" }} mb={{ base: 2 }}>
          <InputLeftElement
            pointerEvents="none"
            children={<SearchIcon color="gray.300" />}
          />
          <Input
            type="text"
            placeholder="Recherche..."
            onChange={(e) => setSearch(e.target.value)}
          />
        </InputGroup>
        <Button
          mt="10px"
          mb={{ base: 5 }}
          w={{ base: '100%', lg: "180px" }}
          colorScheme="blue"
          size="md"
          onClick={() => {
            setChangeModal(true)
            onOpen()
          }}
          leftIcon={<AddIcon />}
        >
          Ajouter un lien
        </Button>
      </Flex>
      <TableContainer>
        <Table variant="striped" colorScheme="gray">
          <Thead>
            <Tr>
              <Th>Titre</Th>
              <Th>Type</Th>
              <Th>Lien</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {filteredData()
              .slice(
                currentPage * pageSize,
                currentPage * pageSize + pageSize
              )
              .map((val, key) => {
                return (
                  <Tr key={key}>
                    <Td>{val.name}</Td>
                    <Td>{val.type}</Td>
                    <Td cursor="pointer" title="Voir le contenu">
                      <Tag onClick={() => {
                        window.open(
                          val.link,
                          '_blank' // <- This is what makes it open in a new window.
                        );
                      }}
                        size="lg"
                        colorScheme="yellow"
                        borderRadius="full"
                      >
                        <BiLink fontSize={25} />
                        <TagLabel ml={2}>{val.link}</TagLabel>
                      </Tag>
                    </Td>
                    <Td display="flex" justifyContent="">
                      {(user.role === "SUPERADMIN" || user.role === "MASTER") ?
                        <>
                          <IconButton
                            size="sm"
                            colorScheme="green"
                            mr={5}
                            icon={<EditIcon />}
                            onClick={() => {
                              setChangeModal(false)
                              setDeletedId(val.id)
                              setName(val.name)
                              setLink(val.link)
                              setTitle(val.name)
                              setLien(val.link)
                              setModification(val.modification)
                              openModalUpdate(val.id);
                            }}
                          >
                            Modifier
                          </IconButton>
                          <IconButton
                            size="sm"
                            mr={5}
                            bg="gray.300"
                            icon={<MdHistory />}
                            onClick={() =>
                              navigate('/historique_media', {
                                state: {
                                  idMedia: val.id,
                                  Type: val.type
                                }
                              })
                            }
                          >
                            Historique
                          </IconButton>
                          <IconButton
                            size="sm"
                            colorScheme="red"
                            icon={<DeleteIcon />}
                            onClick={() => {
                              setDeletedId(val.id);
                              onOpenAlert();
                            }}
                          >
                            Supprimer
                          </IconButton>
                        </>
                        :
                        <>
                          <IconButton
                            size="sm"
                            colorScheme="green"
                            mr={5}
                            icon={<EditIcon />}
                            onClick={() => {
                              setChangeModal(false)
                              setDeletedId(val.id)
                              setName(val.name)
                              setLink(val.link)
                              setTitle(val.name)
                              setLien(val.link)
                              setModification(val.modification)
                              openModalUpdate(val.id);
                            }}
                          >
                            Modifier
                          </IconButton>
                          <IconButton
                            size="sm"
                            bg="gray.300"
                            icon={<MdHistory />}
                            onClick={() =>
                              navigate('/historique_media', {
                                state: {
                                  idMedia: val.id,
                                  Type: val.type
                                }
                              })
                            }
                          >
                            Historique
                          </IconButton>
                        </>
                      }
                    </Td>
                  </Tr>
                );
              })}
            {
              filteredData().length === 0 && (
                <Tr>
                  <Td colSpan={6}>
                    Aucune ligne correspondante n'a été trouvée.
                  </Td>
                </Tr>
              )}
          </Tbody>
        </Table>
      </TableContainer>

      <Flex justify="space-between" align="center" w="100%">
        <Box flex="1">
          <MyPagination
            data={filteredData()}
            searchInput={search}
            PAGE_SIZE={pageSize}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </Box>

        <Select w="70px" value={pageSize} onChange={handlePageSizeChange}>
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="30">30</option>
          <option value="40">40</option>
          {/* Add more options as needed */}
        </Select>
      </Flex>
      {/* Alert for delete */}
      <AlertDialog
        isOpen={isAlertOpen}
        leastDestructiveRef={cancelRef}
        onClose={onCloseAlert}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Supprimer le Lien
            </AlertDialogHeader>

            <AlertDialogBody>
              êtes-vous sûr ? Vous ne pourrez pas annuler cette action
              ultérieurement.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onCloseAlert}>
                Annuler
              </Button>
              <Button
                onClick={() => {
                  deleteLink(deletedId);
                  onCloseAlert();
                }}
                colorScheme="red"
                ml={3}
              >
                Supprimer
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      {changeModal ?
        //Modal for Add
        <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={() => {
          onClose()
          setName('')
          setEtatMessage(false)
        }}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Ajout du lien</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              {etatMessage &&
                <Alert status='error' rounded="md">
                  <AlertIcon />
                  {message}
                </Alert>
              }
              <Flex direction="column">
                <FormControl id="name" isRequired>
                  <FormLabel>Titre</FormLabel>
                  <InputGroup borderColor="#E0E1E7">
                    <Input
                      maxLength={50}
                      type="text"
                      size="lg"
                      name="name"
                      placeholder="Entrer le titre du lien"
                      onChange={(e) => setName(e.target.value)}
                    />
                  </InputGroup>
                </FormControl>
                <Flex justify="end">
                  {name.length > 40
                    ? <Text fontSize='xs' color="red">{name.length}/50 caractères !</Text>
                    : <Text fontSize='xs'>{name.length}/50 {name.length > 1 ? "caractères" : "caractère"}</Text>
                  }
                </Flex>
                <FormControl mt="3" id="link" isRequired>
                  <FormLabel>Lien</FormLabel>
                  <InputGroup borderColor="#E0E1E7">$
                    <Input
                      type="text"
                      size="md"
                      name="link"
                      placeholder="Entrer le lien"
                      onChange={(e) => setLink(e.target.value)}
                    />
                  </InputGroup>
                </FormControl>
                <Flex justify="start">
                </Flex>
              </Flex>
            </ModalBody>

            <ModalFooter>
              {(!name || !link)
                ? <Button colorScheme="blue" title="Veuillez remplir tout les champs" isDisabled>
                  Ajouter
                </Button>
                : <Button colorScheme="blue" onClick={addLinks}>
                  Ajouter
                </Button>}

            </ModalFooter>
          </ModalContent>
        </Modal>
        :

        //Modal for update
        <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={() => {
          onClose()
          setEtatMessage(false)
        }}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Modification du lien</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              {etatMessage &&
                <Alert status='error' rounded="md">
                  <AlertIcon />
                  {message}
                </Alert>
              }
              <Flex direction="column">
                <FormControl id="name" isRequired>
                  <FormLabel>Titre</FormLabel>
                  <InputGroup borderColor="#E0E1E7">
                    <Input
                      type="text"
                      size="lg"
                      name="name"
                      defaultValue={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </InputGroup>
                  <Flex justify="end">
                    {name.length > 40
                      ? <Text fontSize='xs' color="red">{name.length}/50 caractères !</Text>
                      : <Text fontSize='xs'>{name.length}/50 {name.length > 1 ? "caractères" : "caractère"}</Text>
                    }
                  </Flex>
                </FormControl>
                <FormControl mt="3" id="link" isRequired>
                  <FormLabel>Lien</FormLabel>
                  <InputGroup borderColor="#E0E1E7">
                    <Input
                      type="text"
                      size="md"
                      name="link"
                      defaultValue={link}
                      placeholder="Entrer le lien"
                      onChange={(e) => setLink(e.target.value)}
                    />
                  </InputGroup>
                </FormControl>
              </Flex>
            </ModalBody>

            <ModalFooter>
              {(!name || !link)
                ? <Button colorScheme="blue" title="Veuillez remplir tout les champs" isDisabled>
                  Modifier
                </Button>
                :
                <Button colorScheme="blue" mr={3} onClick={() => { modalUpdate(deletedId, modification, title, lien) }}>
                  Modifier
                </Button>}
            </ModalFooter>
          </ModalContent>
        </Modal>
      }

    </>
  );
}

export default TableLinks;
